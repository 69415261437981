import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Spinner from '../../../components/Spinner/Spinner';
import { getContent } from '../../../utilities/getContent/getContent';
import TMCard from '../../../components/TMCard/TMCard';
import { getLogo } from './../../../assets/helper';
import * as actions from '../../../store/actions/survey/survey';

class SurveySubmitting extends Component {
  retrySubmission = () => {
    // Turn this to a end survey component like tim suggested.
    // Spinning wheel while submitting, then you are done message or retry button
    const {
      surveyDataToSend,
      saveSurveyParticipantResponseData
    } = this.props;

    saveSurveyParticipantResponseData({
      ...surveyDataToSend,
      surveySubmittedAt: Date.now(),
    });
  }

  redirectToFinish = () => {
    this.props.history.push('/survey/complete');
  }

  render() {
    return (
      <div className="page-container-survey-complete">
        {
          this.props.isSurveySubmissionLoading ?
          <TMCard
            className="tm-card-stepper"
            header={[
              <div className="text-center mb-4">
                <img src={getLogo()} alt="Logo" />
              </div>,
            ]}>
            <div>
              <p>{getContent('generic_please-wait')}</p>
              <Spinner />
            </div>
          </TMCard>
          :
          (
            <TMCard
              className="tm-card-stepper"
              header={[
                <div className="text-center mb-4">
                  <img src={getLogo()} alt="Logo" />
                </div>,
              ]}>
              <div>
                {
                  this.props.saveSurveyResponseError ?
                  <div>
                    <p>There was a problem submitting your survey results.</p>
                    <p>Do not navigate away from this page.  Please retry submitting your results.</p>
                    <Button type="button" className="small-button green" size="lg" onClick={this.retrySubmission}>{getContent('generic_retry')}</Button>
                  </div>
                     :
                  <div>
                    <p>Survey results successfully saved.</p>
                    <Button type="button" className="small-button green" size="lg" onClick={this.redirectToFinish}>{getContent('generic_finish-survey')}</Button>
                  </div>
                }
              </div>
            </TMCard>  
          )
        }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isSurveySubmissionLoading: state.survey.isSaveSurveyResponseLoading,
    saveSurveyResponseError: state.survey.saveSurveyResponseError,
    surveyDataToSend: state.survey.surveyDataToSend,
    survey: state.survey,
    receivedSurveyData: state.survey.receivedSurveyData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveSurveyParticipantResponseData: surveyDataToSend => dispatch(actions.saveSurveyParticipantResponseData(surveyDataToSend)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SurveySubmitting));
