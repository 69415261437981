import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Spinner from '../../components/Spinner/Spinner';
import IdleTimer from 'react-idle-timer';
// import classnames from 'classnames';
import * as actions from '../../store/actions/';
import Toaster from '../../components/Toaster/Toaster';

const withLayout = (WrappedComponent, { ...props }) => {
	const defaultOptions = { showLogo: false, showContact: false, showUser: false, showLang: false };
	const { showLogo, showUser, showContact, showLang } = { ...defaultOptions, ...props };
	class Layout extends Component {
		constructor(props) {
			super(props);
			this.idleTimer = null;
		}

		redirectToLogin = () => {
			this.props.history.push('/login');
		};

		render() {
			return (
				<>
					<IdleTimer
						ref={ref => {
							this.idleTimer = ref;
						}}
						element={document}
						onIdle={() => {
							this.props.logout(this.redirectToLogin);
						}}
						debounce={250}
						timeout={1000 * 60 * 15}
					/>
					<Toaster />
					<div className="tm-layout" data-role={this.props.role}>
						<Header showLogo={showLogo} showUser={showUser} showLang={showLang} />
						<div className="container">
							{/* if you are logged in and the org is currently Loading OR it is NOT LOADED, Spin */}
							{/* this.props.role && (this.props.isOrgLoading || !this.props.isOrgLoaded) ? <Spinner /> : */}
							{this.props.role && (this.props.isOrgLoading || !this.props.isOrgLoaded) ? (
								<Spinner />
							) : (
								<WrappedComponent {...this.props} />
							)}
						</div>
						<Footer showContact={showContact} />
					</div>
				</>
			);
		}
	}

	const mapStateToProps = state => {
		return {
			role: state.auth.role,
			isOrgLoading: state.organizations.isLoading,
			isOrgLoaded: state.organizations.isLoaded,
			lang: state.meta.lang
		};
	};

	const mapDispatchToProps = dispatch => {
		return {
			logout: cb => dispatch(actions.logout(cb))
		};
	};

	return connect(
		mapStateToProps,
		mapDispatchToProps
	)(Layout);
};

export default withLayout;
