import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Button from 'react-bootstrap/Button';
import DropdownList from 'react-widgets/lib/DropdownList';

import * as actions from '../../../../store/actions';

class SearchReportsForm extends Component {
  componentDidMount() {
    this.props.setSearchValuesReports();
  }
  radioButtonHandler = newRadioVal => {
    this.props.updateSearchReportsObj({ name: 'reportSearchReportType', value: newRadioVal });
  };

  dropdownChangeHandler = (dropdownName, value) => {
    if (dropdownName === 'reportSearchCountryRegion') {
      this.props.clearReportStateOptions();
    }
    if (dropdownName === 'reportSearchStateProvince') {
      this.props.clearReportDistrictOptions();
      this.props.clearReportSchoolOptions();
    }
    if (dropdownName === 'reportSearchDistrict') {
      this.props.clearReportSchoolOptions();
    }

    this.props.updateSearchReportsObj({ name: dropdownName, value: value });
    this.props.setSearchValuesReports();
  };

  render() {
    var yearOptions = [];
    const currentYear = new Date().getFullYear();
    for (let index = 0; index < 10; index++) {
      yearOptions.push(currentYear + index);
    }

    const shouldDisableSearch = this.props.reportSearchReportType === 'studentReport' && this.props.role === 'tm_admin' && (this.props.reportSearchDistrict.name === '' || this.props.reportSearchDistrict.orgId === '');
    return (
      <div className={classnames('container-search-survey', this.props.className)}>
        <p className="search-survey-header">Search Reports</p>
        <form className="search-survey-form" onSubmit={this.props.onSubmit}>
          <div className="input-container-search-reports">
            {this.props.role && this.props.role === 'tm_admin' && (
              <div>
                <label className="label">Country/Region</label>
                <DropdownList
                  className="tm-dropdown short"
                  data={this.props.searchCountryRegionOptions}
                  onChange={value => this.dropdownChangeHandler('reportSearchCountryRegion', value)}
                  value={this.props.reportSearchCountryRegion}
                  placeholder="- Select -"
                />
              </div>
            )}
            {this.props.role && this.props.role === 'tm_admin' && (
              <div>
                <label className="label">State/Province</label>
                <DropdownList
                  className="tm-dropdown short"
                  data={this.props.searchStateProvinceOptions}
                  onChange={value => this.dropdownChangeHandler('reportSearchStateProvince', value)}
                  value={this.props.reportSearchStateProvince}
                  placeholder={this.props.reportSearchCountryRegion === '' ? '' : '- Select -'}
                  disabled={this.props.reportSearchCountryRegion === ''}
                />
              </div>
            )}
            {this.props.role && this.props.role === 'tm_admin' && (
              <div>
                <label className="label">District</label>
                <DropdownList
                  className="tm-dropdown wide"
                  data={this.props.searchDistrictOptions}
                  onChange={value => this.dropdownChangeHandler('reportSearchDistrict', value)}
                  value={this.props.reportSearchDistrict.name}
                  placeholder={this.props.reportSearchStateProvince === '' ? '' : '- Select District -'}
                  disabled={this.props.reportSearchStateProvince === ''}
                />
              </div>
            )}
            {this.props.role && ['district_champion', 'tm_admin'].includes(this.props.role) && (
              <div>
                <label className="label">School</label>
                <DropdownList
                  className="tm-dropdown wide"
                  data={this.props.searchSchoolOptions}
                  onChange={value => this.dropdownChangeHandler('reportSearchSchool', value)}
                  value={this.props.reportSearchSchool.name}
                  placeholder={this.props.reportSearchDistrict && this.props.reportSearchDistrict.name === '' ? '' : '- Select School -'}
                  disabled={this.props.reportSearchDistrict && this.props.reportSearchDistrict.name === '' && this.props.role !== 'district_champion'}
                />
              </div>
            )}
            <div className="radio-container-search-reports">
              <label className="label survey-name-label">Report Type</label>
              <div className="flex-row radio-search-reports">
                <div className="flex-row">
                  <label>
                    <input type="radio" value="schoolReport" checked={this.props.reportSearchReportType === 'schoolReport'} onChange={event => this.radioButtonHandler(event.target.value)} />
                    School Report
                  </label>
                </div>
                <div className="flex-row">
                  <label>
                    <input type="radio" value="studentReport" checked={this.props.reportSearchReportType === 'studentReport'} onChange={event => this.radioButtonHandler(event.target.value)} />
                    Participant Report
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label className="label">Year</label>
              <DropdownList
                className="tm-dropdown short"
                data={yearOptions}
                onChange={value => this.props.updateSearchReportsObj({ name: 'reportSearchYear', value: value })}
                value={this.props.reportSearchYear}
              />
            </div>
            {this.props.reportSearchReportType === 'studentReport' ? (
              <div>
                <label className="label survey-name-label">Participant Name</label>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="search-survey-input"
                  onChange={event => this.props.updateSearchReportsObj({ name: 'reportSearchName', value: event.target.value })}
                  value={this.props.reportSearchName}
                />
              </div>
            ) : null}
          </div>
          <div className="search-survey-submit-container">
            <Button onClick={this.props.onSubmit} className="small-blue-button" disabled={shouldDisableSearch}>
              Search Reports
            </Button>
            <Button onClick={this.props.toggleShowReportSearch} className={this.props.searchReportsResults === null ? 'hidden' : 'small-blue-button-outlined'}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchReportsResults: state.reports.searchReportsResults,
    showReportsSearch: state.reports.showReportsSearch,
    reportSearchDistrict: state.reports.reportSearchDistrict,
    reportSearchSchool: state.reports.reportSearchSchool,
    SearchCountryRegion: state.reports.searchSearchCountryRegion,
    searchCountryRegionOptions: state.reports.searchCountryRegionOptions,
    searchStateProvinceOptions: state.reports.searchStateProvinceOptions,
    searchDistrictOptions: state.reports.searchDistrictOptions,
    searchSchoolOptions: state.reports.searchSchoolOptions,
    reportSearchCountryRegion: state.reports.reportSearchCountryRegion,
    reportSearchStateProvince: state.reports.reportSearchStateProvince,
    reportSearchYear: state.reports.reportSearchYear,
    reportSearchReportType: state.reports.reportSearchReportType,
    reportSearchName: state.reports.reportSearchName,
    role: state.auth.role,
    orgInfo: state.organizations.organization,
    radioValue: state.reports.radioValue,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSearchReportsObj: payload => dispatch(actions.updateSearchReportsObj(payload)),
    toggleShowReportSearch: () => dispatch(actions.toggleShowReportSearch()),
    setSearchValuesReports: () => dispatch(actions.setSearchValuesReports()),
    clearReportStateOptions: () => dispatch(actions.clearReportStateOptions()),
    clearReportDistrictOptions: () => dispatch(actions.clearReportDistrictOptions()),
    clearReportSchoolOptions: () => dispatch(actions.clearReportSchoolOptions()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchReportsForm);
