import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import classnames from 'classnames';

const Breadcrumbs = (props) => {
  console.log('breadcrumbs: ', props.breadcrumbs);
  console.log('breadcrumbs props: ', props);

  const NavItem = ({ breadcrumb }) => {
    return (
      <NavLink to={breadcrumb.url}>
        {breadcrumb.label}
      </NavLink>
    )
  }

  const ActiveItem = ({ breadcrumb }) => {
    return (
      <span>{breadcrumb.label}</span>
    )
  }

  let sectionTitle = 'Terrace Admin';

  if (props.role === 'district_champion') {
    sectionTitle = props.organization && props.organization.name ? props.organization.name : '';
  }

  if (props.role === 'school_champion') {
    sectionTitle = `${props.organization && props.organization.parentOrg ? props.organization.parentOrg.name : ''} : ${props.organization && props.organization.name ? props.organization.name : ''}`;
  }

  return (
    <div className="breadcrumbs">
      <div className="auth">{sectionTitle}</div>
      <ol className="breadcrumb">
        {props.breadcrumbs.map((breadcrumb, index) => (
          <li key={breadcrumb.key} className={classnames('breadcrumb-item', { 'active': (props.breadcrumbs.length - 1) === index })}>
            {
              (props.breadcrumbs.length - 1) === index && !props.linkAllCrumbs ? <ActiveItem breadcrumb={breadcrumb} /> : <NavItem breadcrumb={breadcrumb} />
            }
          </li>
        ))}
      </ol>
    </div>
  )
};

const mapStateToProps = state => {
  return {
    role: state.auth.role,
    organization: state.organizations.organization
  };
};

export default connect(mapStateToProps)(withRouter(Breadcrumbs));
