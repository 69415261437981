export default [
  {
    "name":"Avon",
    "abbreviation":"AVN",
    "country":"England"
  },
  {
    "name":"Bedfordshire",
    "abbreviation":"BDF",
    "country":"England"
  },
  {
    "name":"Berkshire",
    "abbreviation":"BRK",
    "country":"England"
  },
  {
    "name":"Buckinghamshire",
    "abbreviation":"BKM",
    "country":"England"
  },
  {
    "name":"Cambridgeshire",
    "abbreviation":"CAM",
    "country":"England"
  },
  {
    "name":"Cheshire",
    "abbreviation":"CHS",
    "country":"England"
  },
  {
    "name":"Cleveland",
    "abbreviation":"CLV",
    "country":"England"
  },
  {
    "name":"Cornwall",
    "abbreviation":"CON",
    "country":"England"
  },
  {
    "name":"Cumbria",
    "abbreviation":"CMA",
    "country":"England"
  },
  {
    "name":"Derbyshire",
    "abbreviation":"DBY",
    "country":"England"
  },
  {
    "name":"Devon",
    "abbreviation":"DEV",
    "country":"England"
  },
  {
    "name":"Dorset",
    "abbreviation":"DOR",
    "country":"England"
  },
  {
    "name":"Durham",
    "abbreviation":"DUR",
    "country":"England"
  },
  {
    "name":"East Sussex",
    "abbreviation":"SXE",
    "country":"England"
  },
  {
    "name":"Essex",
    "abbreviation":"ESS",
    "country":"England"
  },
  {
    "name":"Gloucestershire",
    "abbreviation":"GLS",
    "country":"England"
  },
  {
    "name":"Hampshire",
    "abbreviation":"HAM",
    "country":"England"
  },
  {
    "name":"Herefordshire",
    "abbreviation":"HEF",
    "country":"England"
  },
  {
    "name":"Hertfordshire",
    "abbreviation":"HRT",
    "country":"England"
  },
  {
    "name":"Isle of Wight",
    "abbreviation":"IOW",
    "country":"England"
  },
  {
    "name":"Kent",
    "abbreviation":"KEN",
    "country":"England"
  },
  {
    "name":"Lancashire",
    "abbreviation":"LAN",
    "country":"England"
  },
  {
    "name":"Leicestershire",
    "abbreviation":"LEI",
    "country":"England"
  },
  {
    "name":"Lincolnshire",
    "abbreviation":"LIN",
    "country":"England"
  },
  {
    "name":"London",
    "abbreviation":"LDN",
    "country":"England"
  },
  {
    "name":"Merseyside",
    "abbreviation":"MSY",
    "country":"England"
  },
  {
    "name":"Norfolk",
    "abbreviation":"NFK",
    "country":"England"
  },
  {
    "name":"Northamptonshire",
    "abbreviation":"NTH",
    "country":"England"
  },
  {
    "name":"Northumberland",
    "abbreviation":"NBL",
    "country":"England"
  },
  {
    "name":"North Yorkshire",
    "abbreviation":"NYK",
    "country":"England"
  },
  {
    "name":"Nottinghamshire",
    "abbreviation":"NTT",
    "country":"England"
  },
  {
    "name":"Oxfordshire",
    "abbreviation":"OXF",
    "country":"England"
  },
  {
    "name":"Rutland",
    "abbreviation":"RUT",
    "country":"England"
  },
  {
    "name":"Shropshire",
    "abbreviation":"SAL",
    "country":"England"
  },
  {
    "name":"Somerset",
    "abbreviation":"SOM",
    "country":"England"
  },
  {
    "name":"South Yorkshire",
    "abbreviation":"SYK",
    "country":"England"
  },
  {
    "name":"Staffordshire",
    "abbreviation":"STS",
    "country":"England"
  },
  {
    "name":"Suffolk",
    "abbreviation":"SFK",
    "country":"England"
  },
  {
    "name":"Surrey",
    "abbreviation":"SRY",
    "country":"England"
  },
  {
    "name":"Tyne and Wear",
    "abbreviation":"TWR",
    "country":"England"
  },
  {
    "name":"Warwickshire",
    "abbreviation":"WAR",
    "country":"England"
  },
  {
    "name":"West Midlands",
    "abbreviation":"WMD",
    "country":"England"
  },
  {
    "name":"West Sussex",
    "abbreviation":"SXW",
    "country":"England"
  },
  {
    "name":"West Yorkshire",
    "abbreviation":"WYK",
    "country":"England"
  },
  {
    "name":"Wiltshire",
    "abbreviation":"WIL",
    "country":"England"
  },
  {
    "name":"Worcestershire",
    "abbreviation":"WOR",
    "country":"England"
  },
  {
    "name":"Clwyd",
    "abbreviation":"CWD",
    "country":"Wales"
  },
  {
    "name":"Dyfed",
    "abbreviation":"DFD",
    "country":"Wales"
  },
  {
    "name":"Gwent",
    "abbreviation":"GNT",
    "country":"Wales"
  },
  {
    "name":"Gwynedd",
    "abbreviation":"GWN",
    "country":"Wales"
  },
  {
    "name":"Mid Glamorgan",
    "abbreviation":"MGM",
    "country":"Wales"
  },
  {
    "name":"Powys",
    "abbreviation":"POW",
    "country":"Wales"
  },
  {
    "name":"South Glamorgan",
    "abbreviation":"SGM",
    "country":"Wales"
  },
  {
    "name":"West Glamorgan",
    "abbreviation":"WGM",
    "country":"Wales"
  },
  {
    "name":"Aberdeenshire",
    "abbreviation":"ABD",
    "country":"Scotland"
  },
  {
    "name":"Angus",
    "abbreviation":"ANS",
    "country":"Scotland"
  },
  {
    "name":"Argyll",
    "abbreviation":"ARL",
    "country":"Scotland"
  },
  {
    "name":"Ayrshire",
    "abbreviation":"AYR",
    "country":"Scotland"
  },
  {
    "name":"Banffshire",
    "abbreviation":"BAN",
    "country":"Scotland"
  },
  {
    "name":"Berwickshire",
    "abbreviation":"BEW",
    "country":"Scotland"
  },
  {
    "name":"Bute",
    "abbreviation":"BUT",
    "country":"Scotland"
  },
  {
    "name":"Caithness",
    "abbreviation":"CAI",
    "country":"Scotland"
  },
  {
    "name":"Clackmannanshire",
    "abbreviation":"CLK",
    "country":"Scotland"
  },
  {
    "name":"Dumfriesshire",
    "abbreviation":"DGY",
    "country":"Scotland"
  },
  {
    "name":"Dunbartonshire",
    "abbreviation":"DNB",
    "country":"Scotland"
  },
  {
    "name":"East Lothian",
    "abbreviation":"ELN",
    "country":"Scotland"
  },
  {
    "name":"Fife",
    "abbreviation":"FIF",
    "country":"Scotland"
  },
  {
    "name":"Inverness-shire",
    "abbreviation":"INV",
    "country":"Scotland"
  },
  {
    "name":"Kincardineshire",
    "abbreviation":"KCD",
    "country":"Scotland"
  },
  {
    "name":"Kinross-shire",
    "abbreviation":"KRS",
    "country":"Scotland"
  },
  {
    "name":"Kirkcudbrightshire",
    "abbreviation":"KKD",
    "country":"Scotland"
  },
  {
    "name":"Lanarkshire",
    "abbreviation":"LKS",
    "country":"Scotland"
  },
  {
    "name":"Midlothian",
    "abbreviation":"MLN",
    "country":"Scotland"
  },
  {
    "name":"Moray",
    "abbreviation":"MOR",
    "country":"Scotland"
  },
  {
    "name":"Nairnshire",
    "abbreviation":"NAI",
    "country":"Scotland"
  },
  {
    "name":"Orkney",
    "abbreviation":"OKI",
    "country":"Scotland"
  },
  {
    "name":"Peeblesshire",
    "abbreviation":"PEE",
    "country":"Scotland"
  },
  {
    "name":"Perthshire",
    "abbreviation":"PER",
    "country":"Scotland"
  },
  {
    "name":"Renfrewshire",
    "abbreviation":"RFW",
    "country":"Scotland"
  },
  {
    "name":"Ross-shire",
    "abbreviation":"ROC",
    "country":"Scotland"
  },
  {
    "name":"Roxburghshire",
    "abbreviation":"ROX",
    "country":"Scotland"
  },
  {
    "name":"Selkirkshire",
    "abbreviation":"SEL",
    "country":"Scotland"
  },
  {
    "name":"Shetland",
    "abbreviation":"SHI",
    "country":"Scotland"
  },
  {
    "name":"Stirlingshire",
    "abbreviation":"STI",
    "country":"Scotland"
  },
  {
    "name":"Sutherland",
    "abbreviation":"SUT",
    "country":"Scotland"
  },
  {
    "name":"West Lothian",
    "abbreviation":"WLN",
    "country":"Scotland"
  },
  {
    "name":"Wigtownshire",
    "abbreviation":"WIG",
    "country":"Scotland"
  },
  {
    "name":"Antrim",
    "abbreviation":"ANT",
    "country":"Northern Ireland"
  },
  {
    "name":"Armagh",
    "abbreviation":"ARM",
    "country":"Northern Ireland"
  },
  {
    "name":"Down",
    "abbreviation":"DOW",
    "country":"Northern Ireland"
  },
  {
    "name":"Fermanagh",
    "abbreviation":"FER",
    "country":"Northern Ireland"
  },
  {
    "name":"Londonderry",
    "abbreviation":"LDY",
    "country":"Northern Ireland"
  },
  {
    "name":"Tyrone",
    "abbreviation":"TYR",
    "country":"Northern Ireland"
  }
];
