import React, { Component } from 'react';
import { getContent } from '../../../../utilities/getContent/getContent';


class QuestionsOfConcernPage extends Component {
  render() {
    const payload = this.props.payload;
    const filteredIndicators = payload.indicators.filter(item => item.responses && item.responses.length > 0);

    if (filteredIndicators.length || (!payload.surveyResultIsValid && payload.validityQuestions)) {
      return (
        <div className="page questions-of-concern">
          <main>
            <h1 className="mb-2 mt-2 text-uppercase">{getContent('report_questions-of-concern')}</h1>
            <p className="mb-5">
              {getContent('report_questions-of-concern-intro').replace('{payload.participant.firstName}',payload.participant.firstName).replace('{payload.participant.lastName}',payload.participant.lastName)}
            </p>
            {/* Validity Questions */}

            {!payload.surveyResultIsValid && payload.validityQuestions ? (
              <div className="concerns-container">
                <h2 className="mb-4">{getContent('report_validity-questions')}</h2>
                <table className="table table-sm table-bordered results mb-4">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{getContent('report_question')}</th>
                      <th className="text-center">{getContent('report_answer')}</th>
                    </tr>
                    {payload.validityQuestions.map((item, index) => {
                      return (
                        <tr key={getContent(item.questionText)}>
                          <td width="30" className="text-center">
                            {index + 1}.
                          </td>
                          <td>{getContent(item.questionText)}</td>
                          <td className="text-center">{getContent(item.responseOptionField)}</td>
                        </tr>
                      );
                    })}
                  </thead>
                </table>
              </div>
            ) : null}

            {/* At risk answers */}
            {/* map over all indicators with responses here */}
            {filteredIndicators && filteredIndicators.map((concern, ci) => {
              return (
                <div className="concerns-container" key={ci}>
                  <h2 className="mb-4">{getContent(concern.contentId || concern.indicator)}</h2>
                  <table className="table table-sm table-bordered results mb-4">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{getContent('report_question')}</th>
                        <th className="text-center">{getContent('report_answer')}</th>
                      </tr>
                      {concern.responses.map((qa, ni) => {
                        return (
                          <tr key={ni}>
                            <td width="30" className="text-center">
                              {ni + 1}.
                            </td>
                            <td>{getContent(qa.questionText)}</td>
                            <td className="text-center">{getContent(qa.responseOptionField)}</td>
                          </tr>
                        );
                      })}
                    </thead>
                  </table>

                  {/* end answers */}
                </div>
                // end concerns-container
              );
            })}
            {/* end at risk answers */}
          </main>
        </div>
      );
    } else {
      return '';
    }
  }
}

export default QuestionsOfConcernPage;
