import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import Button from 'react-bootstrap/Button';
import Spinner from '../../../../../components/Spinner/Spinner';

const RemoteSubmitButton = ({ dispatch, isWaiting }) => {

  // NOTE: This function only gets called if there are manual participants, other wise the createSurvey action is dispatched
  const submitSurvey = () => {
    dispatch(submit('participant-entry-form'));
  };

  return (
    <Button onClick={submitSurvey} className="survey-margin-left" bsPrefix="small-button">
      Save Survey {isWaiting ? <Spinner /> : null}
    </Button>
  );
};

export default connect()(RemoteSubmitButton);
