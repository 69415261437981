import React from 'react';
import { connect } from 'react-redux';
import AddUserModal from '../../containers/Admin/Users/AddUserModal/AddUserModal';
import ForgotPasswordModal from '../../containers/Admin/Auth/ForgotPassword/ForgotPasswordModal';
import GenericModal from '../GenericModal/GenericModal';
import EditParticipantModal from '../../containers/Admin/SurveyCRUD/SurveyDetails/EditParticipantModal/EditParticipantModal';
import AddParticipantModal from '../../containers/Admin/SurveyCRUD/SurveyDetails/AddParticipantModal/AddParticipantModal';
import CreateDistrictModal from '../../containers/Admin/Organizations/CreateOrganization/CreateDistrict/CreateDistrictModal';
import CreateSchoolModal from '../../containers/Admin/Organizations/CreateOrganization/CreateSchool/CreateSchoolModal';
import SurveyStatusModal from '../../containers/Admin/SurveyCRUD/SurveyDetails/SurveyStatusModal/SurveyStatusModal';
import NotifyParentModal from '../../containers/Admin/SurveyCRUD/SurveyDetails/NotifyParentModal/NotifyParentModal';
import BulkNotifyParentModal from '../../containers/Admin/SurveyCRUD/SurveyDetails/BulkNotifyParentModal/BulkNotifyParentModal';
import NotifyHistoryModal from '../../containers/Admin/SurveyCRUD/SurveyDetails/NotifyHistoryModal/NotifyHistoryModal';
import BulkEditModal from '../../containers/Admin/SurveyCRUD/SurveyDetails/BulkEditModal/BulkEditModal';
import BulkDeleteModal from '../../containers/Admin/SurveyCRUD/SurveyDetails/BulkDeleteModal/BulkDeleteModal';

const MODAL_COMPONENTS = {
  ADD_USER_MODAL: AddUserModal,
  ADD_PARTICIPANT_MODAL: AddParticipantModal,
  FORGOT_PASSWORD_MODAL: ForgotPasswordModal,
  GENERIC_MODAL: GenericModal,
  EDIT_PARTICIPANT_MODAL: EditParticipantModal,
  CREATE_DISTRICT_MODAL: CreateDistrictModal,
  CREATE_SCHOOL_MODAL: CreateSchoolModal,
  SURVEY_STATUS_MODAL: SurveyStatusModal,
  NOTIFY_PARENT_MODAL: NotifyParentModal,
  BULK_NOTIFY_PARENT_MODAL: BulkNotifyParentModal,
  NOTIFY_HISTORY_MODAL: NotifyHistoryModal,
  BULK_EDIT_PARTICIPANT_MODAL: BulkEditModal,
  BULK_DELETE_PARTICIPANT_MODAL: BulkDeleteModal,
};

const modalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    console.log('NO MODAL TYPE PROVIDED');
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return <SpecificModal {...modalProps} />;
};

export default connect(state => state.modalConductor)(modalRoot);
