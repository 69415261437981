import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import { ReactComponent as IconSchool } from '../../../../../assets/imgs/icon-school.svg';
import sanitizePhoneNumber from '../../../../../utilities/sanitizePhoneNumber';

import CreateDistrictForm from './CreateDistrictForm';
import * as actions from '../../../../../store/actions';
const { stateData } = require('../../../../../utilities/locale/').getLocale();

class CreateDistrictModal extends Component {
  handleSubmit = values => {
    console.log('CREATE ORG SUBMIT VALUES: ', values, ' item: ', this.props.item);
    const newItem = {
      country: values.country,
      state: values.state.abbreviation,
      name: values.name,
      classification: 'school-district',
    };

    if (this.props.item) {
      newItem.orgId = this.props.item.orgId;
      this.props.updateOrganization(newItem);
    } else {
      newItem.firstName = values.firstName;
      newItem.lastName = values.lastName;
      newItem.email = values.email;
      newItem.phoneNumber = sanitizePhoneNumber(values.phoneNumber);
      this.props.createOrganization(newItem);
    }
  };

  render() {
    const initialValues = {};
    if (this.props.item) {
      console.log('item in create org modal: ', this.props.item);
      const phoneNumber = this.props.item.phoneNumber ? this.props.item.phoneNumber.substring(2) : '';
      console.log(phoneNumber);
      // Initial Values Added here
      initialValues.country = this.props.item.country;
      // Find the State in StateData
      initialValues.state = stateData.find(state => this.props.item.state === state.abbreviation);
      initialValues.name = this.props.item.name;
      // Not used in Edit for now
      // initialValues.firstName = this.props.item.firstName;
      // initialValues.lastName = this.props.item.lastName;
      // initialValues.email = this.props.item.email;
      // initialValues.phoneNumber = `${phoneNumber}`;
    }

    return (
      <ModalWrapper show={this.props.show} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <IconSchool style={{ marginRight: '1rem' }} />
            <span>{this.props.item ? 'Edit District' : 'Create new District'}</span>
          </Modal.Title>
        </Modal.Header>
        <div style={{ flex: 1, alignItems: 'center' }}>
          {typeof this.props.error === 'string' ? <span className="error" style={{ textAlign: 'center' }}>{this.props.error}</span> : null}
        </div>
        <Modal.Body bsPrefix="modalContent">
          <CreateDistrictForm
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            saveButtonText={this.props.item ? 'Save Edits' : 'Add District'}
            closeModal={this.props.closeModal}
            item={this.props.item}
            error={this.props.error}
          />
        </Modal.Body>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.organizations.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOrganization: values => dispatch(actions.createOrganization(values)),
    updateOrganization: values => dispatch(actions.updateOrganization(values)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateDistrictModal);
