export const tmEducatorsLink = "https://www.terracemetrics.org/educators";
export const tmAdultsLink = "https://www.terracemetrics.org/adults"

export const educatorsLinkMap = {
  'Positive School Experiences': "/academic-expectations",
  'Hope': "/hope",
  'Leadership': "/leadership-for-teachers",
  'Grit': "/leadership-for-teachersits-all-about-grit",
  'Anxiety': "/anxiety",
  'Depression': "/depression-for-educators",
  'Drug/Alcohol': "/student-trauma",
  'School Violence': "/substance-abuse",
  'Getting Bullied (at least once a week)': "/student-victimization",
};
