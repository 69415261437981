import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../../../../store/actions';

class DesignatedEmailRecipient extends Component {
  handleChange = (e)=>{
    this.props.setDesignatedEmailRecipient(e.target.value)
  }
	render() {
		return (
			<>
				<div className="file-input-create-survey-step-group">
					<input
            type="text"
            style={{width: '400px'}}
            className="participant-entry-field"
            placeholder="Enter designated email recipient (Optional)"
            onChange={this.handleChange}
					/>
				</div>
			</>
		);
	}
}


const mapDispatchToProps = dispatch => {
	return {
    setDesignatedEmailRecipient: (value) => dispatch(actions.setDesignatedEmailRecipient(value))
  };
};

export default withRouter(
	connect(
    null,
		mapDispatchToProps
	)(DesignatedEmailRecipient)
);
