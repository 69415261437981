import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator';
import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import Spinner from '../../../../components/Spinner/Spinner';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import * as actions from '../../../../store/actions';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Star from '../../../../assets/imgs/icon-star.svg';
import { withRouter } from 'react-router-dom';
import BootStrapButton from 'react-bootstrap/Button';

class ListUsers extends Component {
  componentDidMount() {
    this.props.listUsers();
  }

  getBreadcrumbs = () => {
    let label = 'System Users';
    if (this.props.role && this.props.role === 'district_champion') {
      label = 'District Champions';
    }

    if (this.props.role && this.props.role === 'school_champion') {
      label = 'School Champions';
    }
    const breadcrumbs = [
      {
        key: 1,
        url: '/admin/',
        label: 'Home',
      },
      {
        key: 2,
        url: '/admin/survey/list',
        label: label,
      },
    ];

    return breadcrumbs;
  };

  generateTableData = () => {
    // Waits for survey details to be populated
    if (this.props.users && this.props.users.length > 0) {
      const result = this.props.users.map(item => {
        return {
          star: item.isDesignated === '1' ? <img src={`${Star}`} alt="star icon" /> : null,
          name: item.firstName || item.lastName ? <span>{`${item.firstName || ''} ${item.lastName || ''}`}</span> : '',
          email: item.email,
          phoneNumber: item.phoneNumber,
          icon: (
            <>
              <Edit className="blue-fill-icon" onClick={() => this.openUpdateUserModal(item)} />
              <Delete className="blue-fill-icon" onClick={() => this.openDeleteUserConfirmationModal(item)} />
            </>
          ),
        };
      });

      return result;
    }

    return [];
  };

  openDeleteUserConfirmationModal = user => {
    let firstName = user.firstName ? user.firstName : '';
    let lastName = user.lastName ? user.lastName : '';
    this.props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Are you sure?',
      content: (
        <div>
          <p>
            You are attempting to delete user “
            <strong>
              {`${firstName}`} {`${lastName}`}
            </strong>
            ”. Are you sure you want to continue? You cannot undo this action.{' '}
          </p>
          <BootStrapButton type="button" className="small-button green" size="lg" onClick={() => this.props.deleteUser(user.username)}>
            Delete user
          </BootStrapButton>
          <BootStrapButton type="button" className="small-blue-button-outlined" size="lg" onClick={this.props.closeModal}>
            Cancel
          </BootStrapButton>
        </div>
      ),
    });
  };

  openAddUserModal = () => {
    this.props.openModal({
      modalType: 'ADD_USER_MODAL',
      show: true,
      addUser: this.props.createUser,
      closeModal: e => this.closeModal(e),
    });
  };

  openUpdateUserModal = user => {
    this.props.openModal({
      modalType: 'ADD_USER_MODAL',
      show: true,
      addUser: this.props.updateUser,
      user,
      closeModal: e => this.closeModal(e),
    });
  };

  closeModal = e => {
    // Prevent the form from submitting
    if (e) {
      e.preventDefault();
    }

    this.props.closeModal();
  };

  render() {
    let createButtonText = 'Create User';
    let alertText = 'You are currently the only Terrace Metrics Admin user. Create new Terrace Admin system users by clicking on the button above or edit your information below.';

    if (this.props.role && this.props.role === 'district_champion') {
      createButtonText = 'Create District Champion';
      alertText = `You are currently the only District Champion for the ${(this.props.organization && this.props.organization.name) ||
        ''}. Add new District Champions with the button above or edit your information below.`;
    }

    if (this.props.role && this.props.role === 'school_champion') {
      createButtonText = 'Create School Champion';
      alertText = `You are currently the only School Champion for ${(this.props.organization && this.props.organization.name) ||
        ''}. Add new School Champions with the button above or edit your information below.`;
    }

    return (
      <>
        <div className="sub-header">
          <BreadCrumbs breadcrumbs={this.getBreadcrumbs()} />
          <BootStrapButton type="submit" className="small-button green" size="lg" variant="success" onClick={this.openAddUserModal}>
            {createButtonText}
          </BootStrapButton>
        </div>
        {!this.props.isUsersLoaded ? (
          <>
            <Backdrop>
              <Spinner />
            </Backdrop>
          </>
        ) : (
          <>
            {this.props.users && this.props.users.length < 2 ? (
              <Alert className="alert" dismissible variant="info">
                <div className="alertHeader">You’re the first one here.</div>
                <div>{alertText}</div>
              </Alert>
            ) : null}
            <div className={this.props.users.length > 0 ? null : 'hidden'}>
              <TableGenerator
                config={{
                  headers: ['', 'NAME', 'EMAIL', 'PHONE', ''],
                  contentRows: this.generateTableData(),
                }}
              />
            </div>
            <>
              {this.props.role && this.props.role === 'district_champion' ? (
                <p className="ml-3 caption">
                  <img src={Star} alt="star icon" style={{ marginRight: '1rem' }} />
                  <span className="align-middle">Denotes designated District Champion. The designated District Champion will be the main point of contact for Terrace Metrics.</span>
                </p>
              ) : null}
            </>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.users,
    isUsersLoaded: state.users.isLoaded,
    organization: state.organizations.organization,
    role: state.auth.role,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    closeModal: () => {
      dispatch(actions.closeModal());
      dispatch(actions.clearUserErrors());
    },
    createUser: userProps => dispatch(actions.createUser(userProps)),
    listUsers: () => dispatch(actions.listUsers()),
    updateUser: (userId, values) => dispatch(actions.updateUser(userId, values)),
    deleteUser: userId => dispatch(actions.deleteUser(userId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ListUsers));
