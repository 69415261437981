import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';
import { ReactComponent as IconSchool } from '../../../../../assets/imgs/icon-school.svg';
import sanitizePhoneNumber from '../../../../../utilities/sanitizePhoneNumber';

import CreateSchoolForm from './CreateSchoolForm';
import * as actions from '../../../../../store/actions';

class CreateSchoolModal extends Component {
  state = {
    champions: [],
  };

  componentDidMount() {
    // TODO: For editing champions in this modal later down the road
    // console.log('this.props.item: ', this.props.item);
    // if (this.props.item && this.props.item.schoolChampions) {
    //   if (this.props.item.schoolChampions.length > 0) {
    //     const schoolChampions = this.props.item.schoolChampions.map((item, index) => {
    //       const champion = {};
    //       champion[`firstName-${index}`] = item.firstName;
    //       champion[`lastName-${index}`] = item.lastName;
    //       champion[`phoneNumber-${index}`] = item.phoneNumber;
    //       champion[`email-${index}`] = item.email;
    //       return champion;
    //     });
    //     this.setState({ champions: schoolChampions });
    //   }
    // }
  }

  addChampion = e => {
    e.preventDefault();
    this.setState({ champions: [...this.state.champions, {}] });
  };

  removeChampion = (e, index) => {
    e.preventDefault();
    const champions = [...this.state.champions];
    champions.splice(index, 1);
    this.setState({ champions: champions });
  };

  handleSubmit = values => {
    console.log('CREATE School SUBMIT VALUES: ', values, ' item: ', this.props.item);
    const newItem = {
      name: values.name,
      address: values.address,
      city: values.city,
      phoneNumber: sanitizePhoneNumber(values.phoneNumber),
      classification: 'school',
      // NOTE: These fields get duplicated from parent org
      parentOrgId: this.props.organization.orgId,
      state: this.props.organization.state,
      country: this.props.organization.country,
    };

    const champions = this.state.champions.map((item, index) => {
      return {
        firstName: values[`firstName-${index}`],
        lastName: values[`lastName-${index}`],
        phoneNumber: sanitizePhoneNumber(values[`phoneNumber-${index}`]),
        email: values[`email-${index}`],
      };
    });

    newItem.schoolChampions = champions;

    if (this.props.item) {
      newItem.orgId = this.props.item.orgId;
      delete newItem.schoolChampions;
      this.props.updateOrganization(newItem);
    } else {
      // NOTE: This may need to get lifted since this component won't be in the DOM
      //    if there are no champions, it needs to pop a confirmation modal.
      if (!champions || champions.length === 0) {
        this.props.confirmCreationWithoutChampion(newItem);
      } else {
        this.props.createOrganization(newItem);
      }
    }
  };

  render() {
    let initialValues = {};
    if (this.props.item) {
      // Initial Values Added here
      // NOTE: Saved for later editing champions on this modal
      // if (this.state.champions.length > 0) {
      //   initialValues = { ...this.state.champions.flatten() };
      // }
      initialValues.name = this.props.item.name;
      initialValues.address = this.props.item.address;
      initialValues.city = this.props.item.city;
      initialValues.phoneNumber = this.props.item.phoneNumber;
      console.log('Initial Values: ', initialValues);
    }

    return (
      <ModalWrapper show={this.props.show} size="lg" resetModalErrorsOnClose={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <IconSchool style={{ marginRight: '1rem' }} />
            {this.props.item ? 'Edit School' : 'Create new School'}
          </Modal.Title>
        </Modal.Header>
        <div style={{ flex: 1, alignItems: 'center' }}>
          {typeof this.props.error === 'string' ? <span className="error" style={{ textAlign: 'center' }}>{this.props.error}</span> : null}
        </div>
        <Modal.Body bsPrefix="modalContent">
          <CreateSchoolForm
            onSubmit={this.handleSubmit}
            initialValues={initialValues}
            saveButtonText={this.props.item ? 'Save Edits' : 'Add School'}
            champions={this.state.champions}
            addChampion={this.props.item ? null : this.addChampion}
            removeChampion={this.removeChampion}
            closeModal={this.props.closeModal}
          />
        </Modal.Body>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    organization: state.organizations.organization,
    error: state.organizations.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createOrganization: values => dispatch(actions.createOrganization(values)),
    updateOrganization: values => dispatch(actions.updateOrganization(values)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateSchoolModal);
