export default {
  at_risk: {
    label: 'At Risk',
    color: 'red',
    url: {
      default: 'http://www.terracemetrics.org/parents/',
      adult: 'http://www.terracemetrics.org/adults/',
    }
  },
  moderate: {
    label: 'Moderate Concern',
    color: 'yellow',
  },
  satisfactory: {
    label: 'Satisfactory',
    color: 'light-green',
  },
  optimal: {
    label: 'Optimal',
    color: 'green',
  },
  no_concern: {
    label: 'No Concern',
    color: 'grey',
  },
  no_tfs: {
    label: 'TFS Not Available',
    color: 'grey',
  },
};
