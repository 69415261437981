// META ACTIONS
export const RESET_APP = 'RESET_APP';
export const SET_LANG = 'SET_LANG';

// Media
export const PLAY_PAUSE = 'PLAY_PAUSE';

// Modals
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// Student Auth
export const LOGIN_STUDENT_START = 'LOGIN_STUDENT_START';
export const LOGIN_STUDENT_SUCCESS = 'LOGIN_STUDENT_SUCCESS';
export const LOGIN_STUDENT_FAIL = 'LOGIN_STUDENT_FAIL';

// Wellness Center Auth
export const LOGIN_WELLNESSCENTER_START = 'LOGIN_WELLNESSCENTER_START';
export const LOGIN_WELLNESSCENTER_SUCCESS = 'LOGIN_WELLNESSCENTER_SUCCESS';
export const LOGIN_WELLNESSCENTER_FAIL = 'LOGIN_WELLNESSCENTER_FAIL';

// Auth
export const CHECK_AUTH_STATE_START = 'CHECK_AUTH_STATE_START';
export const CHECK_AUTH_STATE_SUCCESS = 'CHECK_AUTH_START_SUCCESS';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const SET_TEMP_USER = 'SET_TEMP_USER';

export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';

export const CHANGE_ROLE = 'CHANGE_ROLE';
export const RESET_ROLE = 'RESET_ROLE';

// logout user/admin
export const LOGOUT_START = 'LOGOUT_START';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS ';
export const LOGOUT_FAIL = 'LOGOUT_FAIL';

// Survey Response
export const CREATE_SURVEY_RESPONSE = 'CREATE_SURVEY_RESPONSE';
export const TOGGLE_SURVEY_ANSWER = 'TOGGLE_SURVEY_ANSWER';
export const PUSH_SELECTED_ANSWER = 'PUSH_SELECTED_ANSWER';
export const PUSH_SECONDARY_INDICATOR_RESPONSES = 'PUSH_SECONDARY_INDICATOR_RESPONSES';
export const INCREMENT_SURVEY_INDICATOR_INDEX = 'INCREMENT_SURVEY_INDICATOR_INDEX';
export const INCREMENT_SURVEY_INDICATOR_RESPONSE_INDEX = 'INCREMENT_SURVEY_INDICATOR_RESPONSE_INDEX';
export const DEMO_ANSWER_SELECTED = 'DEMO_ANSWER_SELECTED';
export const INC_DEMO_INDEX = 'INC_DEMO_INDEX';
export const TOGGLE_DEMO_RADIO = 'TOGGLE_DEMO_RADIO';
export const SAVE_SURVEY_RESPONSE = 'SAVE_SURVEY_RESPONSE';
export const PUSH_SELECTED_VALIDITY_ANSWER = 'PUSH_SELECTED_VALIDITY_ANSWER';
export const SET_SURVEY_SUBMITTED_AT = 'SET_SURVEY_SUBMITTED_AT';

export const SAVE_SURVEY_RESPONSE_START = 'SAVE_SURVEY_RESPONSE_START';
export const SAVE_SURVEY_RESPONSE_SUCCESS = 'SAVE_SURVEY_RESPONSE_SUCCESS';
export const SAVE_SURVEY_RESPONSE_FAIL = 'SAVE_SURVEY_RESPONSE_FAIL';

// SURVEY CRUD
export const CLEAR_CREATE_SURVEY_STATE = 'CLEAR_CREATE_SURVEY_STATE';
export const SHOW_SURVEY_SEARCH = 'SHOW_SURVEY_SEARCH';
export const HIDE_SURVEY_SEARCH = 'HIDE_SURVEY_SEARCH';
export const SET_SEARCH_VALUES = 'SET_SEARCH_VALUES';
export const SET_SEARCH_VALUES_START = 'SET_SEARCH_VALUES_START';
export const SET_SEARCH_VALUES_FAIL = 'SET_SEARCH_VALUES_FAIL';
export const SUBMIT_SURVEY_SEARCH_START = 'SUBMIT_SURVEY_SEARCH_START';
export const SUBMIT_SURVEY_SEARCH_SUCCESS = 'SUBMIT_SURVEY_SEARCH_SUCCESS';
export const SUBMIT_SURVEY_SEARCH_FAIL = 'SUBMIT_SURVEY_SEARCH_FAIL';

export const CLEAR_DISTRICT_OPTION = 'CLEAR_DISTRICT_OPTION';
export const CLEAR_STATE_OPTION = 'CLEAR_STATE_OPTION';
export const CLEAR_SCHOOL_OPTION = 'CLEAR_SCHOOL_OPTION';
export const UPDATE_SEARCH_SURVEY = 'UPDATE_SEARCH_SURVEY';

export const LIST_INDICATORS_START = 'LIST_INDICATORS_START';
export const LIST_INDICATORS_SUCCESS = 'LIST_INDICATORS_SUCCESS';
export const LIST_INDICATORS_FAIL = 'LIST_INDICATORS_FAIL';

export const LIST_PROFESSIONS_SUCCESS = 'LIST_PROFESSIONS_SUCCESS';

export const GET_SURVEY_START = 'GET_SURVEY_START';
export const GET_SURVEY_SUCCESS = 'GET_SURVEY_SUCCESS';
export const GET_SURVEY_FAIL = 'GET_SURVEY_FAIL';

export const GET_AGGREGATED_OVERALL_REPORT_START = 'GET_AGGREGATED_OVERALL_REPORT_START';
export const GET_AGGREGATED_OVERALL_REPORT_SUCCESS = 'GET_AGGREGATED_OVERALL_REPORT_SUCCESS';
export const GET_AGGREGATED_OVERALL_REPORT_FAIL = 'GET_AGGREGATED_OVERALL_REPORT_FAIL';

export const DELETE_SURVEY_START = 'DELETE_SURVEY_START';
export const DELETE_SURVEY_SUCCESS = 'DELETE_SURVEY_SUCCESS';
export const DELETE_SURVEY_FAIL = 'DELETE_SURVEY_FAIL';

export const CLEAR_SURVEY_DETAILS = 'CLEAR_SURVEY_DETAILS';

export const LIST_SURVEY_START = 'LIST_SURVEY_START';
export const LIST_SURVEY_SUCCESS = 'LIST_SURVEY_SUCCESS';
export const LIST_SURVEY_FAIL = 'LIST_SURVEY_FAIL';

export const CREATE_SURVEY_START = 'CREATE_SURVEY_START';
export const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS';
export const CREATE_SURVEY_FAIL = 'CREATE_SURVEY_FAIL';

export const SET_PARTICIPANT_VALUES = 'SET_PARTICIPANT_VALUES';
export const UPDATE_PARTICIPANT_STARTED = 'UPDATE_PARTICIPANT_STARTED';
export const BULK_UPDATE_PARTICIPANT_STARTED = 'BULK_UPDATE_PARTICIPANT_STARTED';
export const TOGGLE_PARTICIPANT_CONTACTED = 'TOGGLE_PARTICIPANT_CONTACTED';
export const UPDATE_PARTICIPANT_START = 'UPDATE_PARTICIPANT_START';
export const UPDATE_PARTICIPANT_SUCCESS = 'UPDATE_PARTICIPANT_SUCCESS';
export const UPDATE_PARTICIPANT_FAIL = 'UPDATE_PARTICIPANT_FAIL';
export const BULK_UPDATE_PARTICIPANT_START = 'BULK_UPDATE_PARTICIPANT_START';
export const BULK_UPDATE_PARTICIPANT_SUCCESS = 'BULK_UPDATE_PARTICIPANT_SUCCESS';
export const BULK_UPDATE_PARTICIPANT_FAIL = 'BULK_UPDATE_PARTICIPANT_FAIL';
export const BULK_DELETE_PARTICIPANT_START = 'BULK_DELETE_PARTICIPANT_START';
export const BULK_DELETE_PARTICIPANT_SUCCESS = 'BULK_DELETE_PARTICIPANT_SUCCESS';
export const BULK_DELETE_PARTICIPANT_FAIL = 'BULK_DELETE_PARTICIPANT_FAIL';

export const GET_SIGNED_URL_START = 'GET_SIGNED_URL_START';
export const GET_SIGNED_URL_SUCCESS = 'GET_SIGNED_URL_SUCCESS';
export const GET_SIGNED_URL_FAIL = 'GET_SIGNED_URL_FAIL';

export const GET_ALL_PARTICIPANT_DATA_START = 'GET_ALL_PARTICIPANT_DATA_START';
export const GET_ALL_PARTICIPANT_DATA_SUCCESS = 'GET_ALL_PARTICIPANT_DATA_SUCCESS';
export const GET_ALL_PARTICIPANT_DATA_FAIL = 'GET_ALL_PARTICIPANT_DATA_FAIL';

export const GET_ALL_NOTIFICATION_DATA_START = 'GET_ALL_NOTIFICATION_DATA_START';
export const GET_ALL_NOTIFICATION_DATA_SUCCESS = 'GET_ALL_NOTIFICATION_DATA_SUCCESS';
export const GET_ALL_NOTIFICATION_DATA_FAIL = 'GET_ALL_NOTIFICATION_DATA_FAIL';

export const GET_ALL_NOTIFICATION_RESPONSES_START = 'GET_ALL_NOTIFICATION_RESPONSES_START';
export const GET_ALL_NOTIFICATION_RESPONSES_SUCCESS = 'GET_ALL_NOTIFICATION_RESPONSES_SUCCESS';
export const GET_ALL_NOTIFICATION_RESPONSES_FAIL = 'GET_ALL_NOTIFICATION_RESPONSES_FAIL';

// Create New Survey
export const TOGGLE_CHECKED_INDICATOR_IN_FORM = 'TOGGLE_CHECKED_INDICATOR_IN_FORM';
export const SET_APPLIED_TO = 'SET_APPLIED_TO';
export const SET_INDICATORS_BY_CATEGORY_AND_TYPE = 'SET_INDICATORS_BY_CATEGORY_AND_TYPE';
export const SET_CHOSEN_INDICATORS = 'SET_CHOSEN_INDICATORS';
export const SET_PROFESSION = 'SET_PROFESSION';
export const SET_SURVEY_QUARTER = 'SET_SURVEY_QUARTER';
export const SET_SURVEY_YEAR = 'SET_SURVEY_YEAR';
export const SET_DESIGNATED_EMAIL_RECIPIENT = 'SET_DESIGNATED_EMAIL_RECIPIENT';

// Upload Student Roster
export const UPLOAD_ROSTER_START = 'UPLOAD_ROSTER_START';
export const UPLOAD_ROSTER_SUCCESS = 'UPLOAD_ROSTER_SUCCESS';
export const UPLOAD_ROSTER_FAIL = 'UPLOAD_ROSTER_FAIL';

export const CHECK_ROSTER_UPLOAD_STATUS_SUCCESS = 'CHECK_ROSTER_UPLOAD_STATUS_SUCCESS';
export const CHECK_ROSTER_UPLOAD_STATUS_FAIL = 'CHECK_ROSTER_UPLOAD_STATUS_FAIL';

export const CLEAR_MANUAL_ROSTER = 'CLEAR_MANUAL_ROSTER';

export const ADD_TO_MANUAL_ROSTER_LENGTH = 'ADD_TO_MANUAL_ROSTER_LENGTH';

export const ADD_PARTICIPANT_TO_SURVEY_START = 'ADD_PARTICIPANT_TO_SURVEY_START';
export const ADD_PARTICIPANT_TO_SURVEY_SUCCESS = 'ADD_PARTICIPANT_TO_SURVEY_SUCCESS';
export const ADD_PARTICIPANT_TO_SURVEY_FAIL = 'ADD_PARTICIPANT_TO_SURVEY_FAIL';

export const DELETE_PARTICIPANT_FROM_SURVEY_START = 'DELETE_PARTICIPANT_FROM_SURVEY_START';
export const DELETE_PARTICIPANT_FROM_SURVEY_SUCCESS = 'DELETE_PARTICIPANT_FROM_SURVEY_SUCCESS';
export const DELETE_PARTICIPANT_FROM_SURVEY_FAIL = 'DELETE_PARTICIPANT_FROM_SURVEY_FAIL';

// Reports
export const GET_REPORT_START = 'GET_REPORT_START';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_FAIL = 'GET_REPORT_FAIL';

export const SEARCH_REPORT_START = 'SEARCH_REPORT_START';
export const SEARCH_REPORT_SUCCESS = 'SEARCH_REPORT_SUCCESS';
export const SEARCH_REPORT_FAIL = 'SEARCH_REPORT_FAIL';
export const UPDATE_SEARCH_REPORT = 'UPDATE_SEARCH_REPORT';
export const TOGGLE_SHOW_REPORT_SEARCH = 'TOGGLE_SHOW_REPORT_SEARCH';
export const SET_SEARCH_VALUES_REPORTS = 'SET_SEARCH_VALUES_REPORTS';
export const SET_SEARCH_VALUES_REPORTS_START = 'SET_SEARCH_VALUES_REPORTS_START';
export const SET_SEARCH_VALUES_REPORTS_FAIL = 'SET_SEARCH_VALUES_REPORTS_FAIL';
export const CLEAR_REPORT_STATE_OPTION = 'CLEAR_REPORT_STATE_OPTION';
export const CLEAR_REPORT_DISTRICT_OPTION = 'CLEAR_REPORT_DISTRICT_OPTION';
export const CLEAR_REPORT_SCHOOL_OPTION = 'CLEAR_REPORT_SCHOOL_OPTION';
export const CLEAR_SEARCH_REPORTS_PAGINATION_VALUES = 'CLEAR_SEARCH_REPORTS_PAGINATION_VALUES';

// OVERALL SURVEY REPORT
export const GET_OVERALL_SURVEY_REPORT_START = 'GET_OVERALL_SURVEY_REPORT_START';
export const GET_OVERALL_SURVEY_REPORT_FAIL = 'GET_OVERALL_SURVEY_REPORT_FAIL';
export const GET_OVERALL_SURVEY_REPORT_SUCCESS = 'GET_OVERALL_SURVEY_REPORT_SUCCESS';

// USERS
export const LIST_USERS_START = 'LIST_USERS_START';
export const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS';
export const LIST_USERS_FAIL = 'LIST_USERS_FAIL';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const CLEAR_USERS_LIST = 'CLEAR_USERS_LIST';
export const CLEAR_USER_ERRORS = 'CLEAR_USER_ERRORS';

// ORGANIZATIONS
export const LIST_ORGANIZATIONS_START = 'LIST_ORGANIZATIONS_START';
export const LIST_ORGANIZATIONS_SUCCESS = 'LIST_ORGANIZATIONS_SUCCESS';
export const LIST_ORGANIZATIONS_FAIL = 'LIST_ORGANIZATIONS_FAIL';

export const GET_ORGANIZATION_START = 'GET_ORGANIZATION_START';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAIL = 'GET_ORGANIZATION_FAIL';

export const UPDATE_ORGANIZATION_START = 'UPDATE_ORGANIZATION_START';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAIL = 'UPDATE_ORGANIZATION_FAIL';

export const CREATE_ORGANIZATION_START = 'CREATE_ORGANIZATION_START';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAIL = 'CREATE_ORGANIZATION_FAIL';

export const DELETE_ORGANIZATION_START = 'DELETE_ORGANIZATION_START';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_FAIL = 'DELETE_ORGANIZATION_FAIL';

export const CHANGE_ORGANIZATION_SUCCESS = 'CHANGE_ORGANIZATION_SUCCESS';
export const CLEAR_ORGANIZATION = 'CLEAR_ORGANIZATION';
export const CLEAR_ORGANIZATION_SEARCH_OPTIONS = 'CLEAR_ORGANIZATION_SEARCH_OPTIONS';
export const CLEAR_ORGANIZATION_ERRORS = 'CLEAR_ORGANIZATION_ERRORS';
export const SET_SEARCH_VALUES_ORGANIZATION = 'SET_SEARCH_VALUES_ORGANIZATION';
export const RESET_ORGANIZATION_SUCCESS = 'RESET_ORGANIZATION_SUCCESS';
export const HIDE_ORG_SEARCH = 'HIDE_ORG_SEARCH';
export const UPDATE_ORGANIZATION_SEARCH = 'UPDATE_ORGANIZATION_SEARCH';
export const SHOW_ORG_SEARCH = 'SHOW_ORG_SEARCH';

// Toaster
export const OPEN_TOASTER = 'OPEN_TOASTER';
export const CLOSE_TOASTER = 'CLOSE_TOASTER';

// Report Notifications
export const NOTIFY_PARENT_REPORT_PENDING = 'NOTIFY_PARENT_REPORT_PENDING';
export const NOTIFY_PARENT_REPORT_SUCCESS = 'NOTIFY_PARENT_REPORT_SUCCESS';
export const NOTIFY_PARENT_REPORT_FAIL = 'NOTIFY_PARENT_REPORT_FAIL';
export const BULK_NOTIFY_PARENT_REPORT_PENDING = 'BULK_NOTIFY_PARENT_REPORT_PENDING';
export const BULK_NOTIFY_PARENT_REPORT_SUCCESS = 'BULK_NOTIFY_PARENT_REPORT_SUCCESS';
export const BULK_NOTIFY_PARENT_REPORT_FAIL = 'BULK_NOTIFY_PARENT_REPORT_FAIL';

export const NOTIFY_HISTORY_MODAL = 'NOTIFY_HISTORY_MODAL';
export const GET_NOTIFICATION_LOG_START = 'GET_NOTIFICATION_LOG_START';
export const GET_NOTIFICATION_LOG_SUCCESS = 'GET_NOTIFICATION_LOG_SUCCESS';
export const GET_NOTIFICATION_LOG_FAIL = 'GET_NOTIFICATION_LOG_FAIL';

export const ADULT_NOTIFY_REPORT_SUCCESS = 'ADULT_NOTIFY_REPORT_SUCCESS';
export const ADULT_NOTIFY_REPORT_FAIL = 'ADULT_NOTIFY_REPORT_FAIL';

export const INCREMENT_QUESTION_INDEX = 'INCREMENT_QUESTION_INDEX';