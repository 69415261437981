import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { json2csv } from 'json-2-csv';
import * as actions from '../../../../store/actions';
import Spinner from '../../../../components/Spinner/Spinner';
import Backdrop from '../../../../components/Backdrop/Backdrop';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import DescriptionIcon from '@material-ui/icons/Description';
import { ReactComponent as ReportIcon } from '../../../../assets/imgs/icon-survey-report.svg';
import { ReactComponent as EmailSuccessIcon } from '../../../../assets/imgs/icon_notification_email_success.svg';
import { ReactComponent as EmailFailIcon } from '../../../../assets/imgs/icon_notification_email_error.svg';
import { ReactComponent as TextSuccessIcon } from '../../../../assets/imgs/icon_notification_sms_success.svg';
import { ReactComponent as TextFailIcon } from '../../../../assets/imgs/icon_notification_sms_error.svg';
import { ReactComponent as EmailUnsent } from '../../../../assets/imgs/icon_notification_email_standard.svg';
import { ReactComponent as TextUnsent } from '../../../../assets/imgs/icon_notification_sms_standard.svg';
import TableGenerator from '../../../../components/TableGenerator/TableGenerator';
import { getContent } from '../../../../utilities/getContent/getContent';
import { dateFormat, adjustDate } from '../../../../utilities/dates';

class SurveyDetails extends Component {
	componentDidMount() {
		if (this.props.location.state.surveyId) {
			this.props.getSurvey(this.props.location.state.surveyId);
		}
	}

	componentWillUnmount() {
		this.props.clearSurveyDetails();
	}

	// Participant Delete handler
	deleteItemHandler = item => {
		console.log('Delete Item handler - item ID: ', item);
		const { surveyParticipantId } = item;
		this.openDeleteParticipantConfirmationModal(surveyParticipantId, this.props.location.state.surveyId);
	};

	openDeleteParticipantConfirmationModal = (surveyParticipantId, surveyId) => {
		this.props.openModal({
			modalType: 'GENERIC_MODAL',
			show: true,
			headline: 'Are you sure?',
			content: (
				<div>
					<p>Are you sure you want to continue? You cannot undo this action.</p>
					<Button
						type="button"
						className="small-button green"
						size="lg"
						onClick={() => this.props.deleteParticipantFromSurvey(surveyParticipantId, surveyId)}
					>
						Delete Participant
					</Button>
					<Button
						type="button"
						className="small-blue-button-outlined"
						size="lg"
						onClick={this.props.closeModal}
					>
						Cancel
					</Button>
				</div>
			)
		});
	};

	openDeleteSurveyConfirmationModal = () => {
		this.props.openModal({
			modalType: 'GENERIC_MODAL',
			show: true,
			headline: 'Are you sure?',
			content: (
				<div>
					<p>Are you sure you want to continue? You cannot undo this action.</p>
					<Button
						type="button"
						className="small-button green"
						size="lg"
						onClick={() =>
							this.props.deleteSurvey(this.props.surveyDetails.surveyId, this.redirectToSurveyList)
						}
					>
						Delete Survey
					</Button>
					<Button
						type="button"
						className="small-blue-button-outlined"
						size="lg"
						onClick={this.props.closeModal}
					>
						Cancel
					</Button>
				</div>
			)
		});
	};

	addParticipantHandler = () => {
		const { surveyId, orgId } = this.props.surveyDetails;
		this.props.openModal({
			// surveyId: this.props.location.state.surveyId,
			surveyId,
			orgId,
			modalType: 'ADD_PARTICIPANT_MODAL',
			show: true,
			closeModal: this.props.closeModal
		});
	};

	surveyStatusModal = () => {
		const { surveyStatus } = this.props.surveyDetails;
		console.log(surveyStatus);
		this.props.openModal({
			show: surveyStatus === 'Adding Students',
			modalType: 'SURVEY_STATUS_MODAL',
			surveyStatus: surveyStatus
		});
	};

	editParticipantHandler = item => {
		console.log('Edit Participant Handler: ', item);
		this.props.openModal({
			modalType: 'EDIT_PARTICIPANT_MODAL',
			show: true,
			item: item,
			surveyId: this.props.location.state.surveyId,
			surveyStatus: this.props.surveyDetails.surveyStatus,
			closeModal: this.props.closeModal
		});
	};

	printStudentList = () => {
		window.print();
	};

	notifyParentReportHandler = item => {
		console.log('Notify Parent Report Handler: ', item);
		this.props.openModal({
			modalType: 'NOTIFY_PARENT_MODAL',
			show: true,
			item: item,
			surveyId: this.props.location.state.surveyId,
			surveyStatus: this.props.surveyDetails.surveyStatus,
			closeModal: this.props.closeModal
		});
	};

	notificationLogHandler = async item => {
		await this.props.getNotificationLog(item.surveyParticipantId, this.props.location.state.surveyId);

		// const logData = [
		// 	{
		// 		timestamp: 1571106114918,
		// 		destinationEmail: 'cvandyke@fusionalliance.com',
		// 		messageStatus: 'Delivery'
		// 	},
		// 	{
		// 		timestamp: 1571106114918,
		// 		destinationEmail: 'cvandyke1217@gmail.com',
		// 		messageStatus: 'Bounce'
		// 	}
		// ]
		this.props.openModal({
			modalType: 'NOTIFY_HISTORY_MODAL',
			show: true,
			item: item,
			surveyId: this.props.location.state.surveyId,
			surveyStatus: this.props.surveyDetails.surveyStatus,
			closeModal: this.props.closeModal
		});
	};

	bulkEditParticipantHandler = () => {
		this.props.openModal({
			modalType: 'BULK_EDIT_PARTICIPANT_MODAL',
			participants: this.props.surveyDetails.participants,
			surveyId: this.props.location.state.surveyId,
			show: true,
			closeModal: this.props.closeModal
		});
	};

	bulkDeleteParticipantHandler = () => {
		this.props.openModal({
			modalType: 'BULK_DELETE_PARTICIPANT_MODAL',
      participants: this.props.surveyDetails.participants,
      surveyId: this.props.location.state.surveyId,
			show: true,
			closeModal: this.props.closeModal
		});
	};

	bulkNotificationHandler = () => {
		this.props.openModal({
			modalType: 'BULK_NOTIFY_PARENT_MODAL',
			show: true,
			surveyId: this.props.location.state.surveyId,
			surveyStatus: this.props.surveyDetails.surveyStatus,
			closeModal: this.props.closeModal
		});
	};

	printStudentList = () => {
		window.print();
	};
	getReportIcon = (num, item) => {
		return (
			<Link
				className="button-text report-link"
				to={{
					pathname: `/admin/reports/student?pId=${item.surveyParticipantId}`
				}}
				target="_blank"
			>
				<span className="report-icon" data-num={num > 0 ? num : null} data-contacted={item.contacted}>
					<ReportIcon />
				</span>
			</Link>
		);
	};

	getContextMenu = item => {
		return (
			<div className="dropdown">
				<div className="small-gray-button-outlined context-menu">...</div>
				<div className="dropdown-content">
					<div>
						<span onClick={() => this.editParticipantHandler(item)}>
							<Edit className="blue-fill-icon" /> Edit
						</span>
						{this.props.surveyDetails.surveyStatus === 'Created' ? (
							<span onClick={() => this.deleteItemHandler(item)}>
								<Delete className="blue-fill-icon" />
								Delete
							</span>
						) : null}
						{item.participantStatus === 'Completed' ? (
							item.parentEmail || item.parentMobile ? (
								item.emailStatus === 'Delivery' || item.emailStatus === 'Bounce' ? (
									<span onClick={() => this.notifyParentReportHandler(item)}>
										<NotificationsActiveIcon className="blue-fill-icon" onClick={() => {}} />
										Resend Notification
									</span>
								) : (
									<span onClick={() => this.notifyParentReportHandler(item)}>
										<NotificationsActiveIcon className="blue-fill-icon" onClick={() => {}} />
										Send Notification
									</span>
								)
							) : null
						) : null}
						{item.participantStatus === 'Completed' ? (
							item.emailStatus ? (
								<span onClick={() => this.notificationLogHandler(item)}>
									<DescriptionIcon className="blue-fill-icon" onClick={() => {}} />
									Notification History
								</span>
							) : null
						) : null}
					</div>
				</div>
			</div>
		);
	};

	generateTableData = () => {
		// Waits for survey details to be populated
		if (
			this.props.surveyDetails &&
			this.props.surveyDetails.participants &&
			this.props.surveyDetails.participants.length > 0
		) {
			if (
				this.props.surveyDetails.LastEvaluatedKey &&
				this.props.surveyDetails.LastEvaluatedKey.surveyParticipantId
			) {
				this.setState({ nextKey: this.props.surveyDetails.LastEvaluatedKey.surveyParticipantId, next: true });
			}
			const isAdult = this.props.surveyDetails.surveyName.match(/Adult/i) === null;
			const gradeText = isAdult
				? getContent('admin_participant-entry-grade')
				: getContent('admin_participant-entry-year');
			const result = this.props.surveyDetails.participants.map(item => {
				return {
					name:
						item.participantStatus !== 'Completed' ? (
							<span>
								{`${item.lastName}, ${item.firstName} ${item.middleName || ''}`}
								<div className="grade-label">
									{gradeText}: {item.grade}
								</div>
							</span>
						) : (
							<span>
								<Link
									className="button-text"
									disabled={item.participantStatus !== 'Completed'}
									to={{
										pathname: `/admin/reports/parent?pId=${item.surveyParticipantId}`
									}}
									target="_blank"
								>
									{`${item.lastName}, ${item.firstName} ${item.middleName || ''}`}
								</Link>
								<div className="grade-label">Grade: {item.grade}</div>
							</span>
						),
					// grade: item.grade,
					DOB: adjustDate(item.birthDate).format(dateFormat),
					login:
						item.participantSurveyAuthCode.length === 16
							? item.participantSurveyAuthCode.match(/.{1,4}/g).join('-')
							: item.participantSurveyAuthCode,
					status: item.participantStatus,
					result:
						item.participantStatus !== 'Completed' ? (
							<span>-</span>
						) : (
							this.getReportIcon(item.priority, item)
						),
					notification:
						item.parentEmail || item.parentMobile ? (
							item.participantStatus === 'Completed' ? (
								<span className="report-icon">
									{item.parentEmail ? (
										item.emailStatus && item.emailStatus === 'Delivery' ? (
											<EmailSuccessIcon />
										) : item.emailStatus === 'Bounce' ? (
											<EmailFailIcon />
										) : (
											<EmailUnsent />
										)
									) : (
										''
									)}
									{item.parentMobile ? (
										item.textStatus && item.textStatus === 'Delivery' ? (
											<TextSuccessIcon />
										) : item.parentMobile && (!item.textStatus || item.textStatus === 'None') ? (
											<TextUnsent />
										) : (
											<TextFailIcon />
										)
									) : (
										''
									)}
								</span>
							) : (
								<span>-</span>
							)
						) : (
							<span>-</span>
						),
					menu: this.getContextMenu(item)
				};
			});
			return result;
		}

		return [];
	};

	redirectToStudentReport = participantId => {
		this.props.history.push({
			pathname: '/admin/reports/student',
			state: {
				participantId
			}
		});
	};

	redirectToParentReport = participantId => {
		this.props.history.push({
			pathname: '/admin/reports/parent',
			state: {
				participantId
			}
		});
	};

	getBreadcrumbs = () => {
		const breadcrumbs = [
			{
				key: 1,
				url: '/admin/',
				label: 'Home'
			},
			{
				key: 2,
				url: '/admin/survey/list',
				label: 'Surveys'
			},
			{
				key: 3,
				url: '/admin/survey/details',
				label:
					this.props.surveyDetails && this.props.surveyDetails.surveyName
						? this.props.surveyDetails.surveyName
						: ''
			}
		];

		return breadcrumbs;
	};

	redirectToSurveyList = () => {
		this.props.history.push('/admin/survey/list');
	};

	exportCSVFile = async fileTitle => {
		await this.props.getAllParticipantData(this.props.location.state.surveyId);
		if (this.props && this.props.surveyParticipants) {
			console.log(this.props.surveyDetails.surveyName);
			const isAdult = this.props.surveyDetails.surveyName.match(/Adult/i) !== null;
			const gradeText = isAdult
				? getContent('admin_participant-entry-year')
				: getContent('admin_participant-entry-grade');
			const items = this.props.surveyParticipants;
			const filteredItems = items.map(item => {
				return {
					'Auth Code':
						item.participantSurveyAuthCode.length === 16
							? item.participantSurveyAuthCode.match(/.{1,4}/g).join('-')
							: item.participantSurveyAuthCode,
					[gradeText]: item.grade,
					'First Name': item.firstName,
					'Last Name': item.lastName,
					'Middle Name': item.middleName,
					'Birth Date': adjustDate(item.birthDate).format(dateFormat),
					'Group Name': item.groupName
				};
			});
			await json2csv(
				filteredItems,
				(err, csv) => {
					let exportedFilename = fileTitle + '.csv' || 'export.csv';
					var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
					if (navigator.msSaveBlob) {
						// IE 10+
						navigator.msSaveBlob(blob, exportedFilename);
					} else {
						var link = document.createElement('a');
						if (link.download !== undefined) {
							// feature detection
							// Browsers that support HTML5 download attribute
							var url = URL.createObjectURL(blob);
							link.setAttribute('href', url);
							link.setAttribute('download', exportedFilename);
							link.style.visibility = 'hidden';
							document.body.appendChild(link);
							link.click();
							document.body.removeChild(link);
						}
					}
				},
				{ emptyFieldValue: '' }
			);
		}
	};

	exportNotificationCSVFile = async fileTitle => {
		let exportedFilename = fileTitle + '.csv' || 'export.csv';
		await this.props.getAllNotificationData(this.props.location.state.surveyId);
		console.log('New Download URL: ', this.props.surveyNotificationData);
		let link = document.createElement('a');
		link.setAttribute('href', this.props.surveyNotificationData.url);
		link.setAttribute('type', 'application/csv');
		link.setAttribute('download', exportedFilename);
		link.style.visibility = 'hidden';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	render() {
		console.log('|| this.props.surveyDetails.participants.length', this.props.surveyDetails);
		if (
			this.props.surveyDetails &&
			this.props.surveyDetails.surveyStatus &&
			this.props.surveyDetails.surveyStatus === 'Adding Students'
		) {
			this.surveyStatusModal();
		}
		return this.props.isLoading ? (
			<Backdrop>
				<Spinner />
			</Backdrop>
		) : (
			<div className="create-survey-container">
				<div className="sub-header d-print-none">
					<BreadCrumbs breadcrumbs={this.getBreadcrumbs()} />
					{this.props.surveyDetails && this.props.surveyDetails.surveyStatus === 'Created' && (
						<Button
							onClick={() => this.openDeleteSurveyConfirmationModal()}
							className="small-blue-button-outlined"
						>
							Delete Survey
						</Button>
					)}
				</div>
				<div className="survey-info flex-row d-print-none">
					<div>
						<strong>Survey Name</strong>
						<p>{this.props.surveyDetails ? this.props.surveyDetails.surveyName : ''}</p>
					</div>
					<div>
						<strong>Survey Status</strong>
						<p>{this.props.surveyDetails ? this.props.surveyDetails.surveyStatus : ''}</p>
					</div>
				</div>
				<div className="d-print-none">
					<strong>Survey Questions</strong>
					<div>
						<ul>
							{this.props.surveyDetails && this.props.surveyDetails.indicators
								? this.props.surveyDetails.indicators.map(item => {
										return <li key={item}>{item}</li>;
								  })
								: null}
						</ul>
					</div>
				</div>
				<div className="sub-header d-print-none">
					<p className="mb-0">{`Participant Roster Size - ${this.props.surveyDetails &&
						(this.props.surveyDetails.count ||
							(this.props.surveyDetails.participants && this.props.surveyDetails.participants.length) ||
							0)} Participants`}</p>
					<div className="survey-list-button-header-container">
						{this.props.surveyDetails.surveyStatus === 'Completed' ? (
							<Button onClick={this.bulkNotificationHandler} className="small-red-button-outlined">
								Bulk Notification
							</Button>
						) : (
							''
						)}
						{this.props.surveyDetails.surveyStatus === 'Created' ? (
							<>
								<Button onClick={this.bulkDeleteParticipantHandler} className="small-button">
									Bulk Delete Participant
								</Button>
							</>
						) : (
							''
						)}
						{this.props.surveyDetails.surveyStatus === 'In Progress' ? (
							<Button onClick={this.bulkEditParticipantHandler} className="small-button">
								Bulk Edit Participant
							</Button>
						) : (
							''
						)}
						{this.props.surveyDetails.surveyStatus !== 'Completed' ? (
							<>
								<Button onClick={this.addParticipantHandler} className="small-button">
									Add Participant
								</Button>
							</>
						) : (
							''
						)}

						<Button
							onClick={() => {
								this.exportCSVFile(this.props.surveyDetails.surveyName + '-participants');
							}}
							className="small-blue-button-outlined"
						>
							Download Participant List
						</Button>
						<Button
							onClick={() => {
								this.exportNotificationCSVFile(this.props.surveyDetails.surveyName + '-notifications');
							}}
							className="small-blue-button-outlined"
						>
							Download Notification List
						</Button>
					</div>
				</div>
				<div className="mb-0 statusCountText d-print-none">
					{this.props.surveyDetails && this.props.surveyDetails.statusCounts
						? `Not Started: ${this.props.surveyDetails.statusCounts.notStarted} \u00A0 Not Completed: ${this.props.surveyDetails.statusCounts.notCompleted} \u00A0 Completed: ${this.props.surveyDetails.statusCounts.completed}`
						: null}
				</div>
				<TableGenerator
					pagination={{
						next: this.props.surveyDetails.lastEvaluatedKey,
						previous:
							this.props.surveyDetails.pageNumber - 1 >= 0 &&
							this.props.surveyDetails.currentExclusiveStartKey &&
							this.props.surveyDetails.currentExclusiveStartKey.surveyParticipantId
					}}
					getPrevious={() => {
						const { currentExclusiveStartKey } = this.props.surveyDetails;
						let lastKey;
						let sortKey;
						let pageNumber = this.props.surveyDetails.pageNumber - 1;
						console.log('pageNumber: ', pageNumber);

						if (
							currentExclusiveStartKey &&
							currentExclusiveStartKey.surveyParticipantId &&
							currentExclusiveStartKey['surveyParticipant-sortKey']
						) {
							//this.props.updateSearchReportsObj({ name: 'lastKey', value: currentExclusiveStartKey.surveyParticipantId });
							//this.props.updateSearchReportsObj({ name: 'sortKey', value: currentExclusiveStartKey['surveyParticipant-sortKey'] });
							//lastKey = currentExclusiveStartKey.surveyParticipantId;
							//sortKey = currentExclusiveStartKey['surveyParticipant-sortKey'];
							console.log('this.props.pages: ', this.props.surveyDetails.pages);
							console.log('this.props.pageNumber: ', this.props.surveyDetails.pageNumber);
							if (
								!this.props.surveyDetails.pages[this.props.surveyDetails.pageNumber - 1]
									.currentExclusiveStartKey
							) {
								lastKey = null;
								sortKey = null;
							} else {
								lastKey = this.props.surveyDetails.pages[this.props.surveyDetails.pageNumber - 1]
									.currentExclusiveStartKey.surveyParticipantId;
								sortKey = this.props.surveyDetails.pages[this.props.surveyDetails.pageNumber - 1]
									.currentExclusiveStartKey['surveyParticipant-sortKey'];
							}
						}
						// this.searchSubmissionHandler(lastKey, sortKey, pageNumber);
						this.props.getSurvey(this.props.location.state.surveyId, lastKey, sortKey, pageNumber);
					}}
					getNext={() => {
						const { lastEvaluatedKey } = this.props.surveyDetails;
						let lastKey;
						let sortKey;
						if (
							lastEvaluatedKey &&
							lastEvaluatedKey.surveyParticipantId &&
							lastEvaluatedKey['surveyParticipant-sortKey']
						) {
							//this.props.updateSearchReportsObj({ name: 'lastKey', value: lastEvaluatedKey.surveyParticipantId });
							//this.props.updateSearchReportsObj({ name: 'sortKey', value: lastEvaluatedKey['surveyParticipant-sortKey'] });
							lastKey = lastEvaluatedKey.surveyParticipantId;
							sortKey = lastEvaluatedKey['surveyParticipant-sortKey'];
						}
						// this.searchSubmissionHandler(lastKey, sortKey, this.props.pageNumber + 1);
						this.props.getSurvey(
							this.props.location.state.surveyId,
							lastKey,
							sortKey,
							this.props.surveyDetails.pageNumber + 1
						);
					}}
					config={{
						headers: ['PARTICIPANT', 'DATE OF BIRTH', 'LOGIN', 'STATUS', 'RESULT', 'NOTIFICATIONS', ''],
						contentRows: this.generateTableData()
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	console.log('SurveyDetails State: ', state);
	return {
		isLoading: state.surveyCrud.isLoading,
		surveyDetails: state.surveyCrud.surveyDetails,
		surveyParticipants: state.surveyCrud.surveyParticipants,
		surveyNotificationData: state.surveyCrud.surveyNotificationData,
		surveyNotificationResponses: state.surveyCrud.surveyNotificationResponses
	};
};

const mapDispatchToProps = dispatch => {
	return {
		openModal: modalProps => dispatch(actions.openModal(modalProps)),
		closeModal: () => dispatch(actions.closeModal()),
		getSurvey: (surveyId, direction, lastKey, sortKey) =>
			dispatch(actions.getSurvey(surveyId, direction, lastKey, sortKey)),
		deleteSurvey: (surveyId, cb) => dispatch(actions.deleteSurvey(surveyId, cb)),
		clearSurveyDetails: () => dispatch(actions.clearSurveyDetails()),
		deleteParticipantFromSurvey: (participantId, surveyId) =>
			dispatch(actions.deleteParticipantFromSurvey(participantId, surveyId)),
		getAllParticipantData: surveyId => dispatch(actions.getAllParticipantData(surveyId)),
		getAllNotificationData: surveyId => dispatch(actions.getAllNotificationData(surveyId)),
		getAllNotificationResponses: surveyId => dispatch(actions.getAllNotificationResponses(surveyId)),
		getNotificationLog: (participantId, surveyId) => dispatch(actions.getNotificationLog(participantId, surveyId))
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SurveyDetails)
);
