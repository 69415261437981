import React, { Component } from 'react';
import GroupHeader from './Header';
import { getContent } from '../../../../utilities/getContent/getContent';
// import MOCK_SUMMARY from '../mock-resilience-categories';
// import parse from 'html-react-parser';

class ResiliencyDistribution extends Component {
  render() {
    const payload = this.props.payload;
    const isAdult = payload.surveyName && payload.surveyName.includes('Adult');

    const { tmEducatorsLink } = this.props.links;

    return (
      <div className="page group-report" style={{marginTop:'100px'}}>
        <GroupHeader payload={payload} />
        <div className="container">
          <main className="px-5 pt-3">
            <h4 className="mb-3 text-uppercase">Participant Distribution Across Resiliency Indicators</h4>
            <table className="table table-sm table-tm-dark-striped">
              <thead>
                <tr>
                  <th>Indicator</th>
                  <th className="col-red">At Risk</th>
                  <th className="col-yellow">Moderate Concern</th>
                  <th className="col-light-green">Satisfactory</th>
                  <th className="col-green">Optimal</th>
                </tr>
              </thead>
              <tbody>
                {payload.reports.distributionForResiliency.map((indicator, index) => {
                  return (
                    <tr key={index}>
                      <td className="font-weight-bold w-20">{indicator.indicator}</td>
                      <td className="col-red w-20">
                      <div className="score font-weight-bold">
                          {indicator.at_risk > 0 ?
                            isAdult ? <span>{Math.round(indicator.at_risk)}%</span> : <a href={tmEducatorsLink}>{Math.round(indicator.at_risk)}%</a>
                          : `${Math.round(indicator.at_risk)}%`}
                        </div>
                        {(payload.previousSurveyName && indicator.previous_at_risk !== null) && <div className="previous text-dark">({payload.previousYear} - {Math.round(indicator.previous_at_risk)}%)</div>}
                      </td>
                      <td className="col-yellow w-20">
                        <div className="score font-weight-bold">{Math.round(indicator.moderate)}%</div>
                        {(payload.previousSurveyName && indicator.previous_moderate !== null) && <div className="previous text-dark">({payload.previousYear} - {Math.round(indicator.previous_moderate)}%)</div>}
                      </td>
                      <td className="col-light-green w-20">
                        <div className="score font-weight-bold">{Math.round(indicator.satisfactory)}%</div>
                        {(payload.previousSurveyName && indicator.previous_satisfactory !== null) && <div className="previous text-dark">({payload.previousYear} - {Math.round(indicator.previous_satisfactory)}%)</div>}
                      </td>
                      <td className="col-green w-20">
                        <div className="score font-weight-bold">{Math.round(indicator.optimal)}%</div>
                        {(payload.previousSurveyName && indicator.previous_optimal !== null) && <div className="previous text-dark">({payload.previousYear} - {Math.round(indicator.previous_optimal)}%)</div>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <div className="box-comment">
              <h3>What is this table telling me?</h3>
              <p>
                {isAdult ? getContent("report_overall_page05_table_1_adult") : getContent("report_overall_page05_table_1_student")}
              </p>
              <h3>{isAdult ? getContent("report_overall_page03_risk-heading_adult") : getContent("report_overall_page03_risk-heading")}</h3>
              <p>
                {isAdult ? getContent("report_overall_page05_table_2_adult") : getContent("report_overall_page05_table_2_student")}
              </p>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default ResiliencyDistribution;
