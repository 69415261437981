import Amplify from 'aws-amplify';
import { Auth } from 'aws-amplify';
import * as actionTypes from '../actionTypes';
import getAuthenticatedUser from '../../../utilities/getAuthenticatedUser';
import { getOrganization, getOrganizationSuccess } from '../organizations/organizations';
import { getUser } from '../users/users';

Amplify.configure({
  Auth: {
    //identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID || 'us-east-1:ca41d833-0bc4-4b5a-bae1-b1c2ff0309c9',
    region: process.env.REACT_APP_REGION || 'us-east-1',
    userPoolId: process.env.REACT_APP_USER_POOL_ID || 'us-east-1_Ios05vo7O',
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '7ac8pk0etqbaqfp0qjth9jmlup',
  },
});

export const checkAuthStateStart = () => {
  return {
    type: actionTypes.CHECK_AUTH_STATE_START,
  };
};

export const checkAuthStateSuccess = (isAuthenticated, idToken, accessToken, refreshToken) => {
  return {
    type: actionTypes.CHECK_AUTH_STATE_SUCCESS,
    isAuthenticated,
    idToken,
    accessToken,
    refreshToken,
  };
};

export const checkAuthState = () => {
  return async (dispatch, getState) => {
    // HACK: To stop checkAuthState when viewing the site as another role when you are admin
    if (getState().auth.realRole) {
      return;
    }

    dispatch(checkAuthStateStart());
    const user = await getAuthenticatedUser();
    if (!user) {
      return;
    }
    console.log('CHECK AUTH STATE USER: ', user);
    const idToken = user.signInUserSession.idToken.jwtToken;
    const accessToken = user.signInUserSession.accessToken.jwtToken;
    const refreshToken = user.signInUserSession.refreshToken.jwtToken;
    const role = user.attributes['custom:role'] || getState().user.auth.role;
    console.log('ROLE IN CHECK STATE: ', role);
    dispatch(checkAuthStateSuccess(user, idToken, accessToken, refreshToken));
    dispatch(getUser(user.username));

    if (user.attributes && user.attributes['custom:orgId']) {
      const orgId = user.attributes['custom:orgId'];
      if (!getState().organizations.organization && role !== 'tm_admin') {
        dispatch(getOrganization(orgId, idToken));
      } else {
        // HACK: To set isLoaded to true so layout HOC can render wrapped component
        const org = (getState().organizations.realOrganization && role !== 'tm_admin')
          ? getState().organizations.realOrganization
          : getState().organizations.organization;

        dispatch(getOrganizationSuccess(org));
      }
    } else {
      // HACK: To set isLoaded to true so layout HOC can render wrapped component
      let org = null;
      if (role !== 'tm_admin') {
        org = getState().organizations.realOrganization ? getState().organizations.realOrganization : getState().organizations.organization;
      }
      dispatch(getOrganizationSuccess(org));
    }
  };
};

export const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START,
  };
};

export const loginSuccess = (user, idToken, accessToken, refreshToken) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    user,
    idToken,
    accessToken,
    refreshToken,
  };
};

export const loginFail = err => {
  return {
    type: actionTypes.LOGIN_FAIL,
    error: err,
  };
};

export const setTempUser = user => {
  return {
    type: actionTypes.SET_TEMP_USER,
    tempUser: user,
  };
};

export const login = (username, password, redirectToChangePassword) => {
  return (dispatch, getState) => {
    dispatch(loginStart());

    Auth.signIn({
      username, // 'chuck.schleutker+tm-test-0@gmail.com',
      password, // 'Password123!',
    })
      .then(user => {
        console.log('user is: :', user);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          //dispatch(loginFail('Please change your password'));
          dispatch(setTempUser(user));
          redirectToChangePassword();
          return;
        }
        const idToken = user.signInUserSession.idToken.jwtToken;
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        const refreshToken = user.signInUserSession.refreshToken.jwtToken;

        dispatch(loginSuccess(user, idToken, accessToken, refreshToken));
        dispatch(getUser(user.username));
        const orgId = user.attributes['custom:orgId'];
        console.log('ORG ID: ', orgId);
        if (orgId) {
          dispatch(getOrganization(orgId, idToken));
        } else {
          dispatch(getOrganizationSuccess(null));
        }
      })
      .catch(err => {
        console.log('Error Logging In: ', err);
        dispatch(loginFail(err));
      });
  };
};

export const logoutStart = () => {
  return {
    type: actionTypes.LOGOUT_START,
  };
};

export const logoutSuccess = response => {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    signOutResponse: response,
  };
};

export const logoutFail = err => {
  return {
    type: actionTypes.LOGOUT_FAIL,
    error: err,
  };
};

export const logout = cb => {
  return dispatch => {
    dispatch(logoutStart());
    try {
      Auth.signOut()
        .then(res => {
          console.log('signOutResponse: ', res);
          dispatch(logoutSuccess(res));
          dispatch(resetApp());
          if (cb) {
            cb();
          }
        })
        .catch(err => {
          console.log('Error signing out: ', err);
          dispatch(logoutFail(err));
        });
    } catch (err) {
      dispatch(logoutFail(err));
    }
  };
};

export const changePasswordStart = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_START,
  };
};

export const changePasswordSuccess = () => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
  };
};

export const changePasswordFail = err => {
  return {
    type: actionTypes.CHANGE_PASSWORD_FAIL,
    error: err,
  };
};

export const changePassword = (newPassword, cb) => {
  return (dispatch, getState) => {
    dispatch(changePasswordStart());
    Auth.completeNewPassword(getState().auth.tempUser, newPassword)
      .then(user => {
        const idToken = user.signInUserSession.idToken.jwtToken;
        const accessToken = user.signInUserSession.accessToken.jwtToken;
        const refreshToken = user.signInUserSession.refreshToken.jwtToken;
        dispatch(loginSuccess(user, idToken, accessToken, refreshToken));
        if (cb) {
          cb();
        }
      })
      .catch(err => console.log(err));
  };
};

export const changeRole = (role) => {
  return {
    type: actionTypes.CHANGE_ROLE,
    role
  };
};

export const resetRole = () => {
  return {
    type: actionTypes.RESET_ROLE,
  };
};

export const resetApp = () => {
  return {
    type: actionTypes.RESET_APP,
  };
};
