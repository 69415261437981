/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useState } from 'react';
import classnames from 'classnames';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import * as QuizButton from '../QuizButton/QuizButton';
import { getContent, getAudio } from '../../../utilities/getContent/getContent';
import { color, emoticon } from './config';
const TYPES = {
	FAT: 'fat',
	SKINNY: 'skinny'
};

const QuizButtonGroup = ({
	answers,
	questionType,
	answerSelected,
	children,
	quizType,
	isSelected,
	elementary,
	indicatorName,
	responseType
}) => {
	const tempOptions = [];
	answers.map(answer => tempOptions.push(answer.text));

	const [activeAnswer, setActiveAnswer] = useState({
		selectedIndex: null,
		selectedValue: null,
		options: tempOptions
	});

	const toggleSelected = (index, value) => {
		setActiveAnswer(prevState => {
			answerSelected(true, value);
			return {
				selectedIndex: index,
				selectedValue: activeAnswer.options[index],
				options: activeAnswer.options
			};
		});
	};
	const getAnswers = () => {
		const answer = answers.sort((a, b) => (a.value > b.value ? 1 : -1));
		return questionType === 'resilience' || questionType === undefined ? answer : answer.reverse();
	};
	const shouldDisplayEmoticons =
		questionType &&
		answers &&
		answers.length && (
			responseType === 'strongly-disagree-1_to_strongly-agree-6' ||
				responseType === 'strongly-disagree-1_to_strongly-agree-7' ||
        responseType === 'strongly-disagree-6_to_strongly-agree-1' ||
        responseType === 'none-of-the-time-1_to_all-of-the-time-6'
		) &&
		(indicatorName === 'Global Satisfaction' ||
			indicatorName === 'Positive School Experiences' ||
			indicatorName === 'Hope');
	const keys = [];
	answers.map((answer, index) => {
		const newIndex = index + 1;
		return keys.push(`${newIndex}`);
	});
	return (
		<div className={classnames('quizButtonGroup', `${quizType}`)}>
			<KeyboardEventHandler
				handleKeys={keys}
				onKeyEvent={(key, e) => {
					const origIndex = parseInt(key, 10) - 1;
					toggleSelected(origIndex, answers[origIndex].value);
					console.log(key, e);
				}}
			/>
			{getAnswers().map((answer, index) => {
				const isActive = activeAnswer.selectedIndex === index && isSelected;
				return React.cloneElement(children, {
					text: getContent(answer.field),
					key: index,
					active: isActive,
					index,
					icon: shouldDisplayEmoticons ? emoticon[answers.length][index] : null,
					color: questionType && answers.length ? color[answers.length][index] : null,
					onClick: toggleSelected,
					value: answer.value,
					audio: getAudio(answer.field),
					elementary: elementary
				});
			})}
		</div>
	);
};

export const Fat = props => (
	<QuizButtonGroup {...props} quizType={TYPES.FAT}>
		<QuizButton.Fat />
	</QuizButtonGroup>
);
export const Skinny = props => (
	<QuizButtonGroup {...props} quizType={TYPES.SKINNY}>
		<QuizButton.Skinny />
	</QuizButtonGroup>
);
