import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import AppRouter from './routes/routes';
import store from './store/configureStore';

import RootModal from './components/RootModal/RootModal';

import 'bootstrap/dist/css/bootstrap.css';
import './assets/styles/app.scss';
import 'react-sweet-progress/lib/style.css';

import { initSentry } from './utilities/logging';

initSentry();

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

const ReduxApp = () => (
  <Provider store={store}>
    <AppRouter />
    <RootModal />
  </Provider>
);

ReactDOM.render(<ReduxApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
