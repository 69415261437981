import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import ListDistricts from './ListDistricts/ListDistricts';
import ListSchools from './ListSchools/ListSchools';
import * as actions from '../../../../store/actions';
import SearchOrganizationForm from './SearchOrganizationForm/SearchOrganizationForm';

class ListOrganizations extends Component {
  componentDidMount() {
    if (this.props.isOrgSearchVisible) {
      this.props.clearOrganizationSearchOptions();
      this.props.hideOrgSearch();
    }

    if (!this.props.error) {
      this.props.listOrganizations();
    }
  }
  orgSubmissionHandler = (organization) => {
    console.log(organization, this.props[`${organization}SearchName`]);
    const values = {
      name: this.props[`${organization}SearchName`]
    };
    console.log(values);
    this.props.listOrganizations(values);
  };

  openCreateOrgModal = (item = null, type) => {
    console.log('Open Create Org Modal', item, type);
    this.props.openModal({
      modalType: type,
      show: true,
      closeModal: e => this.closeModal(e),
      item: item,
      confirmCreationWithoutChampion: this.confirmCreationWithoutChampion
    });
  };

  closeModal = e => {
    // Prevent the form from submitting if the button is within the form
    if (e) {
      e.preventDefault();
    }

    this.props.closeModal();
  };

  confirmCreationWithoutChampion = item => {
    this.props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      item: item,
      headline: 'Are you sure?',
      content: (
        <div>
          <p>
            You are creating a school without any school champions - no one will be able to create surveys
            for this school yet. (You can always add School Champions later.)
          </p>
          <Button
            type="button"
            className="small-button green"
            size="lg"
            onClick={() => this.props.createOrganization(item)}
          >
            Confirm
          </Button>
          <Button
            type="button"
            className="small-blue-button-outlined"
            size="lg"
            onClick={this.props.closeModal}
          >
            Cancel
          </Button>
        </div>
      )
    });
  };

  openDeleteOrgConfirmationModal = item => {
    this.props.openModal({
      modalType: 'GENERIC_MODAL',
      show: true,
      headline: 'Are you sure?',
      content: (
        <div>
          <p>Are you sure you want to continue? You cannot undo this action.</p>
          <Button
            type="button"
            className="small-button green"
            size="lg"
            onClick={() => this.props.deleteOrganization(item.orgId)}
          >
            Delete Organization
          </Button>
          <Button
            type="button"
            className="small-blue-button-outlined"
            size="lg"
            onClick={this.props.closeModal}
          >
            Cancel
          </Button>
        </div>
      )
    });
  };

  changeRoleAndOrg = (item, isParentOrg) => {
    console.log('CHANGE ROLE AND ORG ITEM: ', item);
    let orgId = item.orgId;
    let role = 'school_champion';
    if (isParentOrg) {
      role = 'district_champion';
    }

    // CHANGE ROLE
    this.props.changeRole(role);
    // CHANGE ORGANIZATION
    this.props.changeOrganization(orgId);
    // REDIRECT
    this.props.history.push('/admin');
  };

  getBreadcrumbs = () => {
    const breadcrumbs = [
      {
        key: 1,
        url: '/admin/',
        label: 'Home'
      },
      {
        key: 2,
        url: '/admin/organizations/list',
        label: this.props.role === 'tm_admin' ? 'School Districts' : 'Schools'
      }
    ];

    return breadcrumbs;
  };

  render() {
    // const areOrganizations = this.props.organizations && this.props.organizations.length > 0;
    const modalType = this.props.role === 'tm_admin' ? 'CREATE_DISTRICT_MODAL' : 'CREATE_SCHOOL_MODAL';
    const buttonText = this.props.role === 'tm_admin' ? 'District' : 'School';
    return (
      <div>
        <div className="sub-header">
          <BreadCrumbs breadcrumbs={this.getBreadcrumbs()} />
            <div>
              <Button
                className="small-button green"
                onClick={() => this.openCreateOrgModal(null, modalType)}
              >
                {`Create ${buttonText}`}
              </Button>
              <Button
                className="small-blue-button mr-0"
                onClick={this.props.showOrgSearch}
                disabled={this.props.isOrgSearchVisible}
              >{`Search ${buttonText}s`}</Button>
            </div>
        </div>
        <SearchOrganizationForm
          onSubmit={this.orgSubmissionHandler}
          className={this.props.isOrgSearchVisible ? null: 'hidden'}
        />
        {this.props.role === 'tm_admin' ? (
          <ListDistricts
            role={this.props.role}
            realRole={this.props.realRole}
            organizations={this.props.organizations}
            openDeleteOrgConfirmationModal={this.openDeleteOrgConfirmationModal}
            openCreateOrgModal={this.openCreateOrgModal}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            changeRoleAndOrg={this.changeRoleAndOrg}
            hasSearched={this.props.hasSearched}
            isLoadingOrganizations={this.props.isLoadingOrganizations}
          />
        ) : (
          <ListSchools
            role={this.props.role}
            realRole={this.props.realRole}
            organizations={this.props.organizations}
            openDeleteOrgConfirmationModal={this.openDeleteOrgConfirmationModal}
            openCreateOrgModal={this.openCreateOrgModal}
            openModal={this.props.openModal}
            closeModal={this.props.closeModal}
            changeRoleAndOrg={this.changeRoleAndOrg}
            hasSearched={this.props.hasSearched}
            isLoadingOrganizations={this.props.isLoadingOrganizations}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    role: state.auth.role,
    realRole: state.auth.realRole,
    organizations: state.organizations.organizations,
    isLoading: state.organizations.loading,
    isOrgSearchVisible: state.organizations.isOrgSearchVisible,
    schoolSearchName:state.organizations.schoolSearchName,
    districtSearchName: state.organizations.districtSearchName,
    hasSearched: state.organizations.hasSearched,
    isLoadingOrganizations: state.organizations.isLoadingOrganizations,
    error: state.organizations.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearOrganizationSearchOptions: () => dispatch(actions.clearOrganizationSearchOptions()),
    createOrganization: item => dispatch(actions.createOrganization(item)),
    listOrganizations: (payload) => dispatch(actions.listOrganizations(payload)),
    deleteOrganization: id => dispatch(actions.deleteOrganization(id)),
    openModal: modalProps => dispatch(actions.openModal(modalProps)),
    closeModal: () => dispatch(actions.closeModal()),
    changeRole: role => dispatch(actions.changeRole(role)),
    changeOrganization: orgId => dispatch(actions.changeOrganization(orgId)),
    showOrgSearch: () => dispatch(actions.showOrgSearch()),
    hideOrgSearch: () => dispatch(actions.hideOrgSearch()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ListOrganizations));
