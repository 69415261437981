import React from 'react';
import CheckBox from '../../../../../../components/CheckBox/CheckBox';

const IndicatorSelectFormTypeGroup = (props) => {
  const { typeName, categoryName, indicatorsByCategoryAndType, chosenIndicators } = props;
  return (
    <div className={`subgroup-create-survey ${typeName}`}>
      <p className="subgroup-type-header">{typeName}</p>

      {indicatorsByCategoryAndType[categoryName][typeName].map(option => {
        return <CheckBox
          key={option.indicatorId}
          label={option.indicator}
          name={option.indicator}
          value={option.id}
          isChecked={chosenIndicators.find(id => id === option.indicatorId)}
          onClick={() => props.toggleCheckedIndicatorInForm(option.indicatorId)}
        />
      })}
    </div>
  );
};

export default IndicatorSelectFormTypeGroup;
