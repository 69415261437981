import React, { Component } from 'react';
import GroupHeader from './Header';
import { getContent } from '../../../../utilities/getContent/getContent';
import PieChart from 'react-minimal-pie-chart';

class OverallResiliency extends Component {
  render() {
    const payload = this.props.payload;
    const isAdult = payload.surveyName && payload.surveyName.includes('Adult');

    const links = this.props.links;

    const getChartData = varType => {
      let rows = [];
      payload.reports.overallResiliency.forEach(row => {
        if (row[varType] !== 0) {
          rows.push({
            title: row.title,
            value: row[varType],
            color: row.color
          });
        }
      });
      return rows;
    };
    
    const currentChartData = getChartData('value');
    const previousChartData = getChartData('previous');

    return (
      <div className="page group-report" style={{marginTop:'100px'}}>
        <GroupHeader payload={payload} />
        <div className="container">
          <main className="px-5 pt-3">
            <h1 className="mb-3 text-uppercase">
              <a href={links.tmEducatorsLink}>
                {getContent("report_overall_page03_heading1")}
              </a>
            </h1>

            <div className="row align-items-center">
              <div className="col-7">
                <h5 className=" mt-3 text-center">
                  {payload.semester} {payload.year}
                </h5>
                {isAdult ? 
                <div className="chart-container">
                  <PieChart
                    data={currentChartData}
                    label={row => {
                      return `${Math.round(row.data[row.dataIndex].percentage)}%`;
                    }}
                    labelStyle={{
                      fontSize: '5px',
                      fontFamily: 'sans-serif',
                      fill: '#fff',
                    }}
                    radius={42}
                    labelPosition={60}
                    />
                  </div> : 
                  <div className="chart-container">
                    <a href={links.tmEducatorsLink}>
                      <PieChart
                        data={currentChartData}
                        label={row => {
                          return `${Math.round(row.data[row.dataIndex].percentage)}%`;
                        }}
                        labelStyle={{
                          fontSize: '5px',
                          fontFamily: 'sans-serif',
                          fill: '#fff',
                        }}
                        radius={42}
                        labelPosition={60}
                      />
                    </a>
                  </div>}
              </div>
              {payload.previousSurveyName && (
                <div className="col-5 pt-5 ">
                  <h5 className=" mt-3 text-center">
                    {payload.previousSurveyName} <br />
                    <em>
                      <small>Historical data</small>
                    </em>
                  </h5>
                  <div className="chart-container">
                    <PieChart
                      data={previousChartData}
                      label={row => {
                        return `${Math.round(row.data[row.dataIndex].percentage)}%`;
                      }}
                      labelStyle={{
                        fontSize: '5px',
                        fontFamily: 'sans-serif',
                        fill: '#FFF'
                      }}
                      radius={42}
                      labelPosition={60}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* end .row for chart */}

            {/* Legend */}
            <div className="legend text-center mb-5">
              {payload.reports.overallResiliency.map((row, index) => {
                return (
                  <div
                    key={index}
                    className="d-inline-block p-4 font-weight-bold"
                    style={{ color: row.color }}
                  >
                    <h4>&bull; {row.title}</h4>
                  </div>
                );
              })}
            </div>

            <div className="box-comment">
              <h3>What is this chart telling me? </h3>
              <p>
                {isAdult ? getContent("report_overall_page03_risk_chart_1_adult") : getContent("report_overall_page03_risk_chart_1_student")} {isAdult ? '' : ' ' + payload.school}.
              </p>
              <h3>{isAdult ? getContent("report_overall_page03_risk-heading_adult") : getContent("report_overall_page03_risk-heading")}</h3>
            <p>{isAdult ? getContent("report_overall_page03_risk_chart_2_adult") : getContent("report_overall_page03_risk_chart_2_student")}</p>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default OverallResiliency;
