import React from 'react';
import Table from 'react-bootstrap/Table';
import uuid from 'uuid/v4';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

const TableGenerator = props => {
  const defaultOptions = {
    headers: ['First', 'Second', 'Third'],
    contentRows: [{ key1: 'this', key2: 'is', key3: 'a' }],
    rowFunction: ()=>{}
  };

  const config = { ...defaultOptions, ...props.config, };
  const makeCell = rowData => {
    let arrayContainer = [];
    // eslint-disable-next-line array-callback-return
    Object.keys(rowData).map(key => {
      arrayContainer.push(
        <td data-key={key} key={uuid()}>
          {rowData[key]}
        </td>
      );
    });
    return arrayContainer;
  };

  return (
    <>
      <Table hover>
        <thead className="tableHeader">
          <tr>
            {/* render the first index of the first object, here */}
            {config.headers.map((headerTitle, index) => {
              return <th key={index}> {headerTitle} </th>;
            })}
          </tr>
        </thead>
        <tbody>
          {/* renders all objects after the first like below */}
          {config.contentRows.map((rowData,index) => {
            return <tr key={`${uuid()}`} onClick={config.rowFunction.bind(null,index)}>{makeCell(rowData)}</tr>;
          })}
          {/* until here */}
          {/* below here is always what comes last */}
          <tr>
            <td colSpan={props.config.headers.length} />
          </tr>
        </tbody>
      </Table>
      {props.pagination && (props.pagination.previous || props.pagination.next)
        ? (
        <div className="container-fluid">
            <div className={classnames('row', { 'justify-content-between': props.pagination.previous && props.pagination.next }, { 'justify-content-flex-end': props.pagination.next && !props.pagination.previous } )}>
              {props.pagination.previous ? <Button
                type="button"
                className="small-button green m-0"
                size="sm"
                onClick={props.getPrevious}
                disabled={!props.pagination.previous ? 'disabled' : null}
              >
                Previous
            </Button> : null}
              {props.pagination.next ? <Button
                type="button"
                className="small-button green m-0"
                size="sm"
                onClick={props.getNext}
                disabled={!props.pagination.next ? 'disabled' : null}
              >
                Next
            </Button> : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TableGenerator;
