const sanitizePhoneNumber = (phoneNumber) => {
  let result = false;

  if (typeof phoneNumber === "string") {
    result = `+${phoneNumber.replace(/\D/g, '')}`;
  }

  return result;
};

export default sanitizePhoneNumber
