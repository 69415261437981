/* eslint-disable no-useless-escape */
import React from 'react';
//import axios from 'axios';
import axios from '../../../utilities/http';

import { groupBy } from 'lodash';

import { openModal, closeModal } from '../modalConductor/modalConductor';
import { openToaster } from '../toaster/toaster';
import * as actionTypes from '../actionTypes';

console.log('axios: ', axios);

const { REACT_APP_API_URL } = process.env;

export const clearCreateSurveyState = () => {
  return {
    type: actionTypes.CLEAR_CREATE_SURVEY_STATE
  };
};

export const toggleCheckedIndicatorInForm = indicatorId => {
  return {
    type: actionTypes.TOGGLE_CHECKED_INDICATOR_IN_FORM,
    indicatorId
  };
};

export const setAppliedToValue = value => {
  return {
    type: actionTypes.SET_APPLIED_TO,
    appliedTo: value
  };
};

export const setParticipantValues = participantData => {
  return {
    type: actionTypes.SET_PARTICIPANT_VALUES,
    participantData: participantData
  };
};

export const updateParticipantStarted = value => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_STARTED,
    value
  };
};

export const toggleParticipantContacted = value => {
  return {
    type: actionTypes.TOGGLE_PARTICIPANT_CONTACTED,
    value: value
  };
};

export const setAppliedTo = value => {
  return (dispatch, getState) => {
    const indicators = getState().surveyCrud.indicators;
    const chosenIndicators = getState().surveyCrud.createSurveyObj.chosenIndicators;
    dispatch(setAppliedToValue(value));
    dispatch(setIndicatorsByCategoryAndType(indicators, value));
    dispatch(removeAppliedToIndicatorIds(value, indicators, chosenIndicators));
  };
};

export const removeAppliedToIndicatorIds = (appliedTo, indicators, chosenIndicators) => {
  /* Temporarily removed for phase 3 - clear all indicators on grade/adult change */
  // const indicatorsToRemove = indicators.filter(indicator => {
  //   return !indicator.appliesTo.includes(appliedTo);
  // });
  // const indicatorIds = indicatorsToRemove.map(item => item.indicatorId);
  // // eslint-disable-next-line array-callback-return
  // const newChosenIndicators = chosenIndicators.filter(indicatorId => {
  //   if (!indicatorIds.includes(indicatorId)) {
  //     return indicatorId;
  //   }
  // });
  const newChosenIndicators = [];

  return {
    type: actionTypes.SET_CHOSEN_INDICATORS,
    chosenIndicators: newChosenIndicators
  };
};

export const setChosenIndicators = chosenIndicators => {
  return {
    type: actionTypes.SET_CHOSEN_INDICATORS,
    chosenIndicators
  };
};

export const setProfession = profession => {
  return {
    type: actionTypes.SET_PROFESSION,
    profession: profession.professionId,
  };
};

export const toggleCategoryIndicators = category => {
  return (dispatch, getState) => {
    // get indicatorIdsByCategory
    const indicatorIdsByCategory = getState().surveyCrud.indicatorIdsByCategory;

    // get createSurveyObj.chosenIndicators
    const chosenIndicators = getState().surveyCrud.createSurveyObj.chosenIndicators;
    // Determine if values need added or removed by checking if all the categories indicators are already added
    const hasAllValues = indicatorIdsByCategory[category].every(elem => chosenIndicators.indexOf(elem) > -1);

    if (hasAllValues) {
      indicatorIdsByCategory[category].forEach(item => {
        const itemIndex = chosenIndicators.indexOf(item);
        if (itemIndex >= 0) {
          chosenIndicators.splice(itemIndex, 1);
        }
      });
      const newChosenIndicators = [...new Set(chosenIndicators)];
      dispatch(setChosenIndicators(newChosenIndicators));
    } else {
      indicatorIdsByCategory[category].forEach(item => chosenIndicators.push(item));
      const newChosenIndicators = [...new Set(chosenIndicators)];
      dispatch(setChosenIndicators(newChosenIndicators));
    }
  };
};

export const setSurveyQuarter = value => {
  return {
    type: actionTypes.SET_SURVEY_QUARTER,
    quarter: value
  };
};

export const setDesignatedEmailRecipient = value => {
  return {
    type: actionTypes.SET_DESIGNATED_EMAIL_RECIPIENT,
    designatedRecipient: value
  };
};

export const setSurveyYear = value => {
  return {
    type: actionTypes.SET_SURVEY_YEAR,
    year: value
  };
};

export const hideSurveySearch = () => {
  return {
    type: actionTypes.HIDE_SURVEY_SEARCH
  };
};

export const showSurveySearch = () => {
  return {
    type: actionTypes.SHOW_SURVEY_SEARCH
  };
};

export const clearStateOptions = () => {
  return {
    type: actionTypes.CLEAR_STATE_OPTION
  };
};

export const clearDistrictOptions = () => {
  return {
    type: actionTypes.CLEAR_DISTRICT_OPTION
  };
};

export const clearSchoolOptions = () => {
  return {
    type: actionTypes.CLEAR_SCHOOL_OPTION
  };
};

export const setSearchValues = () => {
  return (dispatch, getState) => {
    dispatch(setSearchValuesStart());
    const url = `${REACT_APP_API_URL}/search?country=${getState().surveyCrud.surveySearchCountryRegion}&state=${
      getState().surveyCrud.surveySearchStateProvince
    }&district=${
      getState().surveyCrud.surveySearchDistrict && getState().surveyCrud.surveySearchDistrict.orgId
        ? getState().surveyCrud.surveySearchDistrict.orgId
        : ''
    }`;
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken }
    })
      .then(res => {
        dispatch(setSearchValuesSuccess(res.data));
        console.log('res.data', res.data);
      })
      .catch(err => {
        dispatch(setSearchValuesFail(err));
        console.log('err', err);
      });
  };
};

export const setSearchValuesStart = () => {
  return {
    type: actionTypes.SET_SEARCH_VALUES_START
  };
};

export const setSearchValuesFail = err => {
  return {
    type: actionTypes.SET_SEARCH_VALUES_FAIL,
    err
  };
};

export const setSearchValuesSuccess = values => {
  return {
    type: actionTypes.SET_SEARCH_VALUES,
    values
  };
};

export const submitSurveySearchStart = () => {
  return {
    type: actionTypes.SUBMIT_SURVEY_SEARCH_START
  };
};

export const submitSurveySearchSuccess = () => {
  return {
    type: actionTypes.SUBMIT_SURVEY_SEARCH_SUCCESS
  };
};

export const submitSurveySearchFail = () => {
  return {
    type: actionTypes.SUBMIT_SURVEY_SEARCH_FAIL
  };
};

export const listIndicatorsStart = () => {
  return {
    type: actionTypes.LIST_INDICATORS_START
  };
};

export const listIndicatorsSuccess = indicators => {
  return {
    type: actionTypes.LIST_INDICATORS_SUCCESS,
    indicators
  };
};

export const listProfessionsSuccess = professions => {
  return {
    type: actionTypes.LIST_PROFESSIONS_SUCCESS,
    professions
  }
}

export const listIndicatorsFail = err => {
  return {
    type: actionTypes.LIST_INDICATORS_FAIL,
    err
  };
};

export const listIndicators = () => {
	return (dispatch, getState) => {
		dispatch(listIndicatorsStart());
		const url = `${REACT_APP_API_URL}/survey/indicators`;
		const appliedTo = getState().surveyCrud.createSurveyObj.appliedTo;
		//dispatch(listIndicatorsSuccess(indicatorsList));

		axios({
			url,
			method: 'GET',
			headers: { Authorization: getState().auth.idToken }
		})
			.then(res => {
				console.log('INDICATORS: ', res.data);
        dispatch(listIndicatorsSuccess(res.data.indicators));
        dispatch(listProfessionsSuccess(res.data.professions));
				dispatch(setIndicatorsByCategoryAndType(res.data.indicators, appliedTo));
			})
			.catch(err => {
				dispatch(listIndicatorsFail(err));
			});
	};
};

export const setIndicatorsByCategoryAndType = (indicators, appliedTo) => {
  const indicatorsList = indicators.filter(indicator => {
    return indicator.appliesTo.includes(appliedTo);
  });

  console.log('Indicatorslist: ', indicatorsList);
  const indicatorsByCategoryAndType = {};
  const indicatorsByCategory = groupBy(indicatorsList, 'category');
  Object.keys(indicatorsByCategory)
    .sort()
    .forEach(group => {
      indicatorsByCategoryAndType[group] = groupBy(indicatorsByCategory[group], 'type');
    });

  const indicatorIdsByCategory = {};
  // eslint-disable-next-line array-callback-return
  Object.keys(indicatorsByCategory).map(key => {
    indicatorIdsByCategory[key] = indicatorsByCategory[key].map(item => item.indicatorId);
  });

  return {
    type: actionTypes.SET_INDICATORS_BY_CATEGORY_AND_TYPE,
    indicatorsByCategoryAndType,
    indicatorIdsByCategory: indicatorIdsByCategory
  };
};

export const clearSurveyDetails = () => {
  return {
    type: actionTypes.CLEAR_SURVEY_DETAILS
  };
};

export const addToManualRosterLength = () => {
  return {
    type: actionTypes.ADD_TO_MANUAL_ROSTER_LENGTH
  };
};

export const clearManualRoster = () => {
  return {
    type: actionTypes.CLEAR_MANUAL_ROSTER
  };
};

export const uploadRosterStart = () => {
  return {
    type: actionTypes.UPLOAD_ROSTER_START
  };
};

export const uploadRosterSuccess = (fileKey, ETag) => {
  return {
    type: actionTypes.UPLOAD_ROSTER_SUCCESS,
    fileKey,
    ETag
  };
};

export const uploadRosterFail = err => {
  return {
    type: actionTypes.UPLOAD_ROSTER_FAIL,
    err
  };
};

export const uploadRoster = (file, fileKey, signedUrl) => {
  return dispatch => {
    dispatch(uploadRosterStart());
    // Split the filename to get the name and type
    let fileParts = file.name.split('.');
    //let fileName = fileParts[0];
    let fileType = fileParts[1];
    //const fileKey = `district_school_${fileName}_${new Date().getTime()}.csv`;
    // var params = {
    //   Body: file,
    //   Bucket: process.env.REACT_APP_ROSTER_S3_BUCKET || 'tm-test-roster-2',
    //   Key: fileKey,
    //   ServerSideEncryption: 'AES256',
    //   Tagging: 'key1=value1&key2=value2',
    // };
    const options = {
      headers: {
        'Content-Type': fileType
      }
    };
    axios.put(signedUrl, file, options)
      .then(res => {
        console.log('Response for uploadRoser: ', res);
        dispatch(uploadRosterSuccess(fileKey, ''))
      })
      .catch(err => {
        dispatch(uploadRosterFail(err));
      })
    // s3.putObject(params, function (err, data) {
    //   if (err) {
    //     console.log(err, err.stack); // an error occurred
    //     dispatch(uploadRosterFail(err));
    //   } else {
    //     console.log('s3Response: ', data); // successful response
    //     dispatch(uploadRosterSuccess(fileKey, data.ETag));
    //   }
    //   /*
    //   data = {
    //     ETag: "\"6805f2cfc46c0f04559748bb039d69ae\"",
    //     ServerSideEncryption: "AES256",
    //     VersionId: "Ri.vC6qVlA4dEnjgRV4ZHsHoFIjqEMNt"
    //   }
    //   */
    // });
  };
};

export const getSignedUrlStart = () => {
  return {
    type: actionTypes.GET_SIGNED_URL_START,
  };
};

export const getSignedUrlSuccess = (fileKey, url) => {
  return {
    type: actionTypes.GET_SIGNED_URL_SUCCESS,
    fileKey,
    url,
  };
};

export const getSignedUrlFail = err => {
  return {
    type: actionTypes.GET_SIGNED_URL_FAIL,
    err,
  };
};

export const getSignedUrl = (file) => {
  return (dispatch, getState) => {
    dispatch(getSignedUrlStart());
    const parentOrgId = getState().organizations.organization.parentOrgId || '';
    const orgId = getState().organizations.organization.orgId;
    let fileParts = file.name.split('.');
    let fileName = fileParts[0].replace(/[^\w.-]/g, '_').toLowerCase();
    let fileKey = `${parentOrgId}_${orgId}_${fileName}_${new Date().getTime()}.csv`;
    const url = `${REACT_APP_API_URL}/presigned/${fileKey}`;
    axios({
      url,
      method: 'GET',
      headers: { 'Authorization': getState().auth.idToken }
    })
      .then(res => {
        console.log('Res from getSignedUrl', res);
        dispatch(getSignedUrlSuccess(fileKey, res.data.url));
        dispatch(uploadRoster(file, fileKey, res.data.url))
      }).catch(err => {
        dispatch(getSignedUrlFail(err));
      })
  };
};

export const checkRosterUploadStatusSuccess = () => {
  return {
    type: actionTypes.CHECK_ROSTER_UPLOAD_STATUS_SUCCESS
  };
};

export const checkRosterUploadStatusFail = () => {
  return {
    type: actionTypes.CHECK_ROSTER_UPLOAD_STATUS_FAIL
  };
};

export const checkRosterUploadStatus = () => {
  return (dispatch, getState) => {
    const fileKey = getState().surveyCrud.createSurveyObj.s3Tags.fileKey;
    const url = `${REACT_APP_API_URL}/survey/RosterStatus/${fileKey}`;
    console.log('fileKey in Check upload: ', fileKey);
    // wait on key to be propagated
    if (!fileKey) {
      return;
    }

    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken }
    })
      .then(res => {
        console.log('CHECK UPLOAD RESPONSE: ', res);
        console.log('res.data.rosterStatus', res.data.rosterStatus.toLowerCase());
        // status enum = ["processing", "error", "complete"]
        if (res.data.rosterStatus.toLowerCase() === 'complete') {
          dispatch(checkRosterUploadStatusSuccess());
        }

        if (res.data.rosterStatus.toLowerCase() === 'error') {
          dispatch(checkRosterUploadStatusFail());
          dispatch(
            openModal({
              modalType: 'GENERIC_MODAL',
              show: true,
              headline: 'Roster Error',
              content: (
                <>
                  <span className="error-text">ERROR: </span>
                  <span>
                    {res.data.message
                      ? `${res.data.message}`
                      : 'The roster you submitted did not match the correct roster format.'}
                  </span>
                </>
              )
            })
          );
        }
      })
      .catch(err => {
        dispatch(checkRosterUploadStatusFail());
      });
  };
};

export const listSurveyStart = () => {
  return {
    type: actionTypes.LIST_SURVEY_START
  };
};

export const listSurveySuccess = surveys => {
  return {
    type: actionTypes.LIST_SURVEY_SUCCESS,
    surveys
  };
};

export const listSurveyFail = err => {
  return {
    type: actionTypes.LIST_SURVEY_FAIL,
    err
  };
};

export const listSurveys = (values = {}) => {
  const LIST_SURVEY_QUERY_KEYS = ['year', 'country', 'state', 'name', 'school', 'district'];
  return (dispatch, getState) => {
    dispatch(listSurveyStart());
    const {
      organizations: { organization: org },
      auth: { role, idToken }
    } = getState();
    const { year, country, state, name, school, district } = LIST_SURVEY_QUERY_KEYS.reduce((accum, key, value) => {
      return !values[key] ? { ...accum, [key]: '' } : { ...accum, [key]: values[key] };
    }, {});
    const getSchoolOrgId = () => {
      if (org && org.orgId && role === 'school_champion') {
        return org.orgId;
      } else if (school) {
        return school;
      } else {
        return '';
      }
    };
    const getDistrictOrgId = () => {
      if (org && org.orgId && role === 'district_champion') {
        return org.orgId;
      } else if (district) {
        return district;
      } else {
        return '';
      }
    };
    const url =
      `${REACT_APP_API_URL}/surveys/?year=${year}&country=${country}&state=${state}` +
      `&districtId=${getDistrictOrgId()}&schoolId=${getSchoolOrgId()}&surveyName=${name}`;

    axios({
      url,
      method: 'GET',
      headers: { Authorization: idToken }
    })
      .then(res => {
        dispatch(listSurveySuccess(res.data.surveys));
      })
      .catch(err => {
        dispatch(listSurveyFail(err));
      });
  };
};

export const createSurveyStart = () => {
  return {
    type: actionTypes.CREATE_SURVEY_START
  };
};

export const createSurveySuccess = () => {
  return {
    type: actionTypes.CREATE_SURVEY_SUCCESS
  };
};

export const createSurveyFail = err => {
  return {
    type: actionTypes.CREATE_SURVEY_FAIL,
    err
  };
};

export const updateSearchSurveyObj = payload => {
  return {
    type: actionTypes.UPDATE_SEARCH_SURVEY,
    payload
  };
};

export const createSurvey = (participants = [], cb = null) => {
  return (dispatch, getState) => {
    dispatch(createSurveyStart());
    console.log('Participants in Create Survey: ', participants);
    const url = `${REACT_APP_API_URL}/survey`;
    // eslint-disable-next-line no-unused-vars
    const ETag = getState().surveyCrud.createSurveyObj.s3Tags.ETag;
    const fileKey = getState().surveyCrud.createSurveyObj.s3Tags.fileKey;
    const chosenIndicators = getState().surveyCrud.createSurveyObj.chosenIndicators;
    const profession = getState().surveyCrud.createSurveyObj.profession;
    const quarter = getState().surveyCrud.createSurveyObj.quarter;
    const designatedRecipient = getState().surveyCrud.createSurveyObj.designatedRecipient;
    const year = getState().surveyCrud.createSurveyObj.year;
    const appliedTo = getState().surveyCrud.createSurveyObj.appliedTo;
    const indicators = getState().surveyCrud.indicators;

    console.log('CHOSEN INDICATORS FOR CREATE SURVEY: ', chosenIndicators);

    const indicatorsList = indicators.filter(indicator => {
      return indicator.appliesTo.includes(appliedTo);
    });
    const indicatorsByCategoryAndType = {};
    const indicatorsByCategory = groupBy(indicatorsList, 'category');
    Object.keys(indicatorsByCategory).forEach(group => {
      indicatorsByCategoryAndType[group] = groupBy(indicatorsByCategory[group], 'type');
    });

    const indicatorIdsByCategory = {};
    // eslint-disable-next-line array-callback-return
    Object.keys(indicatorsByCategory).map(key => {
      indicatorIdsByCategory[key] = indicatorsByCategory[key].map(item => item.indicatorId);
    });

    // eslint-disable-next-line array-callback-return
    const categories = Object.keys(indicatorIdsByCategory).filter(categoryName => {
      if (indicatorIdsByCategory[categoryName].every(elem => chosenIndicators.indexOf(elem) > -1)) {
        return categoryName;
      }
    });

    if (categories.length === 0) {
      categories.push('selected');
    }
    console.log('Create Survey Participants: ', participants);
    // const data = {
    //   orgId: "e10b33ea-48d3-41c4-b8e2-1f21eee2db41",
    //   survey: {
    //     indicators: [String],
    //     quarter: 'Fall',
    //     year: 2019,
    //     appliedTo: 'uppergrades',
    //     categories: [String]
    //   },
    //   participants: [],
    //   s3Tags: {
    //     eTag: ETag,
    //     fileName: 'district_school_roster_epoch'
    //   }
    // }

    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: {
        orgId: getState().organizations.organization.orgId,
        survey: {
          indicators: chosenIndicators,
          profession,
          quarter,
          year,
          appliedTo: appliedTo,
          categories,
          ...(designatedRecipient && {designatedRecipient})
        },
        participants,
        fileName: fileKey
      }
    })
      .then(res => {
        dispatch(createSurveySuccess(res.data));
        if (cb) {
          cb(res.data.surveyId);
        }
      })
      .catch(err => {
        dispatch(createSurveyFail(err));
      });
  };
};

export const getSurveyStart = () => {
  return {
    type: actionTypes.GET_SURVEY_START
  };
};

export const getSurveySuccess = surveyDetails => {
  return {
    type: actionTypes.GET_SURVEY_SUCCESS,
    surveyDetails
  };
};

export const getSurveyFail = err => {
  return {
    type: actionTypes.GET_SURVEY_FAIL,
    err
  };
};

export const getSurvey = (surveyId, lastKey = null, sortKey = null, pageNumber = 0) => {
  return (dispatch, getState) => {
    dispatch(getSurveyStart());
    const url = `${REACT_APP_API_URL}/survey/${surveyId}?lastKey=${lastKey}&sortKey=${sortKey}`;
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken }
    })
      .then(res => {
        console.log('GET SURVEY RESPONSE: ', res);
        dispatch(getSurveySuccess({ ...res.data, pageNumber }));
      })
      .catch(err => {
        console.log('ERROR WITH GETSURVEY: ', err);
        dispatch(getSurveyFail(err));
      });
  };
};

export const getAggregatedOverallReportStart = () => {
  return {
    type: actionTypes.GET_AGGREGATED_OVERALL_REPORT_START
  };
};

export const getAggregatedOverallReportSuccess = reportDetails => {
  return {
    type: actionTypes.GET_AGGREGATED_OVERALL_REPORT_SUCCESS,
    reportDetails
  };
};


export const getAggregatedOverallReportFail = err => {
  return {
    type: actionTypes.GET_AGGREGATED_OVERALL_REPORT_FAIL,
    err
  };
};


export const getAggregatedOverallReport = () => {
  return (dispatch, getState) => {
    dispatch(getAggregatedOverallReportStart());
    const url = `${REACT_APP_API_URL}/survey/report`;
    axios({
      url,
      method: 'GET',
      headers: { Authorization: getState().auth.idToken }
    })
      .then(res => {
        console.log('GET REPORT RESPONSE: ', res);
        dispatch(getAggregatedOverallReportSuccess({ ...res.data.results }));
      })
      .catch(err => {
        console.log('ERROR WITH GETAGGREGATEDOVERALLREPORT: ', err);
        dispatch(getAggregatedOverallReportFail(err));
      });
  };
};

export const getAllParticipantDataStart = () => {
  return {
    type: actionTypes.GET_ALL_PARTICIPANT_DATA_START
  };
};

export const getAllParticipantDataSuccess = (surveyParticipants) => {
  return {
    type: actionTypes.GET_ALL_PARTICIPANT_DATA_SUCCESS,
    surveyParticipants
  };
};
export const getAllParticipantDataFail = (err) => {
  return {
    type: actionTypes.GET_ALL_PARTICIPANT_DATA_FAIL,
    err
  };
};
export const getAllParticipantData = (surveyId) => {
  return async(dispatch, getState) => {
    dispatch(getAllParticipantDataStart());
    const url = `${REACT_APP_API_URL}/survey/${surveyId}/participants`;
    await axios({
      url,
      method:'GET',
      headers: { Authorization: getState().auth.idToken}
    }).then(res => {
      console.log('Survey Participant Response', res.data.participants);
      dispatch(getAllParticipantDataSuccess(res.data.participants))
    }).catch(err=>{
      console.log('Error getting all survey participant data');
      dispatch(getAllParticipantDataFail(err))
    })
  }
}

export const getAllNotificationDataStart = () => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION_DATA_START
  };
};

export const getAllNotificationDataSuccess = (surveyNotificationData) => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION_DATA_SUCCESS,
    surveyNotificationData
  };
};
export const getAllNotificationDataFail = (err) => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION_DATA_FAIL,
    err
  };
};

export const getAllNotificationData = (surveyId) => {
  return async(dispatch, getState) => {
    dispatch(getAllNotificationDataStart());
    const url = `${REACT_APP_API_URL}/survey/${surveyId}/notificationData`;
    await axios({
      url,
      method:'GET',
      headers: { Authorization: getState().auth.idToken}
    }).then(res => { 
      console.log('Survey Notification Data Response', res.data);
      dispatch(getAllNotificationDataSuccess(res.data))
    }).catch(err=>{
      console.log('Error getting all notification participant data');
      dispatch(getAllNotificationDataFail(err))
    })
  }
}

export const getAllNotificationResponsesStart = () => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION_RESPONSES_START
  };
};

export const getAllNotificationResponsesSuccess = (surveyNotificationResponses) => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION_RESPONSES_SUCCESS,
    surveyNotificationResponses
  };
};
export const getAllNotificationResponsesFail = (err) => {
  return {
    type: actionTypes.GET_ALL_NOTIFICATION_RESPONSES_FAIL,
    err
  };
};

export const getAllNotificationResponses = (surveyId) => {
  return async(dispatch, getState) => {
    dispatch(getAllNotificationResponsesStart());
    const url = `${REACT_APP_API_URL}/survey/${surveyId}/notificationResponses`;
    await axios({
      url,
      method:'GET',
      headers: { Authorization: getState().auth.idToken}
    }).then(res => { 
      console.log('Survey Notification Response Info: ', res.data);
      dispatch(getAllNotificationResponsesSuccess(res.data))
    }).catch(err=>{
      console.log('Error getting all notification RESPONSE data');
      dispatch(getAllNotificationResponsesFail(err))
    })
  }
}

export const deleteSurveyStart = () => {
  return {
    type: actionTypes.DELETE_SURVEY_START
  };
};

export const deleteSurveySuccess = () => {
  return {
    type: actionTypes.DELETE_SURVEY_SUCCESS
  };
};

export const deleteSurveyFail = err => {
  return {
    type: actionTypes.DELETE_SURVEY_FAIL,
    err
  };
};

export const deleteSurvey = (surveyId, cb) => {
  return (dispatch, getState) => {
    dispatch(deleteSurveyStart());
    const url = `${REACT_APP_API_URL}/survey/${surveyId}`;

    axios({
      url,
      method: 'DELETE',
      headers: { 'Authorization': getState().auth.idToken },
    })
      .then(res => {
        console.log('DELETE SURVEY RESPONSE: ', res);
        dispatch(deleteSurveySuccess());
        dispatch(closeModal());
        dispatch(openToaster('Successfully deleted survey.'));
        if (cb) {
          cb();
        }
      })
      .catch(err => {
        console.log('ERROR WITH DELETESURVEY: ', err);
        dispatch(deleteSurveyFail(err));
      });
  };
};

export const bulkUpdateParticipantStart = () => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_START
  };
};

export const bulkUpdateParticipantSuccess = () => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_SUCCESS
  };
};

export const bulkUpdateParticipantFail = err => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_FAIL,
    err
  };
};

export const bulkUpdateParticipant = (data) => {
  console.log(data);
  return (dispatch, getState) => {
     const url = `${REACT_APP_API_URL}/survey/participants`;
    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data,
    })
      .then(res => {
        console.log(res);
        dispatch(bulkUpdateParticipantSuccess());
        dispatch(closeModal());
        dispatch(getSurvey(data.surveyId));
      })
      .catch(err => {
        console.log('ERROR WITH UpdateParticipant: ', err);
        dispatch(bulkUpdateParticipantFail(err));
      });
  };
};

export const bulkDeleteParticipantSuccess = () => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_SUCCESS
  };
};

export const bulkDeleteParticipantFail = err => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_FAIL,
    err
  };
};

export const bulkDeleteParticipant = (data) => {
  console.log(data);
  return (dispatch, getState) => {
     const url = `${REACT_APP_API_URL}/survey/participants`;
    axios({
      url,
      method: 'DELETE',
      headers: { Authorization: getState().auth.idToken },
      data,
    })
      .then(res => {
        console.log(res);
        dispatch(bulkDeleteParticipantSuccess());
        dispatch(closeModal());
        dispatch(getSurvey(data.surveyId));
      })
      .catch(err => {
        console.log('ERROR WITH UpdateParticipant: ', err);
        dispatch(bulkDeleteParticipantFail(err));
      });
  };
};

export const updateParticipantStart = () => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_START
  };
};

export const updateParticipantSuccess = () => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_SUCCESS
  };
};

export const updateParticipantFail = err => {
  return {
    type: actionTypes.UPDATE_PARTICIPANT_FAIL,
    err
  };
};

export const updateParticipant = (participantData, surveyId) => {
  console.log(participantData);
  return (dispatch, getState) => {
    const url = `${REACT_APP_API_URL}/survey/participant/${participantData.id}`;
    let data = {
      participantStatus: participantData.participantStatus,
      contacted: participantData.contacted,
      groupName: participantData.groupName,
      notes: participantData.notes
    }
    if (participantData.parentEmail) {
      data.parentEmail = participantData.parentEmail;
    }
    if (participantData.parentMobile) {
      data.parentMobile = participantData.parentMobile;
    }

    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data,
    })
      .then(res => {
        console.log(res);
        dispatch(updateParticipantSuccess());
        dispatch(closeModal());
        dispatch(getSurvey(surveyId));
      })
      .catch(err => {
        console.log('ERROR WITH UpdateParticipant: ', err);
        dispatch(updateParticipantFail(err));
      });
  };
};

export const notifyParentSuccess = () => {
  return {
    type: actionTypes.NOTIFY_PARENT_REPORT_SUCCESS
  };
};

export const notifyParentFail = err => {
  return {
    type: actionTypes.NOTIFY_PARENT_REPORT_FAIL,
    err
  };
};

export const notifyParentReport = (participantData, surveyId) => {
  console.log('Notify Function Data: ', participantData);
  return (dispatch, getState) => {
    const url = `${REACT_APP_API_URL}/survey/participant/notify-parent`;

    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: {
        participant: participantData,
        surveyId: surveyId
      }
    })
      .then(res => {
        console.log(res);
        dispatch(notifyParentSuccess());
        dispatch(closeModal());
        dispatch(getSurvey(surveyId));
      })
      .catch(err => {
        console.log('ERROR WITH NotifyParentReport: ', err);
        dispatch(notifyParentFail(err));
      });
  };
};

export const bulkNotifyParentSuccess = () => {
  return {
    type: actionTypes.BULK_NOTIFY_PARENT_REPORT_SUCCESS
  };
};

export const bulkNotifyParentFail = err => {
  return {
    type: actionTypes.BULK_NOTIFY_PARENT_REPORT_FAIL,
    err
  };
};

export const bulkNotifyParentReport = (surveyId) => {
  console.log('Bulk Notify Function Data: ', surveyId);
  return (dispatch, getState) => {
    const url = `${REACT_APP_API_URL}/survey/${surveyId}/notify-parent`;

    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: {
        surveyId: surveyId
      }
    })
      .then(res => {
        console.log(res);
        dispatch(bulkNotifyParentSuccess());
        dispatch(closeModal());
        dispatch(getSurvey(surveyId));
        dispatch(openToaster(`An email and/or text notification has been sent to all registered addresses.`));
      })
      .catch(err => {
        console.log('ERROR WITH BulkNotifyParentReport: ', err);
        dispatch(notifyParentFail(err));
      });
  };
};

export const getNotificationLog = (participantId, surveyId) => {
	return (dispatch, getState) => {
	  dispatch(getNotificationLogStart());
	  const url = `${REACT_APP_API_URL}/survey/${surveyId}/notifications/${participantId}`;
	  axios({
      url,
      method:'GET',
      headers: { Authorization: getState().auth.idToken}
	  }).then(res => {
      console.log('All notification attempts', res.data.Items);
      dispatch(getNotificationLogSuccess(res.data.Items))
	  }).catch(err=>{
      console.log('Error getting all notification data');
      dispatch(getNotificationLogFail(err))
	  })
	}
}

export const getNotificationLogStart = () => {
  return {
    type: actionTypes.GET_NOTIFICATION_LOG_START
  };
};

export const getNotificationLogSuccess = notificationAttempts => {
  console.log('Notification Log Success: ', notificationAttempts);
  return {
    type: actionTypes.GET_NOTIFICATION_LOG_SUCCESS,
    notificationAttempts
  };
};
export const getNotificationLogFail = (err) => {
  return {
    type: actionTypes.GET_NOTIFICATION_LOG_FAIL,
    err
    };
};

export const adultNotifyReport = (emailData) => {
  return (dispatch, getState) => {
    console.log('ClientSide Event Data: ', emailData);
    const url = `${REACT_APP_API_URL}/survey/participant/notify-adult`;

    axios({
      url,
      method: 'POST',
      headers: { Authorization: getState().auth.idToken },
      data: emailData
    })
      .then(res => {
        console.log(res);
        dispatch(adultNotifyReportSuccess());
      })
      .catch(err => {
        console.log('ERROR WITH NotifyParentReport: ', err);
        dispatch(adultNotifyReportFail(err));
      });
  };
};

export const adultNotifyReportSuccess = () => {
  return {
    type: actionTypes.ADULT_NOTIFY_REPORT_SUCCESS
  };
};

export const adultNotifyReportFail = err => {
  return {
    type: actionTypes.ADULT_NOTIFY_REPORT_FAIL,
    err
  };
};

export const addParticipantToSurveyStart = () => {
  return {
    type: actionTypes.ADD_PARTICIPANT_TO_SURVEY_START
  };
};

export const addParticipantToSurveySuccess = () => {
  return {
    type: actionTypes.ADD_PARTICIPANT_TO_SURVEY_SUCCESS
  };
};

export const addParticipantToSurveyFail = err => {
  return {
    type: actionTypes.ADD_PARTICIPANT_TO_SURVEY_FAIL,
    err,
  };
};

export const addParticipantToSurvey = (participant) => {
  return (dispatch, getState) => {
    dispatch(addParticipantToSurveyStart());
    const { surveyId } = participant;
    const { idToken } = getState().auth;
    const url = `${REACT_APP_API_URL}/survey/${surveyId}/participant`;
    axios({
      url,
      method: 'POST',
      headers: { 'Authorization': idToken },
      data: participant,
    })
      .then(res => {
        console.log(res);
        dispatch(addParticipantToSurveySuccess());
        dispatch(closeModal());
        dispatch(getSurvey(surveyId));
        dispatch(openToaster(`${participant.firstName} ${participant.lastName} was successfully added to the survey.`));
      })
      .catch(err => {
        console.log('ERROR WITH addParticipantToSurvey: ', err);
        dispatch(addParticipantToSurveyFail(err));
      });
  };
};

export const deleteParticipantFromSurveyStart = () => {
  return {
    type: actionTypes.DELETE_PARTICIPANT_FROM_SURVEY_START
  };
};

export const deleteParticipantFromSurveySuccess = () => {
  return {
    type: actionTypes.DELETE_PARTICIPANT_FROM_SURVEY_SUCCESS
  };
};

export const deleteParticipantFromSurveyFail = err => {
  return {
    type: actionTypes.DELETE_PARTICIPANT_FROM_SURVEY_FAIL,
    err
  };
};

export const deleteParticipantFromSurvey = (participantId, surveyId) => {
  return (dispatch, getState) => {
    dispatch(deleteParticipantFromSurveyStart());
    const url = `${REACT_APP_API_URL}/survey/participant/${participantId}`;

    axios({
      url,
      method: 'DELETE',
      headers: { Authorization: getState().auth.idToken }
    })
      .then(res => {
        console.log(res);
        dispatch(deleteParticipantFromSurveySuccess());
        dispatch(closeModal());
        dispatch(getSurvey(surveyId));
      })
      .catch(err => {
        console.log('ERROR WITH UpdateParticipant: ', err);
        dispatch(addParticipantToSurveyFail(err));
      });
  };
};
/* eslint-enable no-useless-escape */
