import React, { Component } from 'react';
import TMCard from '../../../components/TMCard/TMCard';
import { getLogo } from './../../../assets/helper';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions';
import { connect } from 'react-redux';
import AddStudent from '../../../assets/imgs/icon-plus-circle.svg';
import ButtonArrow from '../../../components/Button/ButtonArrow';

class SurveyEmailResults extends Component {
	state = {
		emailAddresses: [''],
		inputs: ['input-0']
	};

	createUI = () =>
		this.state.inputs.map(input => {
			return (
				<input
					className="mb-2"
					type="text"
					key={input}
					placeholder="email@address.com"
					onChange={this.emailAddressHandler.bind(this, input)}
				/>
			);
		});

	addInput = () => {
		const newInput = `input-${this.state.inputs.length}`;
		this.setState(prevState => ({ inputs: prevState.inputs.concat([newInput]) }));
	};

	completeSurveyAndRedirect = () => {
		const { surveyDataToSend, saveSurveyParticipantResponseData } = this.props;

		saveSurveyParticipantResponseData({
			...surveyDataToSend,
			surveySubmittedAt: Date.now()
		});
		this.props.history.push('/survey/submitting');
	};

	emailAddressHandler = (input, event) => {
		const newEmailArray = this.state.emailAddresses;
		newEmailArray[input.replace('input-', '')] = event.target.value;
		console.log(newEmailArray);
		this.setState({ emailAddresses: newEmailArray });
	};

	sendEmailHandler = async () => {
		this.state.emailAddresses.map(async emailAddress => {
			const emailData = {
				surveyParticipantId: this.props.surveyDataToSend.surveyParticipantId,
				emailAddress
			};
			await this.props.adultNotifyReport(emailData);
		});
		this.completeSurveyAndRedirect();
	};

	render() {
		return (
			<div className="page-container-survey-complete">
				<TMCard
					className="tm-card-stepper"
					header={[
						<div className="text-center mb-4">
							<img src={getLogo()} alt="Logo" />
						</div>
					]}
				>
					<p>
						If you would like to send the results of this survey to someone, please enter an email address
						now, otherwise, press continue.
					</p>
					{this.createUI()}
					<div className="mb-2">
						<img
							onClick={this.addInput}
							className="add-student-button"
							alt="Add Participant"
							src={AddStudent}
						/>
					</div>

					<div className="text-center mb-4">
						{this.state.emailAddresses && this.state.emailAddresses[0].length > 0 ? (
							<ButtonArrow
								className="button green"
								onClick={this.sendEmailHandler}
								type="submit"
								text="Send Email"
							/>
						) : (
							<ButtonArrow
								className="button green"
								onClick={this.completeSurveyAndRedirect}
								type="submit"
								text="Continue"
							/>
						)}
					</div>
				</TMCard>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		survey: state.survey,
		surveyDataToSend: state.survey.surveyDataToSend,
		currentIndicatorIndex: state.survey.indicatorIndex,
		indicatorResponseIndex: state.survey.indicatorResponseIndex,
		answerValue: state.survey.answerValue,
		receivedSurveyData: state.survey.receivedSurveyData,
		isAnswerSelected: state.survey.isAnswerSelected
	};
};

const mapDispatchToProps = dispatch => {
	return {
		saveSurveyParticipantResponseData: surveyDataToSend =>
			dispatch(actions.saveSurveyParticipantResponseData(surveyDataToSend)),
		adultNotifyReport: emailData => dispatch(actions.adultNotifyReport(emailData))
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SurveyEmailResults)
);
