import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import TMButton from '../../../components/TMButton/TMButton';
import AudioButton from '../../../components/Button/ButtonAudio';
import ArrowRight from '../../../assets/imgs/icon-arrow-right.svg';
// import * as Button from '../../../components/Button/Button';
import * as QuizButtonGroup from '../QuizButtonGroup/QuizButtonGroup';
import * as surveyActions from '../../../store/actions/survey/survey';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { getContent, getAudio } from '../../../utilities/getContent/getContent';

class SurveyQuestion extends Component {
	constructor(props) {
		super(props);
		this.questionRef = React.createRef();
	}

	handleSelectedAnswer = (selected, value) => {
		// TODO: Fix when going to the next question, to unset the selected value.. It's not being cleared.
		// console.log('Something Answers', selected, value);
		const { responseOptionsIndex: indexOptionSelected, toggleSurveyAnswer } = this.props;

		toggleSurveyAnswer({
			isSelected: selected,
			answerValue: value,
			indexOptionSelected
		});
	};

	moveToNextQuestion = () => {
		const opacityClass = 'opacity-hidden';
		let curEl = this.questionRef.current;
		curEl.classList.add(opacityClass);
		this.props.onIncrementIndex();
		//delay removing the class/animating in new question
		setTimeout(() => {
			curEl.classList.remove(opacityClass);
		}, 50);
	};

	parseQuestion = question => {
		const errorString = `SurveyQuestion missing response.questionText: ${JSON.stringify(question)}`;
		try {
			if (typeof this.props.currentQuestion.questionText !== 'string') {
				throw Error(errorString);
			}
			return getContent(this.props.currentQuestion.questionText);
		} catch (err) {
			// eslint-disable-next-line
			console.error(err);
			return 'Next to each statement, please select the answer that most accurately describes how much you agree with that statement.';
		}
	};
	//1584385532968
	render() {
		return (
			<>
				{this.props.currentQuestion.questionResponseOptionsType === 'no-response-options' ? (
					<div className="container-single-question justifyCenter" ref={this.questionRef}>
						<KeyboardEventHandler
							handleKeys={['enter']}
							onKeyEvent={(key, e) => {
								this.moveToNextQuestion();
							}}
						/>
						<div className="heading centerText">
							<p className="head">
								{this.props.currentQuestion.questionText
									? this.parseQuestion(this.props.currentQuestion.questionText)
									: ''}
							</p>
							<AudioButton
								className="my-3 mx-auto"
								label={getContent('survey_button-read-question')}
								playlist={[getAudio(this.props.currentQuestion.questionText)]}
							/>
						</div>

						<TMButton
							className="btn-lg btn-round btn-green w-300"
							disabled={false}
							onClick={this.moveToNextQuestion}
							rightIcon={ArrowRight}
						>
							{getContent('survey_button-next-question')}
						</TMButton>
					</div>
				) : (
					<div className="container-single-question" ref={this.questionRef}>
						<KeyboardEventHandler
							handleKeys={['enter']}
							onKeyEvent={(key, e) => {
								if (this.props.isAnswerSelected) {
									this.moveToNextQuestion();
								}
							}}
						/>
						<div className="heading">
							<p className="subhead">{getContent('survey_subhead-please-answer-the-following')}</p>
							<p className="head">{this.parseQuestion(this.props.currentQuestion.questionText)}</p>

							<AudioButton
								className="mt-2"
								label={getContent('survey_button-read-question')}
								playlist={[getAudio(this.props.currentQuestion.questionText)]}
							/>
						</div>
						<div className="answerContainer">
							<QuizButtonGroup.Skinny
								answers={
									this.props.receivedSurveyData.responseOptionsIndex[
										this.props.currentQuestion.questionResponseOptionsType
									]
								}
                answerSelected={this.handleSelectedAnswer}
                responseType={this.props.currentQuestion.questionResponseOptionsType}
								isSelected={this.props.isAnswerSelected}
								questionType={this.props.indicatorData.type}
								indicatorName={this.props.indicatorData.indicator}
								elementary={this.props.receivedSurveyData.surveyName.includes('Elementary')}
							/>
						</div>
						<TMButton
							className="btn-lg btn-round btn-green w-300"
							disabled={!this.props.isAnswerSelected}
							onClick={this.moveToNextQuestion}
							rightIcon={ArrowRight}
						>
							{getContent('survey_button-next-question')}
						</TMButton>
					</div>
				)}
			</>
		);
	}
}

SurveyQuestion.propTypes = {
	isAnswerSelected: PropTypes.bool,
	responseIndex: PropTypes.number,
	responseOptionsIndex: PropTypes.number,
	indicatorData: PropTypes.object,
	answerValue: PropTypes.number,
	onIncrementIndex: PropTypes.func,
	surveyActions: PropTypes.object,
	toggleSurveyAnswer: PropTypes.func,
	incrementSurveyIndicatorIndex: PropTypes.func
};

const mapStateToProps = state => {
	return {
		isAnswerSelected: state.survey.isAnswerSelected,
		answerValue: state.survey.answerValue,
		responseIndex: state.survey.indicatorResponseIndex,
		responseOptionsIndex: state.survey.indicatorResponseOptionsIndex,
		receivedSurveyData: state.survey.receivedSurveyData,
		lang: state.meta.lang
	};
};

const mapDispatchToProps = dispatch => {
	return {
		toggleSurveyAnswer: bindActionCreators(surveyActions.toggleSurveyAnswer, dispatch),
		incrementSurveyIndicatorIndex: () => dispatch(surveyActions.incrementSurveyIndicatorIndex())
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SurveyQuestion)
);
