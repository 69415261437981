import React from 'react';

const ButtonNav = props => (
  <div className="col">
    <button onClick={props.onClick} disabled={props.disabled} className="nav">
      <div className="icon">{props.icon}</div>
      <div className="mainText">{props.text}</div>
      <div className="desc">{props.desc}</div>
    </button>
  </div>
);

export default ButtonNav;
