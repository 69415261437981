import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getContent } from '../../../../../utilities/getContent/getContent';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import DropdownList from 'react-widgets/lib/DropdownList';
import "react-widgets/dist/css/react-widgets.css";

import * as actions from '../../../../../store/actions';
import IndicatorSelectFormCategoryGroup from './IndicatorSelectFormCategoryGroup/IndicatorSelectFormCategoryGroup';

class IndicatorSelectForm extends Component {
  componentDidMount() {
    this.props.listIndicators();
  }

  render() {
    const appliedTo = this.props.surveyCrud.createSurveyObj.appliedTo;
    if (appliedTo === "adult") {
      this.props.surveyCrud.professions.forEach((profession, i) => {
        this.props.surveyCrud.professions[i]['professionLabel'] = getContent(profession.professionId) || '';
      });
    }
    return (
      <>
        <div className='button-container-indicator-select'>
          <Button
            onClick={() => this.props.setAppliedTo('uppergrades')}
            className={classnames('small-button-toggle', { 'active': appliedTo === 'uppergrades' })}
          >
            {getContent('admin_label-upper-grades')}
          </Button>
          <Button
            onClick={() => this.props.setAppliedTo('elementary')}
            className={classnames('small-button-toggle', { 'active': appliedTo === 'elementary' })}
          >
            {getContent('admin_label-elementary')}
          </Button>
          <Button
            onClick={() => this.props.setAppliedTo('adult')}
            className={classnames('small-button-toggle', { 'active': appliedTo === 'adult' })}
          >
            {getContent('admin_label-adult')}
          </Button>
        </div>
        <div className='content-indicator-select-form'>
          {Object.keys(this.props.surveyCrud.indicatorsByCategoryAndType).map((categoryName) => {
            return (
              <div key={categoryName}>
                {
                  this.props.surveyCrud.isLoading === false && this.props.surveyCrud.indicatorsByCategoryAndType ?
                    <IndicatorSelectFormCategoryGroup
                      key={categoryName}
                      chosenIndicators={this.props.surveyCrud.createSurveyObj.chosenIndicators}
                      categoryName={categoryName}
                      indicatorsByCategoryAndType={this.props.surveyCrud.indicatorsByCategoryAndType}
                      indicatorIdsByCategory={this.props.surveyCrud.indicatorIdsByCategory}
                      toggleCheckedIndicatorInForm={this.props.toggleCheckedIndicatorInForm}
                      chosenCategories={this.props.surveyCrud.createSurveyObj.chosenCategories}
                      toggleCategoryIndicators={this.props.toggleCategoryIndicators}
                    /> : null
                }
              </div>
            );
          })}
        </div>
        {appliedTo === 'adult' ? (
          <div className="row mt-5">
            <div className="col-10 offset-1 content-profession-select-form">
              <div className="dropdown-container">
                <h1 className="indicator-headers">Select profession</h1>
                <DropdownList
                  className="tm-dropdown mr-0"
                  data={this.props.surveyCrud.professions}
                  textField='professionLabel'
                  valueField='professionId'
                  onChange={this.props.setProfession}
                  value={this.props.surveyCrud.createSurveyObj.profession}
                  placeholder='Please select...'
                />
              </div>
            </div>
          </div>
        ) : null }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    surveyCrud: state.surveyCrud,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleCheckedIndicatorInForm: (indicatorId) => dispatch(actions.toggleCheckedIndicatorInForm(indicatorId)),
    listIndicators: () => dispatch(actions.listIndicators()),
    setAppliedTo: (value) => dispatch(actions.setAppliedTo(value)),
    toggleCategoryIndicators: (category) => dispatch(actions.toggleCategoryIndicators(category)),
    setProfession: (profession) => dispatch(actions.setProfession(profession)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorSelectForm);
