import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { getContent } from '../../../utilities/getContent/getContent';
import ButtonArrow from '../../../components/Button/ButtonArrow';
// import RenderDropdown from '../../../components/RenderDropdown/RenderDropdown';
// import { required } from '../../../utilities/validation';
import MaskedInput from 'react-text-mask';
// import { months, days, years } from '../../../utilities/dates';

const v1AccessCodeMask = false;
const v2AccessCodeMask = [
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  '-',
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  '-',
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  '-',
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
  /[a-z]/i,
];

const renderKeyCode = ({ input, data, className, accessCodeVersion }) => (
  <MaskedInput
    mask={accessCodeVersion === 2 ? v2AccessCodeMask : v1AccessCodeMask}
    className={`${className}${accessCodeVersion === 2 ? ' v2' : ''}`}
    placeholder={accessCodeVersion === 2 ? 'XXXX-XXXX-XXXX-XXXX' : getContent('wellnesscenter_label-key-code')}
    data={data}
    {...input}
  />
);

class WellnessCenterAccessForm extends Component {
  render() {
    return (
      <form className="access-form form-group" onSubmit={this.props.handleSubmit}>
        <div>
          <div>
            <label className="label">{getContent('wellnesscenter_label-key-code')}</label>
            <Field name="inviteCode" type="text" component={renderKeyCode} className="form-control input" accessCodeVersion={this.props.accessCodeVersion} />
            <button className="btn btn-link" onClick={this.props.toggleAccessCodeVersion}>
              {getContent('survey_button-my-key-code-looks-different')}
            </button>
          </div>
          <div>
            <label className="label">{getContent('wellnesscenter_label-first-name')}</label>
            <Field name="firstName" type="text" component="input" className="form-control input" />
          </div>
          <div>
            <label className="label">{getContent('wellnesscenter_label-last-name')}</label>
            <Field name="lastName" type="text" component="input" className="form-control input" />
          </div>
          {/* <div>
            <label className="label">{getContent('wellnesscenter_label-birthdate')}</label>
            <div className={`row birthday-fields ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order' : ''}`}>
              <div className={`col-md-4 ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order-1' : ''}`}>
                <Field name="month" type="text" placeholder="Month" className="tm-dropdown short" data={months} textField="label" valueField="value" component={RenderDropdown} validate={[required]} />
              </div>
              <div className={`col-md-4 ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order-0' : ''}`}>
                <Field name="day" type="text" placeholder="Day" className="tm-dropdown short" data={days} component={RenderDropdown} validate={[required]} />
              </div>
              <div className={`col-md-4 ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order-2' : ''}`}>
                <Field name="year" type="text" placeholder="Year" className="tm-dropdown short" data={years} component={RenderDropdown} validate={[required]} />
              </div>
            </div>
          </div> */}
          <div className="flex-center">
            <ButtonArrow className="button green" type="submit" text={getContent('wellnesscenter_button-continue')} />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.meta.lang,
});

WellnessCenterAccessForm = connect(mapStateToProps)(WellnessCenterAccessForm);

export default reduxForm({
  form: 'wellness-center-access',
})(WellnessCenterAccessForm);
