import React from 'react';
import { Field } from 'redux-form';
import RenderDropdown from '../../../../../components/RenderDropdown/RenderDropdown';
import { required, isValidEmail } from '../../../../../utilities/validation';
import { getContent } from '../../../../../utilities/getContent/getContent';
import RenderPhoneField from '../../../../../components/RenderPhoneField/RenderPhoneField';

import { months, days, years } from '../../../../../utilities/dates';

const gradeData = process.env.REACT_APP_STAGE === 'uk' ? [...Array(14).keys()].slice(4) : [...Array(13).keys()].slice(3);

const ParticipantEntryFields = props => {
  return (
    <div>
      <div className="participant-row mb-4">
        <div className="label-field-container">
          <label className="label">Participant Name</label>
          <Field name={`firstName-${props.index}`} placeholder="First name" type="text" component="input" className="participant-entry-field" validate={[required]} />
        </div>
        <div className="label-field-container">
          <Field name={`middleName-${props.index}`} placeholder="Middle name" type="text" component="input" className="participant-entry-field" />
        </div>
        <div className="label-field-container">
          <Field name={`lastName-${props.index}`} placeholder="Last name" type="text" component="input" className="participant-entry-field" validate={[required]} />
        </div>
        <div className="label-field-container">
          <label className="label">{getContent('admin_participant-entry-grade')}</label>
          <Field name={`grade-${props.index}`} type="text" placeholder="-Select-" className="tm-dropdown short" data={gradeData} component={RenderDropdown} />
        </div>
      </div>
      <div className="participant-row mb-4">
        <div className="label-field-container">
          <label className="label">Student Id</label>
          <Field name={`studentId-${props.index}`} placeholder="ID (if available)" type="text" component="input" className="participant-entry-field" />
        </div>
        <div className={`label-field-container ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order' : ''}`}>
          <label className="label">Date of Birth</label>
          <div className={`row birthday-fields`}>
            <div className={`col-4 ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order-1' : ''}`}>
              <Field
                name={`month-${props.index}`}
                type="text"
                placeholder="Month"
                className="tm-dropdown short"
                data={months}
                textField="label"
                valueField="value"
                component={RenderDropdown}
                validate={[required]}
              />
            </div>
            <div className={`col-4 ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order-0' : ''}`}>
              <Field name={`day-${props.index}`} type="text" placeholder="Day" className="tm-dropdown short" data={days} component={RenderDropdown} validate={[required]} />
            </div>
            <div className={`col-4 ${process.env.REACT_APP_STAGE === 'uk' ? 'uk-date-order-2' : ''}`}>
              <Field name={`year-${props.index}`} type="text" placeholder="Year" className="tm-dropdown short" data={years} component={RenderDropdown} validate={[required]} />
            </div>
          </div>
        </div>
      </div>
      <div className="participant-row mb-5">
        <div className="label-field-container">
          <label className="label">Notification Email (optional)</label>
          <Field name={`parentEmail-${props.index}`} placeholder="Notification Email" type="text" component="input" className="participant-entry-field" validate={[isValidEmail]} />
        </div>
        <div className="label-field-container">
          <label className="label">Notification Mobile (optional)</label>
          <Field name={`parentMobile-${props.index}`} type="tel" component={RenderPhoneField} className="participant-entry-field" />
        </div>
        <div className="label-field-container">
          <label className="label">Group Name</label>
          <Field name={`group-name-${props.index}`} type="text" placeholder="Group name" className="participant-entry-field" component="input" />
        </div>
      </div>
    </div>
  );
};

export default ParticipantEntryFields;
