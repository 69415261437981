import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import BootStrapButton from 'react-bootstrap/Button';
import RenderField from '../../../../components/RenderField/RenderField';
import RenderPhoneField from '../../../../components/RenderPhoneField/RenderPhoneField';
import CheckedImage from '../../../../assets/imgs/checked.png'
import UncheckedImage from '../../../../assets/imgs/unchecked.png'

import { confirmPasswordsMatch, required } from '../../../../utilities/validation';
import PasswordConfirmation from './PasswordConfirmation';

class AddUserForm extends Component {
  state = {
    isDesignatedChecked: this.props.initialValues.isDesignated,
  };

  toggleDesignated = () => {
    this.setState({ isDesignatedChecked: this.state.isDesignatedChecked === true ? false : true });
  };

  render() {
    const { invalid, pristine, submitting } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div>
          <label className="label" htmlFor="firstName">{this.props.labelText} First Name</label>
          <Field
            name="firstName"
            placeholder="First Name"
            type="text"
            component={RenderField}
            className="modalInput"
            validate={[required]}
          />
        </div>
        <div>
          <label className="label" htmlFor="lastName">{this.props.labelText} Last Name</label>
          <Field
            name="lastName"
            placeholder="Last Name"
            type="text"
            component={RenderField}
            className="modalInput"
            validate={[required]}
          />
        </div>
        <div>
          <label className="label" htmlFor="email">{this.props.labelText} Email</label>
          <Field
            name="email"
            placeholder="Email Address"
            type="text"
            component={RenderField}
            className="modalInput"
            validate={[required]}
          />
        </div>
        <div>
          <label className="label" htmlFor="phoneNumber">{this.props.labelText} Phone</label>
          <div className="modalInput p-0">
            <Field
              name="phoneNumber"
              type="tel"
              component={RenderPhoneField}
              validate={[required]}
            />
          </div>
        </div>
        {this.props.showDesignatedToggle ?
          <div>
            <label>
              <img className='checkbox' alt='Checkbox' src={this.state.isDesignatedChecked ? CheckedImage : UncheckedImage} />
              <Field
                name="isDesignated"
                type='checkbox'
                component="input"
                onChange={this.toggleDesignated}
              />
              Designated District Champion
            </label>
          </div>
        : null}
        {this.props.isUserProfile ?
          <PasswordConfirmation />
        : null}
        <div className='btnContainer mt-5'>
          <BootStrapButton
            type="submit"
            className='small-button green'
            size="lg"
            variant="success"
            disabled={ invalid || pristine || submitting }
          >
            {this.props.saveButtonText}
          </BootStrapButton>
          <BootStrapButton
            onClick={(e) => this.props.closeModal(e)}
            className='small-blue-button-outlined'
            size="lg"
            variant="outline-primary"
          >
            Cancel
          </BootStrapButton>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'add-user-form',
  validate: confirmPasswordsMatch,
})(AddUserForm);
