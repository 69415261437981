import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
const wellnesscenterAuthLayout = (WrappedComponent, { ...props }) => {
  class WellnessCenterLayout extends Component {
    render() {
      return (
        <>
        {this.props.loggedIn ?
          <WrappedComponent {...this.props} />
        :
          <Redirect to="/wellness-center"/>
        }
        </>
      );
    }
  }
  const mapStateToProps = state => {
    return {
      lang: state.meta.lang,
      loggedIn: state.wellnessCenterAuth.loggedIn,
    };
  };
  return connect(mapStateToProps)(WellnessCenterLayout);
};
export default wellnesscenterAuthLayout;
