import React from 'react';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import IconEmail from '../../../../../assets/imgs/icon-email.svg';
import Spinner from '../../../../../components/Spinner/Spinner';
import Backdrop from '../../../../../components/Backdrop/Backdrop';
import { json2csv } from 'json-2-csv';
import TableGenerator from '../../../../../components/TableGenerator/TableGenerator';

const ListDistricts = props => {
	async function exportCSVFile( items, fileTitle) {
		await json2csv(
			items,
			(err, csv) => {
				let exportedFilename = fileTitle + '.csv' || 'export.csv';
				var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
				if (navigator.msSaveBlob) {
					// IE 10+
					navigator.msSaveBlob(blob, exportedFilename);
				} else {
					var link = document.createElement('a');
					if (link.download !== undefined) {
						// feature detection
						// Browsers that support HTML5 download attribute
						var url = URL.createObjectURL(blob);
						link.setAttribute('href', url);
						link.setAttribute('download', exportedFilename);
						link.style.visibility = 'hidden';
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}
				}
			},
			{ emptyFieldValue: '' }
		);
	}
	const generateDistrictTableData = () => {
		// Waits for districts
		if (props.organizations && props.organizations.length > 0) {
			const result = props.organizations.map(item => {
				return {
					country: item.country,
					state: item.state,
					districtName:
						props.realRole === 'tm_admin' || props.role === 'tm_admin' ? (
							<button className="button-text" onClick={() => props.changeRoleAndOrg(item, true)}>
								{item.name}
							</button>
						) : (
							item.name
						),
					championName: `${item.firstName || ''} ${item.lastName || ''}`,
					email: (
						<a href={`mailto:${item.email}`}>
							<img src={IconEmail} alt="envelope icon" />
						</a>
					),
					report:
						item.report.indicators.length > 0 ? (
							<button
								className="button-text"
								onClick={() => {
                  exportCSVFile(item.report.indicators, `${item.name} - District Indicator Report`);
                  const { satisfactory, optimal, at_risk, moderate, no_tfs, totalStudents } = item.report;
                  exportCSVFile({ satisfactory, optimal, at_risk, moderate, no_tfs, totalStudents },`${item.name} - District Overall Report`);
                  exportCSVFile(item.report.surveyIndicatorResults, `${item.name} - Survey Indicator Results`);
                  exportCSVFile(item.report.surveyOverallResults, `${item.name} - Survey Overall Results`);
								}}
							>
								Data
							</button>
						) : null,
					phone: item.phoneNumber,
					icon: (
						<>
							<Edit
								className="blue-fill-icon"
								onClick={() => props.openCreateOrgModal(item, 'CREATE_DISTRICT_MODAL')}
							/>
							<Delete
								className="blue-fill-icon"
								onClick={() => props.openDeleteOrgConfirmationModal(item)}
							/>
						</>
					)
				};
			});

			return result;
		}

		return [];
	};

	const getDistrictTableConfig = () => {
		const config = {
			headers: ['Country', 'State', 'District Name', 'Champion Name', 'Email', 'Download', 'Phone', ''],
			contentRows: generateDistrictTableData()
		};
		return config;
	};
	return (
		<>
			{props.isLoadingOrganizations ? (
				<Backdrop>
					<Spinner />
				</Backdrop>
			) : props.organizations && props.organizations.length === 0 && !props.hasSearched ? (
				<>
					<div className="text-center py-5">
						<h1>The School District List is currently empty.</h1>
						<p>
							It looks like no school districts have been created. Click the button to create a new school
							district.
						</p>
						<button
							onClick={() => props.openCreateOrgModal(null, 'CREATE_DISTRICT_MODAL')}
							className="button green mt-0"
						>
							Create District
						</button>
					</div>
				</>
			) : (
				<>
					<p>{`${props.organizations ? props.organizations.length : '0'} District Results`}</p>
					{props.organizations && props.organizations.length > 0 ? (
						<TableGenerator config={getDistrictTableConfig()} />
					) : null}
				</>
			)}
		</>
	);
};

export default ListDistricts;
