import React, { Component } from 'react';
import GroupHeader from './Header';
import { getContent } from '../../../../utilities/getContent/getContent';

// import MOCK_SUMMARY from '../mock-resilience-categories';
// import parse from 'html-react-parser';

class DistressScores extends Component {
  render() {
    const payload = this.props.payload;
    const isAdult = payload.surveyName && payload.surveyName.includes('Adult');
    const urlPath = isAdult ? "adults" : "educators";
    const urlPassword = isAdult ? "TMadults" : "TMeducators";
    const { tmEducatorsLink } = this.props.links;
    const educatorLinkStatement = () => {
      return (
        <span>
          <strong><a href={tmEducatorsLink}>terracemetrics.org/{urlPath}</a></strong>, using the password <strong>"{urlPassword}"</strong>
        </span>
      )
    }

    return (
      <div className="page group-report" style={{marginTop:'100px'}}>
        <GroupHeader payload={payload} />
        <div className="container">
          <main className="px-5 pt-3">
            <h4 className="mb-3 text-uppercase">Mean Distress Scores by Indicator</h4>
            <table className="table table-sm table-tm-dark-striped">
              <thead>
                <tr>
                  <th>Indicator</th>
                  <th width="10%">TM Historical Average</th>
                  <th width="10%">
                    {payload.semester} {payload.year} Mean
                  </th>
                  {(payload.previousSurveyName) && <th width="10%">{payload.previousSemester} {payload.previousYear} Mean</th>}
                  <th width="10%">Range</th>
                  <th>Interpretation</th>
                </tr>
              </thead>
              <tbody>
                {payload.reports.distressScores.map((score, index) => {
                  return (
                    <tr key={index}>
                      <td className="font-weight-bold">{score.title}</td>
                      <td nowrap="true">{score.national}</td>
                      <td className="font-weight-bold">{score.survey}</td>
                      {(payload.previousSurveyName) && <td>{score.previous}</td>}
                      <td>{score.range}</td>
                      <td>{score.interpretation}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="pt-2">
              <div className="box-comment">
                <h3>What's this table telling me?</h3>
                <p>
                  {isAdult ? getContent("report_overall_page06_table_1_adult") : getContent("report_overall_page06_table_1_student")}
                </p>
                <h3>{isAdult ? getContent("report_overall_page03_risk-heading_adult") : getContent("report_overall_page03_risk-heading")}</h3>
                <p>
                  {isAdult ? getContent("report_overall_page06_table_2_adult") : getContent("report_overall_page06_table_2_student")}
                </p>
              </div>
            </div>

            <h4 className="mb-3 mt-5 text-uppercase">{payload.school} Participant Distribution on Risk Variables</h4>
            <div className="row">
              <div className="col-7">
                <table className="table table-sm table-tm-dark-striped">
                  <thead>
                    <tr>
                      <th>Indicator</th>
                      <th className="w-25 col-red">{payload.semester} {payload.year} Mean <br />At-risk</th>
                      {(payload.previousSurveyName) && <th className="w-25 col-red">{payload.previousSemester} {payload.previousYear} Mean <br />At-risk</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {payload.reports.riskVariables.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td className="font-weight-bold">{row.title}</td>
                          <td className="col-red score">
                            {row.value > 0 ?
                              isAdult ? <span>{Math.round(row.value)}%</span> : <a href={tmEducatorsLink}>{Math.round(row.value)}%</a>
                            : `${Math.round(row.value)}%`}
                          </td>
                          {(payload.previousSurveyName) && <td className="col-red">{Math.round(row.previous) || 0}%</td>}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="col-5">
                <div className="box-comment">
                  <h3>What's this table telling me?</h3>
                  <p>This table presents the percentage of participants who scored in the At-Risk range across selected risk indicators.</p>
                  <h3>{isAdult ? getContent("report_overall_page03_risk-heading_adult") : getContent("report_overall_page03_risk-heading")}</h3>
                  <p>
                    {isAdult ? getContent("report_overall_page06_table_3_adult") : getContent("report_overall_page06_table_3_student")}
                  {isAdult ? '' : educatorLinkStatement()}.
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default DistressScores;
