import React, { Component } from 'react'
import { connect } from 'react-redux';
import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs'

import CreateSurveyStepGroup from './CreateSurveyStepGroup/CreateSurveyStepGroup'

import Backdrop from '../../../../components/Backdrop/Backdrop';
import Spinner from '../../../../components/Spinner/Spinner';
import * as actions from '../../../../store/actions';

class CreateSurvey extends Component {
  componentDidMount() {
    this.props.clearCreateSurveyState();
  }

  getBreadcrumbs = () => {
    const breadcrumbs = [
      {
        key: 1,
        url: '/admin/',
        label: 'School Home'
      },
      {
        key: 2,
        url: '/admin/survey/list',
        label: 'Surveys',
      },
      {
        key: 3,
        url: '/admin/survey/create',
        label: 'Create Survey',
      }
    ];

    return breadcrumbs;
  }

  render() {
    return (
      <div className='create-survey-container'>
        <div className='sub-header'>
          <BreadCrumbs breadcrumbs={this.getBreadcrumbs()} />
        </div>
        <p>To create a survey, fill out each section below. Sections are broken into steps, and each step will unlock upon your completion of the previous step. You will not be able to save your survey until you have completed all the steps.</p>
        <CreateSurveyStepGroup createSurvey={this.props.createSurvey} manualRoster={this.props.manualRoster} />
        {
          this.props.isRosterUploading || this.props.isLoading ?
            (
              <>
                <Backdrop>
                  <Spinner />
                </Backdrop>
              </>
            ) : null
          }
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.surveyCrud.isLoading,
    isRosterUploading: state.surveyCrud.isRosterUploading,
    manualRoster: state.surveyCrud.manualRoster,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkRosterUploadStatus: () => dispatch(actions.checkRosterUploadStatus()),
    createSurvey: () => dispatch(actions.createSurvey()),
    clearCreateSurveyState: () => dispatch(actions.clearCreateSurveyState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurvey);
