import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import BootStrapButton from 'react-bootstrap/Button';
import RenderPhoneField from '../../../../../components/RenderPhoneField/RenderPhoneField';
import RenderField from '../../../../../components/RenderField/RenderField';

import Dropdown from '../../../../../components/Dropdown/Dropdown';
const { country, stateData } = require('../../../../../utilities/locale/').getLocale();

class CreateDistrictForm extends Component {

  renderDropdown = ({ input, data, valueField, textField, className }) => {
    return <Dropdown {...input} data={data} valueField={valueField} textField={textField} onChange={input.onChange} className={className} />;
  };

  render() {
    const { invalid, pristine, submitting } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="d-flex align-items-end mb-2">
          <div className="label-field-container">
            <label
              className="label"
              htmlFor="country"
            >
              Country/Region
            </label>
            <Field
              name="country"
              component={this.renderDropdown}
              data={[country]}
              className="short"
            />
          </div>
          <div className="label-field-container">
            <label
              className="label"
              htmlFor="state"
            >
              State/Province
            </label>
            <Field
              name="state"
              component={this.renderDropdown}
              data={stateData}
              textField="abbreviation"
              valueField="abbreviation"
              className="short"
            />
          </div>
          <div className="label-field-container flex-grow-1">
            <label className="label" htmlFor="name">
              District Name
            </label>
            <Field
              name="name"
              placeholder="Full District Name"
              type="text"
              component="input"
              className="participant-entry-field"
            />
          </div>
        </div>
        {!this.props.item ? (
          <>
            <div className="d-flex align-items-end mb-2">
              <div className="label-field-container">
                <label
                  className="label"
                  htmlFor="firstName"
                >
                  Champion Name
              </label>
                <Field
                  name="firstName"
                  placeholder="First name"
                  type="text"
                  component="input"
                  className='participant-entry-field'
                />
              </div>
              <div className="label-field-container">
                <Field
                  name="lastName"
                  placeholder="Last name"
                  type="text"
                  component="input"
                  className='participant-entry-field'
                />
              </div>
              <div className="label-field-container">
                <label
                  className="label"
                  htmlFor="email"
                >
                  Champion Email
              </label>
                <Field
                  name="email"
                  placeholder="Email Address"
                  type="text"
                  component="input"
                  className='participant-entry-field'
                />
              </div>
            </div>
            <div className="d-flex col-4 mb-4 p-0">
              <div className="label-field-container mr-2">
                <label
                  className="label"
                  htmlFor="phoneNumber"
                >
                  Champion Phone
                </label>
                <Field
                  name="phoneNumber"
                  type="tel"
                  component={ RenderPhoneField }
                  className='participant-entry-field'
                />
              </div>
            </div>
          </>
        ) : null}
        <div className="btnContainer justify-content-end">
          <BootStrapButton
            type="submit"
            className="small-button green"
            size="lg"
            variant="success"
            disabled={invalid || pristine || submitting}
          >
            {this.props.saveButtonText}
          </BootStrapButton>
          <BootStrapButton onClick={this.props.closeModal} className="small-blue-button-outlined" size="lg" variant="outline-primary">
            Cancel
          </BootStrapButton>
        </div>
        <div className="d-flex align-items-center">
          {typeof this.props.error === 'string' ? <span className="error">{this.props.error}</span> : null}
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'create-district-form',
})(CreateDistrictForm);
