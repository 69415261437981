import React, { Component } from 'react';
import parse from 'html-react-parser';
const { REACT_APP_ROSTER_CSV_URL } = process.env;
class CreateSurveyStep extends Component {
  render() {
    return (
      <div className='survey-step-container' data-active={this.props.active}>
        <div className='step-header'>
          <p className='step-number'>{this.props.number}</p>
          <div>
            <p>{this.props.header}</p>
            <p>{parse(this.props.subhead.replace('ROSTERNAME',REACT_APP_ROSTER_CSV_URL))}</p>
          </div>
        </div>
        {this.props.children}
      </div>
    )
  }
}

export default CreateSurveyStep
