import update from 'immutability-helper';

import * as actionTypes from '../actions/actionTypes';

const initialState = {
  surveySearchCountryRegion: '',
  surveySearchStateProvince: '',
  surveySearchDistrict: { name: '', orgId: '' },
  surveySearchSchool: { name: '', id: '' },
  surveySearchYear: '',
  surveySearchName: '',
  searchCountryRegionOptions: [],
  searchStateProvinceOptions: [],
  searchDistrictOptions: [],
  searchSchoolOptions: [],
  editParticipantStatus: '',
  editParticipantContacted: false,
  isSurveySearchVisible: false,
  indicatorFormOptions: [],
  createSurveyObj: {
    appliedTo: 'uppergrades', // ENUM: ['uppergrades', 'elementary']
    chosenIndicators: [],
    chosenCategories: [],
    profession: '',
    quarter: '',
    designatedRecipient:'',
    year: '',
    s3Tags: {
      fileKey: null,
    },
  },
  manualRoster: [],
  isRosterUploading: false,
  isRosterError: false,
  rosterUploadStatus: null,
  indicators: [],
  professions:[],
  indicatorsByCategoryAndType: [],
  surveys: [],
  surveyParticipants:null,
  surveyDetails: {
    pages: [],
    pageNumber: 0,
    isLastKey: false,
    currentExclusiveStartKey: {},
    lastEvaluatedKey: {}
  },
  bulkUpdate:{},
  isLoading: false,
  error: false,
  reportDetails: {},
};

const clearCreateSurveyState = (state, action) => {
  return update(state, {
    createSurveyObj: { $set: initialState.createSurveyObj },
    manualRoster: { $set: [] },
    isRosterUploading: { $set: false },
    isRosterError: { $set: false },
    rosterUploadStatus: { $set: null }
  });
};

const toggleCheckedIndicatorInForm = (state, action) => {
  const chosenIndicators = [...state.createSurveyObj.chosenIndicators];
  // First see if the item is in the array
  // eslint-disable-next-line array-callback-return
  const indicatorIndex = chosenIndicators.findIndex((indicator) => {
    if (indicator === action.indicatorId) {
      return indicator;
    }
  });

  // if it is remove it from the array
  if (indicatorIndex >= 0) {
    chosenIndicators.splice(indicatorIndex, 1);
  } else {
    // else push it to the array
    chosenIndicators.push(action.indicatorId);
  }

  return update(state, {
    createSurveyObj: {
      chosenIndicators: { $set: chosenIndicators },
    }
  });
};

const setChosenIndicators = (state, action) => {
  return update(state, {
    createSurveyObj: {
      chosenIndicators: { $set: action.chosenIndicators },
      //chosenCategories: { $set: action.chosenCategories },
    }
  });
};

const setProfession = (state, action) => {
  return update(state, {
    createSurveyObj: {
      profession: { $set: action.profession },
    }
  });
};

const setAppliedTo = (state, action) => {
  return update(state, {
    createSurveyObj: {
      appliedTo: { $set: action.appliedTo }
    }
  });
};

const setSurveyQuarter = (state, action) => {
  return update(state, {
    createSurveyObj: {
      quarter: { $set: action.quarter },
    }
  });
};

const setDesignatedEmailRecipient = (state, action) => {
  console.log(action.designatedRecipient);
  return update(state, {
    createSurveyObj: {
     designatedRecipient: { $set: action.designatedRecipient },
    }
  });
};

const setSurveyYear = (state, action) => {
  return update(state, {
    createSurveyObj: {
      year: { $set: action.year },
    }
  });
};

const addToManualRosterLength = (state, action) => {
  const newParticipant = {};
  return update(state, {
    manualRoster: { $push: [newParticipant] }
  });
};

const uploadRosterStart = (state, action) => {
  return update(state, {
    isRosterUploading: { $set: true },
    isRosterError: { $set: false },
  });
};

const uploadRosterSuccess = (state, action) => {
  return update(state, {
    createSurveyObj: {
      s3Tags: {
        fileKey: { $set: action.fileKey },
        ETag: { $set: action.ETag }
      }
    }
  });
};

const uploadRosterFail = (state, action) => {
  return update(state, {
    isRosterUploading: { $set: false },
    isRosterError: { $set: action.err }
  });
};

const getSignedUrlStart = (state, action) => {
  return update(state, {
    isRosterUploading: { $set: true },
    isRosterError: { $set: false },
  });
};

const getSignedUrlSuccess = (state, action) => {
  return update(state, {
    createSurveyObj: {
      s3Tags: {
        fileKey: { $set: action.fileKey },
        ETag: { $set: action.ETag }
      }
    }
  });
};

const getSignedUrlFail = (state, action) => {
  return update(state, {
    isRosterUploading: { $set: false },
    isRosterError: { $set: action.err }
  });
};

const listIndicatorsStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};

const listIndicatorsSuccess = (state, action) => {
  return update(state, {
    indicators: { $set: action.indicators },
    isLoading: { $set: false },
    error: { $set: null }
  });
};

const listProfessionsSuccess = (state, action) => {
  return update(state, {
    professions: {$set: action.professions},
  })
}

const setIndicatorsByCategoryAndType = (state, action) => {
  return update(state, {
    indicatorsByCategoryAndType: { $set: action.indicatorsByCategoryAndType },
    indicatorIdsByCategory: { $set: action.indicatorIdsByCategory }
  });
};

const checkRosterUploadStatusSuccess = (state, action) => {
  return update(state, {
    isRosterUploading: { $set: false },
    rosterUploadStatus: { $set: 'success' }
  });
};

const checkRosterUploadStatusFail = (state, action) => {
  return update(state, {
    isRosterUploading: { $set: false },
    rosterUploadStatus: { $set: 'failed' }
  });
};

const listIndicatorsFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.errror },
  });
};

const hideSurveySearch = (state, action) => {
  return update(state, {
    isSurveySearchVisible: { $set: false },
    surveySearchCountryRegion: { $set: '' },
    surveySearchStateProvince: { $set: '' },
    surveySearchDistrict: { $set: { name: '', orgId: '' } },
    surveySearchSchool: { $set: { name: '', id: '' } },
    surveySearchYear: { $set: '' },
    surveySearchName: { $set: '' },
  });
};
const showSurveySearch = (state, action) => {
  return update(state, {
    isSurveySearchVisible: { $set: true },
  });
};
const setSearchValuesStart = (state, action) => {
  return update(state, {
    error: { $set: null }
  });
}

const setSearchValuesFail = (state, action) => {
  return update(state, {
    error: { $set: action.err },
    isLoading: { $set: false }
  })
}

const setSearchValues = (state, action) => {
  let newState = {};

  if (action.values.districts) {
    const districtsMap = action.values.districts.map(district => {
      return district.name
    });
    newState = {
      ...newState,
      searchDistrictOptions: { $set: districtsMap },
      rawDistrictsData: { $set: action.values.districts },
    };
  }

  if (action.values.schools) {
    const schoolsMap = action.values.schools.map(school => {
      return school.name
    });
    newState = {
      ...newState,
      searchSchoolOptions: { $set: schoolsMap },
      rawSchoolsData: { $set: action.values.schools },
    };
  }

  if (action.values.countries) {
    newState = {
      ...newState,
      searchCountryRegionOptions: { $set: action.values.countries },
    };
  }

  if (action.values.states) {
    newState = {
      ...newState,
      searchStateProvinceOptions: { $set: action.values.states },
    }
  }

  return update(state, newState);
}

const updateSearchSurveyObj = (state, action) => {
  var valueToBeUpdated = action.payload.value

  if (action.payload.name === 'surveySearchDistrict' && state.rawDistrictsData) {
    valueToBeUpdated = state.rawDistrictsData.find(district => {
      return action.payload.value === district.name;
    })
  }

  if (action.payload.name === 'surveySearchSchool') {
    valueToBeUpdated = state.rawSchoolsData.find(school => {
      return action.payload.value === school.name;
    })
  }

  return update(state, {
    [action.payload.name]: { $set: valueToBeUpdated }
  })
}

const clearStateOptions = (state, action) => {
  return update(state, {
    searchStateProvinceOptions: { $set: [] },
    surveySearchCountryRegion: { $set: null },
  });
}

const clearDistrictOptions = (state, action) => {
  return update(state, {
    searchDistrictOptions: { $set: [] },
    surveySearchDistrict: { $set: { name: '', orgId: '' } },
  })
}

const clearSchoolOptions = (state, action) => {
  return update(state, {
    surveySearchSchool: { $set: { name: '', id: '' } },
    searchSchoolOptions: { $set: [] },
  });
}

const submitSurveySearchStart = (state, action) => {
  return update(state, {

  })
}

const submitSurveySearchSuccess = (state, action) => {
  return update(state, {

  })
}

const submitSurveySearchFail = (state, action) => {
  return update(state, {

  })
}

const listSurveyStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};

const listSurveySuccess = (state, action) => {
  return update(state, {
    surveys: { $set: action.surveys },
    isLoading: { $set: false },
    error: { $set: null }
  });
};

const listSurveyFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.errror },
  });
};

const createSurveyStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};

const createSurveySuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: null }
  });
};

const createSurveyFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
};
const getAllParticipantDataStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};
const getAllParticipantDataSuccess = (state, action) => {
  console.log('reducer action',action);
  return update(state, {
    isLoading: { $set: false },
    error: {$set: null},
    surveyParticipants: {$set: action.surveyParticipants}
  })
};

const getAllParticipantDataFail = (state, action) => {
 return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
}
const getAllNotificationDataStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};
const getAllNotificationDataSuccess = (state, action) => {
  console.log('reducer action',action);
  return update(state, {
    isLoading: { $set: false },
    error: {$set: null},
    surveyNotificationData: {$set: action.surveyNotificationData}
  })
};

const getAllNotificationDataFail = (state, action) => {
 return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
}

const getAllNotificationResponsesStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};
const getAllNotificationResponsesSuccess = (state, action) => {
  console.log('reducer action',action);
  return update(state, {
    isLoading: { $set: false },
    error: {$set: null},
    surveyNotificationResponses: {$set: action.surveyNotificationResponses}
  })
};

const getAllNotificationResponsesFail = (state, action) => {
 return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
}

const getSurveyStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};


const getSurveySuccess = (state, action) => {
  const pages = [...state.surveyDetails.pages];
  console.log('PAGES: ', pages, 'action: ', action);
  if (!pages[action.surveyDetails.pageNumber] && !action.surveyDetails.isLastKey && (action.surveyDetails.lastEvaluatedKey || action.surveyDetails.currentExclusiveStartKey)) {
    pages.push({ lastEvaluatedKey: action.surveyDetails.lastEvaluatedKey, currentExclusiveStartKey: action.surveyDetails.currentExclusiveStartKey });
  }
  action.surveyDetails.pageNumber = action.surveyDetails.pageNumber || 0;
  action.surveyDetails.pages = pages;
  return update(state, {
    isLoading: { $set: false },
    error: { $set: null },
    surveyDetails: { $set: action.surveyDetails },
  });
};

const getSurveyFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
};

const getAggregatedOverallReportStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};


const getAggregatedOverallReportSuccess = (state, action) => {
  
  return update(state, {
    isLoading: { $set: false },
    error: { $set: null },
    reportDetails: { $set:  action.reportDetails},
  });
};

const getAggregatedOverallReportFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
};


const deleteSurveyStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};

const deleteSurveySuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: null },
  });
};

const deleteSurveyFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
};

const clearSurveyDetails = (state, action) => {
  return update(state, {
    surveyDetails: {
      currentExclusiveStartKey: { $set: null },
      lastEvaluatedKey: { $set: null },
      isLastKey: { $set: false },
      pages: { $set: [] },
      pageNumber: { $set: 0 },
    },
  });
};

const setParticipantValues = (state, action) => {
  return update(state, {
    // editParticipantContacted: { $set: action.participantData.participantContacted},
    editParticipantStatus: { $set: action.participantData.participantStatus }
  })
}

const updateParticipantStarted = (state, action) => {
  return update(state, {
    editParticipantStatus: { $set: action.value }
  })
}

const bulkUpdateParticipantStarted = (state, action) => {
  return update(state, {
    bulkUpdate: { $set: action.value }
  })
}

const toggleParticipantContacted = (state, action) => {
  return update(state, {
    editParticipantContacted: { $set: action.value }
  })
}

const bulkUpdateParticipantStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};

const bulkUpdateParticipantSuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: null },
  });
};

const bulkUpdateParticipantFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
};

const bulkDeleteParticipantStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};

const bulkDeleteParticipantSuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: null },
  });
};

const bulkDeleteParticipantFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
};

const updateParticipantStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};

const updateParticipantSuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: null },
  });
};

const updateParticipantFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
};

const addParticipantToSurveyStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};

const addParticipantToSurveySuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: null },
  });
};

const addParticipantToSurveyFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
};

const deleteParticipantFromSurveyStart = (state, action) => {
  return update(state, {
    error: { $set: null },
    isLoading: { $set: true },
  });
};

const deleteParticipantFromSurveySuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: null },
  });
};

const deleteParticipantFromSurveyFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
};

const getNotificationLogStart = (state, action) => {
  return update(state, {
    isLoading: { $set: true },
    error: { $set: null },
  })
}

const getNotificationLogSuccess = (state, action) => {
  console.log('State:  ', state);
  console.log('Action: ', action);
  return update(state, {
    isLoading: { $set: false },
    notificationAttempts: { $set: action.notificationAttempts}
  })
}

const getNotificationLogFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_CREATE_SURVEY_STATE:
      return clearCreateSurveyState(state, action);
    case actionTypes.TOGGLE_CHECKED_INDICATOR_IN_FORM:
      return toggleCheckedIndicatorInForm(state, action);
    case actionTypes.SET_CHOSEN_INDICATORS:
      return setChosenIndicators(state, action);
    case actionTypes.SET_PROFESSION:
      return setProfession(state, action);
    case actionTypes.SET_APPLIED_TO:
      return setAppliedTo(state, action);
    case actionTypes.SET_SURVEY_QUARTER:
      return setSurveyQuarter(state, action);
    case actionTypes.SET_SURVEY_YEAR:
      return setSurveyYear(state, action);
    case actionTypes.SET_INDICATORS_BY_CATEGORY_AND_TYPE:
      return setIndicatorsByCategoryAndType(state, action);
    case actionTypes.ADD_TO_MANUAL_ROSTER_LENGTH:
      return addToManualRosterLength(state, action);
    case actionTypes.UPLOAD_ROSTER_START:
      return uploadRosterStart(state, action);
    case actionTypes.UPLOAD_ROSTER_SUCCESS:
      return uploadRosterSuccess(state, action);
    case actionTypes.UPLOAD_ROSTER_FAIL:
      return uploadRosterFail(state, action);
    case actionTypes.CHECK_ROSTER_UPLOAD_STATUS_SUCCESS:
      return checkRosterUploadStatusSuccess(state, action);
    case actionTypes.CHECK_ROSTER_UPLOAD_STATUS_FAIL:
      return checkRosterUploadStatusFail(state, action);
    case actionTypes.LIST_INDICATORS_START:
      return listIndicatorsStart(state, action);
    case actionTypes.LIST_INDICATORS_SUCCESS:
      return listIndicatorsSuccess(state, action);
    case actionTypes.LIST_PROFESSIONS_SUCCESS:
      return listProfessionsSuccess(state, action);
    case actionTypes.LIST_INDICATORS_FAIL:
      return listIndicatorsFail(state, action);
    case actionTypes.SHOW_SURVEY_SEARCH:
      return showSurveySearch(state, action);
    case actionTypes.HIDE_SURVEY_SEARCH:
      return hideSurveySearch(state, action);
    case actionTypes.SET_SEARCH_VALUES:
      return setSearchValues(state, action);
    case actionTypes.SET_SEARCH_VALUES_START:
      return setSearchValuesStart(state, action);
    case actionTypes.SET_SEARCH_VALUES_FAIL:
      return setSearchValuesFail(state, action);
    case actionTypes.CLEAR_STATE_OPTION:
      return clearStateOptions(state, action);
    case actionTypes.CLEAR_DISTRICT_OPTION:
      return clearDistrictOptions(state, action);
    case actionTypes.CLEAR_SCHOOL_OPTION:
      return clearSchoolOptions(state, action);
    case actionTypes.UPDATE_SEARCH_SURVEY:
      return updateSearchSurveyObj(state, action);
    case actionTypes.SUBMIT_SURVEY_SEARCH_START:
      return submitSurveySearchStart(state, action);
    case actionTypes.SUBMIT_SURVEY_SEARCH_SUCCESS:
      return submitSurveySearchSuccess(state, action);
    case actionTypes.SUBMIT_SURVEY_SEARCH_FAIL:
      return submitSurveySearchFail(state, action);
    case actionTypes.LIST_SURVEY_START:
      return listSurveyStart(state, action);
    case actionTypes.LIST_SURVEY_SUCCESS:
      return listSurveySuccess(state, action);
    case actionTypes.LIST_SURVEY_FAIL:
      return listSurveyFail(state, action);
    case actionTypes.CREATE_SURVEY_START:
      return createSurveyStart(state, action);
    case actionTypes.CREATE_SURVEY_SUCCESS:
      return createSurveySuccess(state, action);
    case actionTypes.CREATE_SURVEY_FAIL:
      return createSurveyFail(state, action);
    case actionTypes.GET_SURVEY_START:
      return getSurveyStart(state, action);
    case actionTypes.GET_SURVEY_SUCCESS:
      return getSurveySuccess(state, action);
    case actionTypes.GET_SURVEY_FAIL:
      return getSurveyFail(state, action);
    case actionTypes.GET_AGGREGATED_OVERALL_REPORT_START:
      return getAggregatedOverallReportStart(state, action);
    case actionTypes.GET_AGGREGATED_OVERALL_REPORT_SUCCESS:
      return getAggregatedOverallReportSuccess(state, action);
    case actionTypes.GET_AGGREGATED_OVERALL_REPORT_FAIL:
      return getAggregatedOverallReportFail(state, action);
    case actionTypes.GET_ALL_PARTICIPANT_DATA_START:
      return getAllParticipantDataStart(state, action);
    case actionTypes.GET_ALL_PARTICIPANT_DATA_SUCCESS:
      return getAllParticipantDataSuccess(state, action);
    case actionTypes.GET_ALL_PARTICIPANT_DATA_FAIL:
      return getAllParticipantDataFail(state, action);
    case actionTypes.GET_ALL_NOTIFICATION_DATA_START:
      return getAllNotificationDataStart(state, action);
    case actionTypes.GET_ALL_NOTIFICATION_DATA_SUCCESS:
      return getAllNotificationDataSuccess(state, action);
    case actionTypes.GET_ALL_NOTIFICATION_DATA_FAIL:
      return getAllNotificationDataFail(state, action);
    case actionTypes.GET_ALL_NOTIFICATION_RESPONSES_START:
      return getAllNotificationResponsesStart(state, action);
    case actionTypes.GET_ALL_NOTIFICATION_RESPONSES_SUCCESS:
      return getAllNotificationResponsesSuccess(state, action);
    case actionTypes.GET_ALL_NOTIFICATION_RESPONSES_FAIL:
      return getAllNotificationResponsesFail(state, action);
    case actionTypes.DELETE_SURVEY_START:
      return deleteSurveyStart(state, action);
    case actionTypes.DELETE_SURVEY_SUCCESS:
      return deleteSurveySuccess(state, action);
    case actionTypes.DELETE_SURVEY_FAIL:
      return deleteSurveyFail(state, action);
    case actionTypes.CLEAR_SURVEY_DETAILS:
      return clearSurveyDetails(state, action);
    case actionTypes.SET_PARTICIPANT_VALUES:
      return setParticipantValues(state, action);
    case actionTypes.UPDATE_PARTICIPANT_STARTED:
      return updateParticipantStarted(state, action)
    case actionTypes.BULK_UPDATE_PARTICIPANT_STARTED:
      return bulkUpdateParticipantStarted(state, action)
    case actionTypes.TOGGLE_PARTICIPANT_CONTACTED:
      return toggleParticipantContacted(state, action)
    case actionTypes.UPDATE_PARTICIPANT_START:
      return updateParticipantStart(state, action);
    case actionTypes.UPDATE_PARTICIPANT_SUCCESS:
      return updateParticipantSuccess(state, action);
    case actionTypes.UPDATE_PARTICIPANT_FAIL:
      return updateParticipantFail(state, action);
    case actionTypes.BULK_UPDATE_PARTICIPANT_START:
      return bulkUpdateParticipantStart(state, action);
    case actionTypes.BULK_UPDATE_PARTICIPANT_SUCCESS:
      return bulkUpdateParticipantSuccess(state, action);
    case actionTypes.BULK_UPDATE_PARTICIPANT_FAIL:
      return bulkUpdateParticipantFail(state, action);
    case actionTypes.BULK_DELETE_PARTICIPANT_START:
      return bulkDeleteParticipantStart(state, action);
    case actionTypes.BULK_DELETE_PARTICIPANT_SUCCESS:
      return bulkDeleteParticipantSuccess(state, action);
    case actionTypes.BULK_DELETE_PARTICIPANT_FAIL:
      return bulkDeleteParticipantFail(state, action);
    case actionTypes.ADD_PARTICIPANT_TO_SURVEY_START:
      return addParticipantToSurveyStart(state, action);
    case actionTypes.ADD_PARTICIPANT_TO_SURVEY_SUCCESS:
      return addParticipantToSurveySuccess(state, action);
    case actionTypes.ADD_PARTICIPANT_TO_SURVEY_FAIL:
      return addParticipantToSurveyFail(state, action);
    case actionTypes.DELETE_PARTICIPANT_FROM_SURVEY_START:
      return deleteParticipantFromSurveyStart(state, action);
    case actionTypes.DELETE_PARTICIPANT_FROM_SURVEY_SUCCESS:
      return deleteParticipantFromSurveySuccess(state, action);
    case actionTypes.DELETE_PARTICIPANT_FROM_SURVEY_FAIL:
      return deleteParticipantFromSurveyFail(state, action);
    case actionTypes.GET_SIGNED_URL_START:
      return getSignedUrlStart(state, action);
    case actionTypes.GET_SIGNED_URL_SUCCESS:
      return getSignedUrlSuccess(state, action);
    case actionTypes.GET_SIGNED_URL_FAIL:
      return getSignedUrlFail(state, action);
    case actionTypes.RESET_APP:
      return initialState;
    case actionTypes.GET_NOTIFICATION_LOG_SUCCESS:
      return getNotificationLogSuccess(state, action);
    case actionTypes.GET_NOTIFICATION_LOG_START:
      return getNotificationLogStart(state, action);
    case actionTypes.GET_NOTIFICATION_LOG_FAIL:
      return getNotificationLogFail(state, action);
    case actionTypes.SET_DESIGNATED_EMAIL_RECIPIENT:
      return setDesignatedEmailRecipient(state,action);
    default:
      return state;
  }
};

export default reducer;
