import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import MaskedInput from 'react-text-mask';
import * as actions from '../../../store/actions';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { getContent, getAudio } from '../../../utilities/getContent/getContent';

// import * as Button from '../../../components/Button/Button';
// import Card from '../../../components/Card/Card';

import TMButton from '../../../components/TMButton/TMButton';
import TMCard from '../../../components/TMCard/TMCard';
import LanguagePicker from '../../../components/LanguagePicker/LanguagePicker';
import AudioButton from '../../../components/Button/ButtonAudio';
import ArrowRight from '../../../assets/imgs/icon-arrow-right.svg';
import { getLogo } from './../../../assets/helper';

class SurveyAccess extends Component {
  state = {
    keyCodeValue: '',
    accessCodeVersion: 2, // v1 codes look like x_XxX0x || v2 codes look like XXXX-XXXX-XXXX-XXXX
  };

  v1AccessCodeMask = false;
  v2AccessCodeMask = [ /[a-z]/i, /[a-z]/i, /[a-z]/i, /[a-z]/i, '-', /[a-z]/i, /[a-z]/i, /[a-z]/i, /[a-z]/i, '-', /[a-z]/i, /[a-z]/i, /[a-z]/i, /[a-z]/i, '-', /[a-z]/i, /[a-z]/i, /[a-z]/i, /[a-z]/i ];

  toggleAccessCodeVersion = () => {
    this.setState({ accessCodeVersion: this.state.accessCodeVersion === 2 ? 1 : 2 });
  }

  logInStudent = () => {
    const accessCode = this.state.accessCodeVersion === 2 ? this.state.keyCodeValue.toUpperCase().replace(/[^A-Z]/g, '') : this.state.keyCodeValue;
    this.props.loginStudent(accessCode);
  };

  updateKey = event => {
    this.setState({ keyCodeValue: event.target.value });
  };

  render() {
    if (this.props.loggedIn) {
      return <Redirect to="/survey/intro" />;
    }

    return (
      <div className="page-container-survey-access">
        <TMCard
          className="tm-card-stepper"
          header={[
            <div className="text-center mb-4">
              <img className="w-75 " src={getLogo()} alt="Logo" />
            </div>,
            <div className="mb-3">
              <LanguagePicker/>
            </div>,
            <p>{getContent('survey_content-access-1')}</p>,
            <p>{getContent('survey_content-access-2')}</p>,
            <AudioButton
              label={getContent('survey_button-listen')}
              playlist={[
                getAudio('survey_content-access-1'),
                getAudio('survey_content-access-2'),
              ]}
            />
          ]}
        >
          <KeyboardEventHandler
            handleKeys={['enter']}
            onKeyEvent={() => {
              if (this.state.keyCodeValue.length > 0) {
                this.logInStudent();
              }
            }}
          />
          <div className={classnames(`form-group`)}>
            <MaskedInput
              mask={this.state.accessCodeVersion === 2 ? this.v2AccessCodeMask : this.v1AccessCodeMask}
              className={classnames('form-control', 'input', { 'has-error': this.props.showErrorMsg, 'v2': this.state.accessCodeVersion === 2 })}
              placeholder={ this.state.accessCodeVersion === 2 ? "XXXX-XXXX-XXXX-XXXX" : getContent('wellnesscenter_label-key-code') }
              guide={true}
              onChange={this.updateKey}
              tabIndex={0}
              onKeyDown={e => {
                if (e.keyCode === 13 && this.state.keyCodeValue.length > 0) {
                  this.logInStudent();
                }
              }}
            />
            {this.props.showErrorMsg ? (
              <div className="text-danger text-center">
                <small>
                  <strong>Error:</strong> Please enter a valid key code
                </small>
              </div>
            ) : (
              ''
            )}
            <button
              className="btn btn-link"
              onClick={this.toggleAccessCodeVersion}
            >
              {getContent('survey_button-my-key-code-looks-different')}
            </button>
            <TMButton disabled={this.state.keyCodeValue.length === 0} className="btn-block btn-round btn-green btn-lg" onClick={this.logInStudent} tabIndex={0} iconRight={ArrowRight}>
              {getContent('survey_button-begin-survey')}
            </TMButton>
          </div>
        </TMCard>
      </div>
    );
  }
}

const mapStatetoProps = state => ({
  loggedIn: state.studentAuth.userId,
  showErrorMsg: state.studentAuth.showErrorMsg,
});

const mapDispatchToProps = dispatch => ({
  loginStudent: keyCode => dispatch(actions.loginStudent(keyCode)),
});

export default connect(
  mapStatetoProps,
  mapDispatchToProps,
)(SurveyAccess);
