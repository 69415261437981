import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Route Components
import App from '../components/app'; // TODO: Trash?

// Errors
import Error404 from '../containers/Errors/Error404';

// Auth Route Components
import Login from '../containers/Admin/Auth/Login/Login';
import ResetPassword from '../containers/Admin/Auth/ForgotPassword/ResetPassword';
import ChangePassword from '../containers/Admin/Auth/ChangePassword/ChangePassword';

// Surveys
import Surveys from '../containers/Surveys/Surveys';

// Wellness Center
import WellnessCenter from '../containers/WellnessCenter/WellnessCenter';
import ParentReport from '../containers/Admin/Reports/Parent/Report'

// Admin Routes
import Admin from '../containers/Admin/Admin';

// HOCs
import withLayout from '../hoc/Layout/Layout';
import wellnessCenterAuth from '../hoc/Layout/WellnessCenterLayout'

class AppRouter extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* Survey Route */}
          <Route path="/survey" component={Surveys} />

          {/* Wellness Center Route */}
          <Route path="/wellness-center" exact component={WellnessCenter} />
          <Route path="/wellness-center/parent" exact component={wellnessCenterAuth(ParentReport)} />

          {/* Auth Routes */}
          <Route path="/login" exact component={withLayout(Login, { showLogo: false, showContact: true, showUser: true, showLang: false })} />
          <Route path="/reset-password" component={withLayout(ResetPassword, { showLogo: true, showContact: true, showUser: false, showLang: false })} />
          <Route path="/change-password" component={withLayout(ChangePassword, { showLogo: true, showContact: true, showUser: false, showLang: false })} />

          {/* Admin Routes */}
          <Route path="/admin" component={Admin} />

          {/* Default Route */}
          <Route path="/" exact component={App} />
          <Route component={withLayout(Error404, { showLogo: true, showContact: true, showUser: true, showLang: false })} />
        </Switch>
      </Router>
    );
  }
}


export default AppRouter;
