import update from 'immutability-helper';

import * as actionTypes from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  users: [],
  user: null
};

const listUsersStart = (state, action) => {
  return update(state, {
    isLoading: { $set: true },
    isLoaded: { $set: false },
    error: { $set: null }
  });
};

const listUsersSuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    isLoaded: { $set: true },
    users: { $set: action.users }
  });
};

const listUsersFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err }
  });
};

const getUserStart = (state, action) => {
  return update(state, {
    isLoading: { $set: true },
    error: { $set: null }
  });
};

const getUserSuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    user: { $set: action.user }
  });
};

const getUserFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err }
  });
};

const createUserStart = (state, action) => {
  return update(state, {
    isLoading: { $set: true },
    error: { $set: null }
  });
};

const createUserSuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false }
  });
};

const createUserFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err }
  });
};

const updateUserStart = (state, action) => {
  return update(state, {
    isLoading: { $set: true },
    error: { $set: null }
  });
};

const updateUserSuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    user: { $set: action.userProfile }
  });
};

const updateUserFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err }
  });
};

const deleteUserStart = (state, action) => {
  return update(state, {
    isLoading: { $set: true },
    error: { $set: null }
  });
};

const deleteUserSuccess = (state, action) => {
  return update(state, {
    isLoading: { $set: false }
  });
};

const deleteUserFail = (state, action) => {
  return update(state, {
    isLoading: { $set: false },
    error: { $set: action.err }
  });
};

const clearUsersList = (state, action) => {
  return update(state, {
    users: { $set: [] },
  });
};

const clearUserErrors = (state, action) => {
  return update(state, {
    error: { $set: null },
  });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_USERS_START:
      return listUsersStart(state, action);
    case actionTypes.LIST_USERS_SUCCESS:
      return listUsersSuccess(state, action);
    case actionTypes.LIST_USERS_FAIL:
      return listUsersFail(state, action);
    case actionTypes.GET_USER_START:
      return getUserStart(state, action);
    case actionTypes.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case actionTypes.GET_USER_FAIL:
      return getUserFail(state, action);
    case actionTypes.CREATE_USER_START:
      return createUserStart(state, action);
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccess(state, action);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFail(state, action);
    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state, action);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);
    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state, action);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state, action);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);
    case actionTypes.CLEAR_USERS_LIST:
      return clearUsersList(state, action);
    case actionTypes.CLEAR_USER_ERRORS:
      return clearUserErrors(state, action);
    case actionTypes.RESET_APP:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
