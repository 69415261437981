import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import { devToolsEnhancer } from 'redux-devtools-extension';
import rootReducer from './reducers';

const configureStore = (preloadedState) => {
  if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'prod') {
    return createStore(
      rootReducer,
      preloadedState,
      compose(
        applyMiddleware(thunk),
      )
    );
  }

  return createStore(
    rootReducer,
    preloadedState,
    compose(
      applyMiddleware(thunk),
      devToolsEnhancer(),
    )
  );
};

const store = configureStore();

export default store;


