import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import classnames from 'classnames';
// import * as actions from '../../store/actions/';
const withLayout = (WrappedComponent, { ...props }) => {
	const defaultOptions = { showLogo: false, showContact: false, showUser: false, showLang: false };
	const { showLogo, showUser, showContact, showLang } = { ...defaultOptions, ...props };
	class Layout extends Component {
		render() {
			return (
				<div className={classnames('survey-container')}>
					<Header className="row" showLogo={showLogo} showUser={showUser} showLang={showLang} />
					<div>
						<WrappedComponent {...this.props} />
					</div>
					<Footer className="row" showContact={showContact} />
				</div>
			);
		}
	}
	const mapStateToProps = state => {
		return {
			lang: state.meta.lang
		};
	};
	return connect(mapStateToProps)(Layout);
};
export default withLayout;
