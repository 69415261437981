import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ButtonNav from '../../../components/Button/ButtonNav';
import classnames from 'classnames';
import * as actions from '../../../store/actions';
import { withRouter } from 'react-router';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { ReactComponent as IconNavReports } from '../../../assets/imgs/icon-nav-reports.svg';
import { ReactComponent as IconNavSchools } from '../../../assets/imgs/icon-nav-schools.svg';
import { ReactComponent as IconNavSurveys } from '../../../assets/imgs/icon-nav-surveys.svg';
import { ReactComponent as IconNavUsers } from '../../../assets/imgs/icon-nav-users.svg';
import Spinner from '../../../components/Spinner/Spinner';
// import { getContent } from '../../../utilities/getContent/getContent';
// import { json2csv } from 'json-2-csv';

// const generateReports = async (report, name) => {
// 	await json2csv(
// 		report,
// 		(err, csv) => {
// 			let exportedFilename = name + '.csv';
// 			var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
// 			if (navigator.msSaveBlob) {
// 				navigator.msSaveBlob(blob, exportedFilename);
// 			} else {
// 				var link = document.createElement('a');
// 				if (link.download !== undefined) {
// 					var url = URL.createObjectURL(blob);
// 					link.setAttribute('href', url);
// 					link.setAttribute('download', exportedFilename);
// 					link.style.visibility = 'hidden';
// 					document.body.appendChild(link);
// 					link.click();
// 					document.body.removeChild(link);
// 				}
// 			}
// 		},
// 		{ emptyFieldValue: '' }
// 	);
// };

const AdminHome = props => {
	const getRoleBasedData = (role, type, item) => {
		const rolesText = {
			tm_admin: {
				orgs: {
					show: true,
					text: 'School Districts',
					descr: 'Add new School Districts or edit existing School Districts'
				},
				surveys: {
					show: true,
					text: 'Surveys',
					descr: 'View Surveys created by School Champions'
				},
				reports: {
					show: true,
					text: 'Reports',
					descr: 'View results from individual Surveys'
				},
				users: {
					show: true,
					text: 'Add Terrace User',
					descr: 'Set up or edit other Terrace Admin users'
				}
			},
			district_champion: {
				orgs: {
					show: true,
					text: 'Schools',
					descr: 'View, add or edit schools within your school district'
				},
				surveys: {
					show: true,
					text: 'Surveys',
					descr: 'View Surveys created by School Champions'
				},
				reports: {
					show: true,
					text: 'Reports',
					descr: 'View Individual Participant Reports, Parent Reports and School Reports'
				},
				users: {
					show: true,
					text: 'District Champions',
					descr: 'View, add or edit other users as School Champions'
				}
			},
			school_champion: {
				orgs: {
					show: false,
					text: '',
					descr: ''
				},
				surveys: {
					show: true,
					text: 'Surveys',
					descr: 'Create new surveys, edit or view existing surveys for your school'
				},
				reports: {
					show: true,
					text: 'Reports',
					descr: 'View Individual Participant Reports, Parent Reports and School Reports'
				},
				users: {
					show: true,
					text: 'School Champions',
					descr: 'View, add or edit other users as School Champions'
				}
			}
		};

		return rolesText[role][type][item];
	};

	useEffect(() => {
		props.getAggregatedOverallReport();
	}, []);

	const getBreadcrumbs = () => {
		const breadcrumbs = [
			{
				key: 1,
				url: '/admin/',
				label: 'Home'
			}
		];

		return breadcrumbs;
	};

	return (
		<>
			{props.isLoading ? (
				<Spinner />
			) : (
				<div className="page admin-home">
					<div className="sub-header">
						<Breadcrumbs breadcrumbs={getBreadcrumbs()} />
					</div>
					<div className="row my-5 nav-buttons">
						{getRoleBasedData(props.role, 'orgs', 'show') ? (
							<ButtonNav
								isNormal={false}
								text={getRoleBasedData(props.role, 'orgs', 'text')}
								icon={<IconNavSchools />}
								desc={getRoleBasedData(props.role, 'orgs', 'descr')}
								onClick={() => {
									props.history.push('/admin/organizations');
								}}
							/>
						) : null}
						{getRoleBasedData(props.role, 'surveys', 'show') ? (
							<ButtonNav
								isNormal={false}
								text={getRoleBasedData(props.role, 'surveys', 'text')}
								icon={<IconNavSurveys />}
								desc={getRoleBasedData(props.role, 'surveys', 'descr')}
								onClick={() => {
									props.history.push('/admin/survey/list');
								}}
							/>
						) : null}
						<div className={classnames('w-100', 'divider')} />
						{getRoleBasedData(props.role, 'reports', 'show') ? (
							<ButtonNav
								isNormal={false}
								text={getRoleBasedData(props.role, 'reports', 'text')}
								imgType={'report'}
								icon={<IconNavReports />}
								desc={getRoleBasedData(props.role, 'reports', 'descr')}
								onClick={() => {
									props.history.push('/admin/reports/search');
								}}
							/>
						) : null}
						{getRoleBasedData(props.role, 'users', 'show') ? (
							<ButtonNav
								isNormal={false}
								text={getRoleBasedData(props.role, 'users', 'text')}
								imgType={'user'}
								icon={<IconNavUsers />}
								desc={getRoleBasedData(props.role, 'users', 'descr')}
								onClick={() => {
									props.history.push('/admin/users');
								}}
							/>
						) : null}
					</div>
					{props.role === 'tm_admin' || props.role === 'school_champion' ? (
						<div>
							<div className={'row mx-5'}>
								<div className={classnames('col', 'colTitle')}>TEMPLATES</div>
								{props.role === 'tm_admin' ? (
									<div className={classnames('col', 'colTitle')}>REVIEW</div>
								) : null}
							</div>
							<div className={'row mx-5 my-3'}>
								<div className={'col'}>
									{/*<div className={'row'}>
                    <div className={classnames('col', 'templateLink')}>
                      <a href="#TODO">Student Results Template</a>
                    </div>
                    <div className={classnames('col', 'templateLink')}>
                      <a href="#TODO">District Results Template</a>
                    </div>
                  </div>*/}
									<div className={'row'}>
										{/*<div className={classnames('col', 'templateLink')}>
                      <a href="#TODO">Parent Results Template</a>
                    </div>*/}

										<div className={classnames('col', 'templateLink')}>
											<a
												href="https://s3.amazonaws.com/landing-page-links/ParentPermissionPassiveConsentFormTemplate.docx"
												target="_blank"
												rel="noopener noreferrer"
											>
												Parent Permission Template (Passive Consent)
											</a>
										</div>
									</div>
									<div className={'row'}>
										<div className={classnames('col', 'templateLink')}>
											<a
												href="https://s3.amazonaws.com/landing-page-links/ParentPermissionActiveConsentFormTemplate.docx"
												target="_blank"
												rel="noopener noreferrer"
											>
												Parent Permission Template (Active Consent)
											</a>
										</div>
									</div>
									<div className={'row'}>
										<div className={classnames('col', 'templateLink')}>
											<a
												href="https://landing-page-links.s3.amazonaws.com/ES_ParentPermissionPassiveConsentFormTemplate.docx"
												target="_blank"
												rel="noopener noreferrer"
											>
												Parent Permission Template (Passive Consent) - Spanish Version
											</a>
										</div>
									</div>
									<div className={'row'}>
										<div className={classnames('col', 'templateLink')}>
											<a
												href="https://landing-page-links.s3.amazonaws.com/ES_ParentPermissionActiveConsentFormTemplate.docx"
												target="_blank"
												rel="noopener noreferrer"
											>
												Parent Permission Template (Active Consent) - Spanish Version
											</a>
										</div>
									</div>
									{/* {Object.entries(props.reportDetails).length !== 0 ? (
										<div className={'row'}>
											<div
												onClick={() => {
													console.log(props.reportDetails);
													if (props.reportDetails.uppergrades) {
														const uppergradesIndicatorKeys = Object.keys(
															props.reportDetails.uppergrades
														);
														const uppergradesIndicators = uppergradesIndicatorKeys.map(
															key => {
																const indicator = props.reportDetails.uppergrades[key];
																return {
																	name: key,
																	...indicator
																};
															}
														);
														generateReports(
															uppergradesIndicators,
															'Uppergrades Aggregated Overall Report'
														);
													}
													if (props.reportDetails.elementary) {
														const elementaryIndicatorKeys = Object.keys(
															props.reportDetails.elementary
														);
														const elementaryIndicators = elementaryIndicatorKeys.map(
															key => {
																const indicator = props.reportDetails.elementary[key];
																return {
																	name: key,
																	...indicator
																};
															}
														);
														generateReports(
															elementaryIndicators,
															'Elementary Aggregated Overall Report'
														);
													}

													if (props.reportDetails.adult) {
														const professionKeys = Object.keys(props.reportDetails.adult);
														professionKeys.forEach(key => {
															const professionIndicatorKeys = Object.keys(
																props.reportDetails.adult[key]
															);
															const indicators = professionIndicatorKeys.map(
																indicatorKey => {
																	const indicator =
																		props.reportDetails.adult[key][indicatorKey];
																	return {
																		name: indicatorKey,
																		...indicator
																	};
																}
															);
															generateReports(
																indicators,
																`${getContent(key)} Adult Aggregated Overall Result`
															);
														});
													}
												}}
												className={classnames('col', 'templateLink')}
											>
												<div className="fake-link">Report Download by Classification</div>
											</div>
										</div>
									) : null} */}
								</div>
								{props.role === 'tm_admin' ? (
									<div className={'col'}>
										<div className={'row'}>
											<div className={classnames('col', 'templateLink')}>
												<a
													href="https://console.aws.amazon.com/dynamodb/home?region=us-east-1#tables:"
													target="_blank"
													rel="noopener noreferrer"
												>
													Database Tool
												</a>
											</div>
										</div>
									</div>
								) : null}
							</div>
						</div>
					) : null}
				</div>
			)}
		</>
	);
};

const mapDispatchToProps = dispatch => {
	return {
		getAggregatedOverallReport: () => dispatch(actions.getAggregatedOverallReport())
	};
};

const mapStateToProps = state => {
	return {
		role: state.auth.role,
		isLoading: state.organizations.isLoading,
		reportDetails: state.surveyCrud.reportDetails
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(AdminHome));
