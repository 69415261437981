import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Button from 'react-bootstrap/Button';

import * as actions from '../../../../../store/actions';

class SearchOrganizationForm extends Component {
	handleHideOrgSearch = () => {
		const { hideOrgSearch, clearOrganizationSearchOptions } = this.props;
		clearOrganizationSearchOptions();
		hideOrgSearch();
	};

	render() {
		const organization = this.props.role === 'tm_admin' ? 'District' : 'School';
		const searchClassificationKey = `${organization.toLowerCase()}SearchName`;
		return (
			<div className={classnames('container-search-survey', this.props.className)}>
				<p className="search-survey-header">{`Search ${organization}`}</p>
				<form className="search-survey-form" onSubmit={this.props.onSubmit}>
					<div className="input-container-search-survey">
						<div>
							<label className="label survey-name-label">{`${organization} Name`}</label>
							<input
								type="text"
								placeholder={`Full ${organization} Name`}
								className="search-survey-input"
								value={this.props[searchClassificationKey]}
								onChange={event =>
									this.props.updateSearchOrganizationObj({
										name: searchClassificationKey,
										value: event.target.value
									})
								}
							/>
						</div>
					</div>
					<div className="search-survey-submit-container">
						<Button
							onClick={()=>{this.props.onSubmit(organization.toLowerCase())}}
							className="small-blue-button"
							disabled={this.props.isLoading}
						>
              Search {organization}s
						</Button>
						<Button onClick={this.handleHideOrgSearch} className="small-blue-button-outlined">
							Cancel
						</Button>
					</div>
				</form>
			</div>
		);
	}
}

const mapStateToProps = state => {
  return {
    schoolSearchName:state.organizations.schoolSearchName,
    districtSearchName:state.organizations.districtSearchName,
    isLoading: state.organizations.isLoading,
    role: state.auth.role,
    orgInfo: state.organizations.organization,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearOrganizationSearchOptions: () => dispatch(actions.clearOrganizationSearchOptions()),
    hideOrgSearch: () => dispatch(actions.hideOrgSearch()),
    updateSearchOrganizationObj: payload => dispatch(actions.updateSearchOrganizationObj(payload)),
    setSearchValues: () =>dispatch(actions.setSearchValues()),
  };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchOrganizationForm);
