import parse from 'html-react-parser';
import store from '../../store/configureStore';
// const contentImport = require(`./content.prebuild-mock`);
// const contentImport = require(`./content.prebuild`);
const contentImport = require(`./content`);

export const getContentObject = (id, content) => {
  // Default to passed in content to make testing easier
  let contentStore = content || contentImport;

  // Function calling this function is responsible for checking result is not undefined
  return contentStore[id];
};

/**
 * Shortcut function to retrieve audio keys
 * @required id - The content ID to retrieve
 */
export const getAudio = id => {
  // Can be http or https depending on env
  // `${s3Prefix}/${key}-${lang}-audio.mp3`;
  const fallbackLang = 'en-US';
  const lang = store.getState().meta.lang || fallbackLang;
  const contentObj = getContentObject(id);
  const langToUse = contentObj[lang] ? lang : fallbackLang;
  const s3Prefix = process.env.REACT_APP_S3_PREFIX || 'static/media';
  // const host = process.env.REACT_APP_S3_HOST || 'https';
  const webUrl = process.env.REACT_APP_WEB_URL || 'http://dev.terracemetrics.org.s3-website-us-east-1.amazonaws.com'
  // const bucket = process.env.REACT_APP_S3_BUCKET || 'dev.terracemetrics.org';
  const s3Key = `${s3Prefix}/${id}-${langToUse}-audio.mp3`;

  return `${webUrl}/${s3Key}`;
  // return process.env.REACT_APP_STAGE === 'uk' ? `${host}://${bucket}.s3.amazonaws.com/${s3Key}` : `${host}://s3.amazonaws.com/${bucket}/${s3Key}`;
};

/**
 * @required id - The content ID to retrieve
 * @optional type - defaults to return text if nothing is passed in. Otherwise used for ['audio', 'video', 'image']
 * @optional lang - Explicitly pass in language, mostly used for testability
 * @optional audience - Explicitly pass in audience such as role, mostly for testability
 * @optional content - Explicitly Pass in a content object, mostly for testability
 *
 * @returns Case 0 - Content ID doens't exist, return empty string
 * @returns Case 1 Requested key exists and is returned
 * @returns Case 2 Lang Exists but audience is missing, returns value of lang look up
 * @returns Case 3 Lang and Audience are missing return default string
 * @returns Case 4 All look ups failed, return empty string
 */
export const getContent = (id, replacements = null, type = null, lang = null, audience = null, content = null) => {
  // If id does not match this pattern, it is alternate content so return it immediately
  if (!id.match(/^\w+_/)) {
    return parse(id);
  }

  const contentObj = getContentObject(id, content);
  let languageModifier = lang || store.getState().meta.lang;
  let audienceModifier = audience || store.getState().auth.role || '';
  const typeModifier = type ? type.toLowerCase() : null;
  audienceModifier = audienceModifier.toLowerCase();

  // Case 1
  let key = typeModifier ? `${languageModifier}-${audienceModifier}-${typeModifier}` : `${languageModifier}-${audienceModifier}`;
  // Case 2
  let audienceFallback = typeModifier ? `${languageModifier}-${typeModifier}` : languageModifier;
  // Case 3
  let defaultKey = typeModifier ? `en-US-${typeModifier}` : 'en-US';

  // Case 0
  if (!contentObj) {
    console.error('Content ID does not exist: ', id);
    return '';
  }

  // First attempt to get the requested key based on lang, type, and audience
  let result = contentObj[key];
  // Next attempt to look up the key with no audience
  if (!result) result = contentObj[audienceFallback];
  // Next attempt to look up the default key ('en-US' || 'en-US-audio')
  if (!result) result = contentObj[defaultKey];
  // Case 4
  if (!result) result = '';

  // do any replacements
  if (replacements && result) {
    replacements.map(item => {
      const match = new RegExp(item.match, 'g');
      result = result.replace(match, item.replacement);
      return result; // just to make eslint happy
    });
  }

  // Wrap output with debugging decorators
  if (process.env.REACT_APP_DEBUG_CONTENT === 'true' && !type) {
    result = `<span
      class="debug-content"
      data-key="${key}"
      data-audience-fallback="${audienceFallback}"
      data-default-key="${defaultKey}"
      data-id="${id}"
      data-type="${typeModifier}"
      data-lang="${languageModifier}"
      data-audience="${audienceModifier}"
      >${result}</span>`;
  }

  return result !== '' ? parse(result) : result;
};
