import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';

const RenderPhoneField = ({ input, meta: { touched, error, warning }, className }) => {
  return (
    <>
      <PhoneInput
        inputClass={`${className} form-control`}
        defaultCountry={process.env.REACT_APP_STAGE === 'uk' ? 'gb' : 'us'}
        preferredCountries={['gb','us']}
        disableAreaCodes={true}
        {...input }
      />
      {touched && ((error && <span className="error">{error}</span>) || (warning && <span className="warning">{warning}</span>))}
    </>
  );
};

export default RenderPhoneField;
