import React from 'react';
import classnames from 'classnames';
import AudioButton from '../../../components/Button/ButtonAudio';
import {Icon} from 'react-icons-kit';
const TYPES = {
  FAT: 'fat',
  SKINNY: 'skinny'
};

const QuizButton = ({ text, onClick, quizType,icon, color, active, index, value, audio,elementary }) => {
  console.log(audio)
  return (
    <div
      onClick={() => {
        onClick(index, value);
      }}
      className={classnames('quiz-button', `${quizType}`, { 'clicked': active, 'has-audio': audio })}
    >
      <div className="qb-text">{(icon  && elementary ? <Icon icon={icon} size={32} className='mr-3' style={{color: color}}/> : '')} {text}</div>
      <div className="keyboard-indicator-styles">{index + 1}</div>
      <AudioButton
        playlist={[audio]}
      />
    </div>
)};

export const Fat = props => <QuizButton {...props} quizType={TYPES.FAT} />;
export const Skinny = props => <QuizButton {...props} quizType={TYPES.SKINNY} />;
