import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import TFSReport from '../Shared/TFSReport';
import ParticipantHeader from '../Shared/ParticipantHeader';
import QuestionsOfConcernPage from './QuestionsOfConcernPage';

import * as actions from '../../../../store/actions/';


class StudentReport extends Component {
  componentDidMount() {
    //const participantId = this.props.location.state && this.props.location.state.participantId ? this.props.location.state.participantId : null;
    const parsed = queryString.parse(this.props.location.search);
    const participantId = parsed.pId;
    this.props.getReport(participantId);
  }

  render() {
    console.log('THIS.props.report : ', this.props.report);
    console.log('IsLoading: ', this.props.isLoading);
    return (
      <div className="tm-report tm-report-student">
        {/* Header is sticky so it prints on each page */}
        {this.props.isLoading === false && this.props.report ? (
          <>
            <ParticipantHeader payload={this.props.report} showPriority={true} isParentReport={false} showPrint={true} />
            <TFSReport payload={this.props.report} showTimeBox={true} isParentReport={false} />
            {/**  Start Student  **/}
            <QuestionsOfConcernPage payload={this.props.report} />
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    report: state.reports.report,
    isLoading: state.reports.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReport: participantId => dispatch(actions.getReport(participantId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(StudentReport));
