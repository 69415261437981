import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Auth } from 'aws-amplify';

import * as actions from '../../../../store/actions';
import WarningIcon from '../../../../assets/imgs/icon-warning.png';
import ModalWrapper from '../../../../components/RootModal/ModalWrapper';
import ForgotPasswordStartForm from './ForgotPasswordStartForm';

class ForgotPasswordModal extends Component {
  forgotPasswordStart = (username) => {
    Auth.forgotPassword(username)
      .then(data => {
        console.log(data);
        this.props.closeModal();
        this.props.redirectToResetPassword();
      })
      .catch(err => console.log(err));
  }

  handleSubmit = (values) => {
    console.log('VALUES: ', values);
    this.forgotPasswordStart(values.username);
  }

  render() {
    return (
      <ModalWrapper show={this.props.show}>
        <Modal.Header closeButton>
          <Modal.Title>
            <img alt="Warning Icon" style={{ marginRight: '1rem' }} src={WarningIcon} />
            Reset your password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body bsPrefix='modalContent'>
          <p>Please enter your email address.  You will receive a link to create a new password via email.</p>
          <ForgotPasswordStartForm
            onSubmit={this.handleSubmit}
          />
        </Modal.Body>
      </ModalWrapper>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(actions.closeModal())
  };
};

export default connect(null, mapDispatchToProps)(ForgotPasswordModal);
