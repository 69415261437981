import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import ModalWrapper from '../../../../components/RootModal/ModalWrapper';
import { ReactComponent as IconAvatar } from '../../../../assets/imgs/icon-avatar.svg';
import AddUserForm from './AddUserForm';
import sanitizePhoneNumber from '../../../../utilities/sanitizePhoneNumber';

class AddUserModal extends Component {
  handleAddNewUser = (values) => {
    let isDesignatedValue = undefined;

    if (values.isDesignated !== undefined) {
      if (values.isDesignated === true) {
        isDesignatedValue = "1";
      }
      else if (values.isDesignated === false) {
        isDesignatedValue = "0";
      }
    }

    const user = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: sanitizePhoneNumber(values.phoneNumber),
      role: this.props.role,
      isDesignated: isDesignatedValue,
    };

    console.log('user:', user);

    if (this.props.role !== 'tm_admin' && this.props.organization.orgId) {
      user.orgId = this.props.organization.orgId;
    }

    if (this.props.isUserProfile && values.password && values.password.length > 0) {
      user.password = values.password;
    }

    if (this.props.user) {
      this.props.addUser(this.props.user.username, user, this.props.isUserProfile);
    } else {
      this.props.addUser(user);
    }
  };

  render() {
    const initialValues = {};
    if (this.props.user) {
      initialValues.firstName = this.props.user.firstName;
      initialValues.lastName = this.props.user.lastName;
      initialValues.email = this.props.user.email;
      if (this.props.user.phoneNumber) {
        initialValues.phoneNumber = this.props.user.phoneNumber;
      }
      if (this.props.role === 'district_champion' && this.props.user.isDesignated) {
        initialValues.isDesignated = this.props.user.isDesignated === "1" ? true : false;
      }
    }

    let createButtonText = 'Add User';
    let titleText = this.props.user ? 'Edit User' : 'Add New User';
    let labelText = 'User';

    if (this.props.role && this.props.role === 'district_champion') {
      createButtonText = 'Add District Champion';
      titleText = this.props.user ? 'Edit District Champion' : 'Add District Champion';
      labelText = 'District Champion';
    }

    if (this.props.role && this.props.role === 'school_champion') {
      createButtonText = 'Add School Champion';
      titleText = this.props.user ? 'Edit School Champion' : 'Add School Champion';
      labelText = 'School Champion';
    }

    // When Editing your own profile
    if (this.props.isUserProfile) {
      titleText = 'Edit your profile';
      createButtonText = 'Save Edits';
      labelText = 'User';
    }

    return (
      <ModalWrapper show={this.props.show} resetModalErrorsOnClose={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            <IconAvatar style={{ marginRight: '1rem' }} />
            {titleText}
          </Modal.Title>
        </Modal.Header>
        <div style={{ flex: 1, alignItems: 'center' }}>
          {typeof this.props.error === 'string' ? <span className="error" style={{ textAlign: 'center' }}>{this.props.error}</span> : null}
        </div>
        <Modal.Body bsPrefix="modalContent">
          <AddUserForm
            onSubmit={this.handleAddNewUser}
            initialValues={initialValues}
            labelText={labelText}
            saveButtonText={this.props.user ? 'Save Edits' : createButtonText}
            closeModal={this.props.closeModal}
            isUserProfile={this.props.isUserProfile}
            showDesignatedToggle={this.props.role === 'district_champion' && !this.props.isUserProfile ? true : false}
          />
        </Modal.Body>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    role: state.auth.role,
    organization: state.organizations.organization,
    error: state.users.error,
  };
};

export default connect(mapStateToProps)(AddUserModal);
