import axios from 'axios';
import * as actionTypes from '../actionTypes';
import { closeModal } from '../modalConductor/modalConductor';
import { openToaster } from '../toaster/toaster';

const { REACT_APP_API_URL } = process.env;

export const listUsersStart = () => {
  return {
    type: actionTypes.LIST_USERS_START
  };
};

export const listUsersSuccess = (users) => {
  return {
    type: actionTypes.LIST_USERS_SUCCESS,
    users,
  };
};

export const listUsersFail = (err) => {
  return {
    type: actionTypes.LIST_USERS_FAIL,
    err
  };
};

export const listUsers = () => {
  return (dispatch, getState) => {
    dispatch(listUsersStart());
    let url = `${REACT_APP_API_URL}/users`;
    const orgId = getState().organizations.organization ? getState().organizations.organization.orgId : null;
    if (orgId) {
      url = `${REACT_APP_API_URL}/users/${orgId}`;
    }

    axios({
      url,
      method: 'GET',
      headers: { 'Authorization': getState().auth.idToken },
    })
      .then((res) => {
        console.log('res users success: ', res.data);
        dispatch(listUsersSuccess(res.data.users));
      })
      .catch((err) => {
        dispatch(listUsersFail(err));
      });
  };
};

export const getUserStart = () => {
  return {
    type: actionTypes.GET_USER_START
  };
};

export const getUserSuccess = (user) => {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    user,
  };
};

export const getUserFail = (err) => {
  return {
    type: actionTypes.GET_USER_FAIL,
    err
  };
};

export const getUser = (userId) => {
  return (dispatch, getState) => {
    dispatch(getUserStart());
    const url = `${REACT_APP_API_URL}/user/${userId}`;

    axios({
      url,
      method: 'GET',
      headers: { 'Authorization': getState().auth.idToken },
    })
      .then((res) => {
        console.log('GET USER SUCCESS: ', res);
        const userProfile = {
          username: res.data.user.attributes.sub,
          firstName: res.data.user.attributes.firstName || '',
          lastName: res.data.user.attributes.lastName || '',
          email: res.data.user.attributes.email || '',
          phoneNumber: res.data.user.attributes['phone_number'] || '',
        };
        dispatch(getUserSuccess(userProfile));
      })
      .catch((err) => {
        const errMsg = err.response.data.message ? err.response.data.message : 'Failed to get user';
        dispatch(getUserFail(errMsg));
      });
  };
};

export const createUserStart = () => {
  return {
    type: actionTypes.CREATE_USER_START
  };
};

export const createUserSuccess = (users) => {
  return {
    type: actionTypes.CREATE_USER_SUCCESS,
    users,
  };
};

export const createUserFail = (err) => {
  return {
    type: actionTypes.CREATE_USER_FAIL,
    err
  };
};

export const createUser = (values) => {
  return (dispatch, getState) => {
    dispatch(createUserStart());
    const url = `${REACT_APP_API_URL}/user`;

    axios({
      url,
      method: 'POST',
      data: { ...values },
      headers: { 'Authorization': getState().auth.idToken },
    })
      .then((res) => {
        dispatch(createUserSuccess());
        dispatch(listUsers());
        dispatch(closeModal());
        dispatch(openToaster(`${values.firstName} ${values.lastName} was successfully added!`));
      })
      .catch((err) => {
        const errMsg = err.response.data.message ? err.response.data.message : 'Error creating user';
        dispatch(createUserFail(errMsg));
      });
  };
};

export const updateUserStart = () => {
  return {
    type: actionTypes.UPDATE_USER_START
  };
};

export const updateUserSuccess = (values) => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    userProfile: values,
  };
};

export const updateUserFail = (err) => {
  return {
    type: actionTypes.UPDATE_USER_FAIL,
    err
  };
};

export const updateUser = (userId, values, isUserProfile = false) => {
  return (dispatch, getState) => {
    dispatch(updateUserStart());
    const url = `${REACT_APP_API_URL}/user/${userId}`;

    axios({
      url,
      method: 'POST',
      data: { ...values },
      headers: { 'Authorization': getState().auth.idToken },
    })
      .then((res) => {
        console.log('UPDATE USER RESPONSE: ', res);
        dispatch(updateUserSuccess());
        dispatch(listUsers());
        dispatch(closeModal());

        if (isUserProfile) {
          dispatch(getUserSuccess(values));
          dispatch(openToaster('Your profile was successfully changed.'));
        } else {
          dispatch(openToaster(`${values.firstName} ${values.lastName} was successfully changed.`));
        }
      })
      .catch((err) => {
        const errMsg = err.response.data.message ? err.response.data.message : 'Failed to update user';
        dispatch(updateUserFail(errMsg));
      });
  };
};

export const deleteUserStart = () => {
  return {
    type: actionTypes.DELETE_USER_START
  };
};

export const deleteUserSuccess = (users) => {
  return {
    type: actionTypes.DELETE_USER_SUCCESS,
    users,
  };
};

export const deleteUserFail = (err) => {
  return {
    type: actionTypes.DELETE_USER_FAIL,
    err
  };
};

export const deleteUser = (userId) => {
  return (dispatch, getState) => {
    dispatch(deleteUserStart());
    const url = `${REACT_APP_API_URL}/user/${userId}`;

    axios({
      url,
      method: 'DELETE',
      headers: { 'Authorization': getState().auth.idToken },
    })
      .then((res) => {
        dispatch(deleteUserSuccess());
        dispatch(listUsers());
        dispatch(closeModal());
        dispatch(openToaster('User was successfully deleted.'));
      })
      .catch((err) => {
        dispatch(deleteUserFail(err));
      });
  };
};

export const clearUsersList = () => {
  return {
    type: actionTypes.CLEAR_USERS_LIST,
  };
};

export const clearUserErrors = () => {
  return {
    type: actionTypes.CLEAR_USER_ERRORS,
  };
};
