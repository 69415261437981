import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

//import MOCK_PAYLOAD from './mockPayload';
import * as tmLinks from './tmLinks';
import Footer from './Footer';
import Page01 from './Page-01';
import Page02 from './Page-02';
import Page03 from './Page-03';
import Page04 from './Page-04';
import Page05 from './Page-05';
import Page06 from './Page-06';

import * as actions from '../../../../store/actions/';

// const payload = MOCK_PAYLOAD;

class OverallSurveyReport extends Component {
  componentDidMount() {
    //const participantId = this.props.location.state && this.props.location.state.participantId ? this.props.location.state.participantId : null;
    const parsed = queryString.parse(this.props.location.search);
    const surveyId = parsed.id;
    console.log('THIS.PROPS IN OVERALL SURVEY REPORT: ', this.props);
    this.props.getReport(surveyId);
  }

  render() {
    let component = <div className="tm-report-group">Loading Data</div>;
    if (!this.props.isLoading) {
      component = <div className="tm-report-group">
        <Page01 payload={this.props.report} />
        <Page02 payload={this.props.report} />
        <Page03 payload={this.props.report} links={tmLinks} />
        <Page04 payload={this.props.report} />
        <Page05 payload={this.props.report} links={tmLinks} />
        <Page06 payload={this.props.report} links={tmLinks} />
        <Footer />
      </div>;
    }
    return (
      <div>
        {component}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    report: state.overallSurveyReport.report,
    isLoading: state.overallSurveyReport.isLoading,
    error: state.overallSurveyReport.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReport: (surveyId) => dispatch(actions.getOverallSurveyReport(surveyId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(OverallSurveyReport));
