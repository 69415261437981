import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ChangePasswordForm from './ChangePasswordForm';
import BreadCrumbs from '../../../../components/Breadcrumbs/Breadcrumbs';
import * as actions from '../../../../store/actions';

class ChangePassword extends Component {
  handleChangePassword = values => {
    console.log('Change Password Values: ', values);
    // TODO: Validate newPassword === confirmPassword
    this.props.changePassword(values.newPassword, this.redirectAfterPasswordChange);
  };

  redirectAfterPasswordChange = () => {
    this.props.history.push('/login');
  };

  breadcrumbs = [
    {
      key: 0,
      url: '/admin',
      label: 'Home',
    },
    {
      key: 1,
      label: 'Change Password',
    },
  ];

  render() {
    return (
      <div className="change-password">
        <div className="sub-header">
          <BreadCrumbs breadcrumbs={this.breadcrumbs} />
        </div>
        <div>
          <h1 className="mb-4">Change Password</h1>
          <p>Welcome to Terrace Metrics! In order to proceed, please update your password. Your password:</p>
          <ul>
            <li>Must be at least 8 characters long</li>
            <li>Must contain at least one uppercase character</li>
            <li>Must contain at least one number</li>
            <li>Must contain at least one symbol (@,$, etc)</li>
          </ul>
        </div>
        <ChangePasswordForm onSubmit={this.handleChangePassword} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    changePassword: (newPassword, cb) => dispatch(actions.changePassword(newPassword, cb)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ChangePassword));
