import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { SubmissionError } from 'redux-form';

import { constructBirthday } from '../../../../../utilities/dates';
import AddStudent from '../../../../../assets/imgs/icon-plus-circle.svg';
import * as actions from '../../../../../store/actions';
import ParticipantEntryForm from './ParticipantEntryForm';
import moment from 'moment';

class StudentRosterUpdate extends Component {
  state = {
    intervalId: null,
    fileName: null,
    error: null,
    isWaiting: false,
  };

  // Perform the upload
  handleUpload = ev => {
    let file = this.uploadInput.files[0];
    this.props.getSignedUrl(file);
    const intervalId = setInterval(() => {
      this.props.checkRosterUploadStatus();
      if (!this.props.isRosterUploading) {
        clearInterval(this.state.intervalId);
      }
    }, 2000);
    this.setState({ intervalId: intervalId }, console.log('this.state: ', this.state));
  };

  handleFileChange = () => {
    let file = this.uploadInput.files[0];
    if (file) {
      if (file.name.match(/\.csv$/i)) {
        this.setState({
          fileName: file.name,
          error: null,
        });
      } else {
        this.setState({
          fileName: null,
          error: 'Must be a .csv file.',
        });
      }
    }
  };

  // HACK: For Chuck to fix status
  waitAndRedirect = surveyId => {
    this.props.setIsWaiting(true);
    setTimeout(() => {
      this.props.setIsWaiting(false);
      this.redirectToSurveyDetails(surveyId);
    }, 3000);
  };

  handleParticipantSubmission = values => {
    const participants = [];
    // eslint-disable-next-line array-callback-return
    console.log('Values: ', values);

    this.props.manualRoster.map((participant, index) => {
      const birthDate = constructBirthday(values[`year-${index}`], values[`month-${index}`].value, values[`day-${index}`]);
      if (!moment(birthDate).isValid()) {
        throw new SubmissionError({ _error: 'Invalid Birthday' });
      }
      const entry = {
        firstName: values[`firstName-${index}`],
        middleName: values[`middleName-${index}`],
        lastName: values[`lastName-${index}`],
        grade: values[`grade-${index}`],
        studentId: values[`studentId-${index}`],
        parentEmail: values[`parentEmail-${index}`],
        parentMobile: values[`parentMobile-${index}`],
        birthDate: birthDate,
        contacted: false,
        groupName: values[`group-name-${index}`],
      };
      participants.push(entry);
    });
    console.log('participants in createSurvey: ', participants);

    this.props.createSurvey(participants, this.waitAndRedirect);
  };

  redirectToSurveyDetails = surveyId => {
    this.props.history.push({
      pathname: '/admin/survey/details',
      state: {
        surveyId,
      },
    });
  };

  render() {
    return (
      <>
        <div className="file-input-create-survey-step-group">
          <Button bsPrefix="small-button green" onClick={this.handleUpload} disabled={!this.state.fileName ? 'disabled' : undefined}>
            Upload
          </Button>
          <div className="input-group">
            <div className="custom-file">
              <input
                ref={ref => {
                  this.uploadInput = ref;
                }}
                type="file"
                onChange={this.handleFileChange}
                className="custom-file-input"
                id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01"
              />
              <label className="custom-file-label" htmlFor="inputGroupFile01">
                {this.state.fileName || 'Choose file'}
              </label>
              {this.state.error ? <span class="error">{this.state.error}</span> : null}
            </div>
          </div>
        </div>
        <div className="manual-student">
          <p className="manual-student-header">Add participants manually</p>
          <p className="manual-student-subhead">If needed, you may add participants manually via the button below.</p>
          <ParticipantEntryForm manualRoster={this.props.manualRoster} onSubmit={this.handleParticipantSubmission} />
          {/* TODO: Refactor to a real button */}
          {/* <button
            className=""
            onClick={this.props.addToManualRosterLength}>
            <img className='add-student-button' alt='Add Student' src={AddStudent} />
            <span>Add Student</span>
          </button> */}
          <div className="add-student-container">
            <img onClick={this.props.addToManualRosterLength} className="add-student-button" alt="Add Participant" src={AddStudent} />
            <label onClick={this.props.addToManualRosterLength}>Add Participant</label>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    isRosterUploading: state.surveyCrud.isRosterUploading,
    manualRoster: state.surveyCrud.manualRoster,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSignedUrl: file => dispatch(actions.getSignedUrl(file)),
    uploadRoster: file => dispatch(actions.uploadRoster(file)),
    checkRosterUploadStatus: () => dispatch(actions.checkRosterUploadStatus()),
    addToManualRosterLength: () => dispatch(actions.addToManualRosterLength()),
    createSurvey: (participants, cb) => dispatch(actions.createSurvey(participants, cb)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(StudentRosterUpdate),
);
