import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div className="page-footer row">
        <div className="col-4">&copy; Copyright Terrace Metrics 2019</div>
        <div className="col-8 text-right">For assistance email us at info@terracemetrics.org or contact us at 800-470-4401.</div>
      </div>
    );
  }
}

export default Footer;
