import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import ButtonArrow from '../../../../components/Button/ButtonArrow';

class LoginForm extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <form className="login-form" onSubmit={handleSubmit}>
        <div>
          <div>
            <Field
              name="username"
              type="text"
              component="input"
              className={classNames('form-control', 'input')}
              placeholder="Username"
             />
          </div>
          <div>
            <Field
              name="password"
              type="password"
              component="input"
              className={classNames('form-control', 'input')}
              placeholder="Password"
            />
          </div>
          <ButtonArrow className="button green" onClick={this.submit} type="submit" text="Login"  tabIndex={0} />
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'login-form',
})(LoginForm);
