import React from 'react';
import logo from '../../../assets/imgs/logo-terrace-small.png';
import faqs from '../../../assets/imgs/Group 169@2x.png';
import contact from '../../../assets/imgs/ic_phonelink_setup_24px@2x.png';
import change from '../../../assets/imgs/ic_text_format_24px@2x.png';
const Footer = ({showContact}) => {
	const footerContent = (
		<>
			<img src={logo} className="logo" alt="Terrace Metrics" />
			<div>
				<h6>Terrace Metrics</h6>
				<p>Copyright &copy; Terrace Metrics 2019</p>
				<p>All rights reserved.</p>
			</div>
		</>
	);
	return (
    <footer>
      <div className="largefooter">
        <svg
				className="right"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 100 100"
				preserveAspectRatio="none"
			>
        <defs>
					<linearGradient id="grad" gradientTransform="rotate(-1)" x1="0%" y1="0%" x2="100%" y2="0%">
						<stop offset="0%" stopColor="black" stopOpacity="1" />
						<stop offset="34%" stopColor="white" stopOpacity="1" />
						<stop offset="100%" stopColor="white" stopOpacity="1" />
          </linearGradient>
        </defs>
				<polygon points="0 60, 0 100, 100 100, 100 0" fill="url(#grad)" />
      </svg>
			<div className="left">
				<div className="content">{footerContent}</div>
      </div>
      {showContact ? <div className="footerUserInfo">
        <ul>
            <li><a href="/admin/contact"><img src={contact} alt={'contact'}/>Contact Us</a></li>
          <li><a href="/admin/faq"><img src={faqs} alt={'faqs'}/>FAQs</a></li>
          <li><a href="/admin/changelog"><img src={change} alt={'changelog'}/>Changelog</a></li>
        </ul>
      </div> : null}
      </div>

      <div className="smallfooter">{footerContent}</div>

		</footer>
	);
};

export default Footer;
