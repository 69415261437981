import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import BootStrapButton from 'react-bootstrap/Button';

import ModalWrapper from '../../../../../components/RootModal/ModalWrapper';

import * as actions from '../../../../../store/actions';

class NotifyParentModal extends Component {

  handleSubmit = () => {
    const participant = this.props.item;
    const values = {
      participant,
      surveyId: this.props.surveyId
    };
    this.props.notifyParentReport(values, this.props.surveyId);
  }

  render() {
    return (
      <ModalWrapper className='survey-status-modal-wrapper' show={this.props.show} onExited={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Report Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body bsPrefix='modalContent survey-status-modal'>
          Are you certain you would like to email or text this report link? <br />  
          <strong>This action can not be undone.</strong>
          <div className="btnContainer-edit-participant">
          <BootStrapButton
            onClick={() => this.handleSubmit()}
            type="submit"
            className="modalBtn"
            size="lg"
            variant="success"
          >
            Send Notification
          </BootStrapButton>
          <BootStrapButton onClick={this.props.closeModal} className="modalBtn" size="lg" variant="outline-primary">
            Cancel
          </BootStrapButton>
        </div>
        </Modal.Body>
      </ModalWrapper>
    );
  }
}


const mapDispatchToProps = dispatch => {
  return {
    notifyParentReport: (participantData, surveyId) => dispatch(actions.notifyParentReport(participantData, surveyId)),
    closeModal: () => dispatch(actions.closeModal()),
  };
};


export default connect(null,mapDispatchToProps)(NotifyParentModal);
