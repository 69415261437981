import React, { Component } from 'react';
import { connect } from 'react-redux';

const isSurveyReady = (WrappedComponent) => {
  class isSurveyReadyComponent extends Component {
    componentDidMount() {
      this.checkAndRedirect();
    }

    componentDidUpdate() {
      this.checkAndRedirect();
    }

    checkAndRedirect = () => {
      if (!this.props.isSurveyReady) {
        this.props.history.push('/survey');
      }
    }

    render() {
      return (
        <>
          {(this.props.isSurveyReady) ? <WrappedComponent {...this.props} /> : null}
        </>
      )
    }
  };

  const mapStateToProps = (state) => {
    return {
      isSurveyReady: state.studentAuth.userId, // This should check that the survey questions are loaded and ready
    };
  };

  return connect(mapStateToProps)(isSurveyReadyComponent);
};

export default isSurveyReady;
