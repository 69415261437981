import axios from 'axios';
import * as actionTypes from '../actionTypes';
import { closeModal } from '../modalConductor/modalConductor';
import { openToaster } from '../toaster/toaster';

const { REACT_APP_API_URL } = process.env;

export const listOrganizationsStart = () => {
  return {
    type: actionTypes.LIST_ORGANIZATIONS_START,
  };
};

export const hideOrgSearch = () => {
  return {
    type: actionTypes.HIDE_ORG_SEARCH,
  }
};
export const updateSearchOrganizationObj = payload => {
  console.log(payload)
  return {
    type: actionTypes.UPDATE_ORGANIZATION_SEARCH,
    payload
  }
}

export const showOrgSearch = () => {
  return {
    type: actionTypes.SHOW_ORG_SEARCH,
  }
}

export const listOrganizationsSuccess = organizations => {
  return {
    type: actionTypes.LIST_ORGANIZATIONS_SUCCESS,
    organizations,
  };
};

export const listOrganizationsFail = err => {
  return {
    type: actionTypes.LIST_ORGANIZATIONS_FAIL,
    err,
  };
};

export const listOrganizations = (values={}) => {
  return (dispatch, getState) => {
    dispatch(listOrganizationsStart());
    let classification = 'school-district';
    if (getState().auth.role === 'district_champion') {
      classification = 'school';
    }
    const orgId = getState().organizations.organization ? getState().organizations.organization.orgId || '' : '';

    const url = `${REACT_APP_API_URL}/org/search?classification=${classification}&parentOrgId=${orgId}&name=${values.name || ''}`;

    axios({
      url,
      method: 'GET',
      headers: { 'Authorization': getState().auth.idToken },
    })
      .then(res => {
        res.data.hasSearched = Object.entries(values).length > 0 && values.constructor === Object;
        console.log('res Organizations success: ', res.data);
        dispatch(listOrganizationsSuccess(res.data));
      })
      .catch(err => {
        dispatch(listOrganizationsFail(err));
      });
  };
};

export const getOrganizationStart = () => {
  return {
    type: actionTypes.GET_ORGANIZATION_START,
  };
};

export const getOrganizationSuccess = organization => {
  console.log('organization: ', organization);
  return {
    type: actionTypes.GET_ORGANIZATION_SUCCESS,
    organization,
  };
};

export const getOrganizationFail = err => {
  return {
    type: actionTypes.GET_ORGANIZATION_FAIL,
    err,
  };
};

export const getOrganization = (orgId, idToken = null) => {
  return (dispatch, getState) => {
    dispatch(getOrganizationStart());
    const url = `${REACT_APP_API_URL}/org?orgId=${orgId}`;

    // NOTE: idToken being passed in from checkAuthState to avoid the race condition
    //    of the idToken not being set in state before this function is called on page load
    axios({
      url,
      method: 'GET',
      headers: { 'Authorization': idToken || getState().auth.idToken },
    })
      .then(res => {
        console.log('Res Organization: ', res.data);
        dispatch(getOrganizationSuccess(res.data));
      })
      .catch(err => {
        dispatch(getOrganizationFail(err));
      });
  };
};

export const createOrganizationStart = () => {
  return {
    type: actionTypes.CREATE_ORGANIZATION_START,
  };
};

export const createOrganizationSuccess = organizations => {
  return {
    type: actionTypes.CREATE_ORGANIZATION_SUCCESS,
    organizations,
  };
};

export const createOrganizationFail = err => {
  return {
    type: actionTypes.CREATE_ORGANIZATION_FAIL,
    err,
  };
};

export const createOrganization = values => {
  return (dispatch, getState) => {
    dispatch(createOrganizationStart());
    const url = `${REACT_APP_API_URL}/org`;

    axios({
      url,
      method: 'POST',
      data: { ...values },
      headers: { 'Authorization': getState().auth.idToken },
    })
      .then(res => {
        dispatch(createOrganizationSuccess());
        dispatch(listOrganizations());
        dispatch(closeModal());
        dispatch(openToaster(`${values.name} was successfully added!`));
      })
      .catch(err => {
        const errMsg = err.response.data.message ? err.response.data.message : 'Failed to create organization';
        dispatch(createOrganizationFail(errMsg))
      });
  };
};

export const updateOrganizationStart = () => {
  return {
    type: actionTypes.UPDATE_ORGANIZATION_START,
  };
};

export const updateOrganizationSuccess = organization => {
  return {
    type: actionTypes.UPDATE_ORGANIZATION_SUCCESS,
    organization,
  };
};

export const updateOrganizationFail = err => {
  return {
    type: actionTypes.UPDATE_ORGANIZATION_FAIL,
    err,
  };
};

export const updateOrganization = (values) => {
  return (dispatch, getState) => {
    dispatch(updateOrganizationStart());
    const url = `${REACT_APP_API_URL}/org`;

    console.log('==== updateOrganization ====', values);

    axios({
      url,
      method: 'PUT',
      data: { ...values },
      headers: { 'Authorization': getState().auth.idToken },
    })
      .then(res => {
        dispatch(updateOrganizationSuccess());
        dispatch(listOrganizations());
        dispatch(closeModal());
        dispatch(openToaster(`${values.name} was successfully changed.`));
      })
      .catch(err => {
        const errMsg = err.response.data.message ? err.response.data.message : 'Failed to update organization';
        dispatch(updateOrganizationFail(errMsg))
      });
  };
};

export const deleteOrganizationStart = () => {
  return {
    type: actionTypes.DELETE_ORGANIZATION_START,
  };
};

export const deleteOrganizationSuccess = organization => {
  return {
    type: actionTypes.DELETE_ORGANIZATION_SUCCESS,
    organization,
  };
};

export const deleteOrganizationFail = err => {
  return {
    type: actionTypes.DELETE_ORGANIZATION_FAIL,
    err,
  };
};

export const deleteOrganization = orgId => {
  return (dispatch, getState) => {
    dispatch(deleteOrganizationStart());
    const url = `${REACT_APP_API_URL}/org/${orgId}`;

    axios({
      url,
      method: 'DELETE',
      headers: { 'Authorization': getState().auth.idToken },
    })
      .then(res => {
        dispatch(deleteOrganizationSuccess());
        dispatch(listOrganizations());
        dispatch(closeModal());
        dispatch(openToaster('Successfully deleted organization.'));
      })
      .catch(err => {
        const errMsg = err.response.data.message ? err.response.data.message : 'Delete to update organization';
        dispatch(deleteOrganizationFail(errMsg))
      });
  };
};

export const changeOrganizationSuccess = (realOrg) => {
  return {
    type: actionTypes.CHANGE_ORGANIZATION_SUCCESS,
    realOrg
  };
};

export const changeOrganization = (newOrgId) => {
  return (dispatch, getState) => {
    const realOrg = { ...getState().organizations.organization };
    dispatch(changeOrganizationSuccess(realOrg));
    dispatch(getOrganization(newOrgId));
  };
};

export const resetOrganizationSuccess = (realOrg) => {
  return {
    type: actionTypes.RESET_ORGANIZATION_SUCCESS,
    realOrg,
  };
};

export const resetOrganization = () => {
  return (dispatch, getState) => {
    const realOrg = { ...getState().organizations.realOrganization };
    dispatch(resetOrganizationSuccess(realOrg));
  };
};

export const clearOrganization = () => {
  return {
    type: actionTypes.CLEAR_ORGANIZATION,
  };
};

export const clearOrganizationSearchOptions = () => {
  return {
    type: actionTypes.CLEAR_ORGANIZATION_SEARCH_OPTIONS,
  };
};

export const clearOrganizationErrors = () => {
  return {
    type: actionTypes.CLEAR_ORGANIZATION_ERRORS,
  };
};
