import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SurveyQuestion from '../SingleSurveyQuestion/SurveyQuestion';
import * as actions from '../../../store/actions/survey/survey';
import { Progress } from 'react-sweet-progress';

class SurveyQuestions extends Component {
	completeSurveyAndRedirect = () => {
		const isAdult =
			this.props.survey.receivedSurveyData.surveyName &&
			this.props.survey.receivedSurveyData.surveyName.includes('Adult');
		if (isAdult) return this.props.history.push('/survey/email-results');

		// Turn this to a end survey component like tim suggested.
		// Spinning wheel while submitting, then you are done message or retry button
		const { surveyDataToSend, saveSurveyParticipantResponseData } = this.props;

		saveSurveyParticipantResponseData({
			...surveyDataToSend,
			surveySubmittedAt: Date.now()
		});
		this.props.history.push('/survey/submitting');
	};

	handleIncrementIndex = () => {
		const currentIndicator = this.props.receivedSurveyData.indicators[this.props.currentIndicatorIndex];
		const currentQuestion = currentIndicator.questions[this.props.indicatorResponseIndex];
		const currentQuestionAnswers = this.props.receivedSurveyData.responseOptionsIndex[
			currentQuestion.questionResponseOptionsType
		];
		console.log('this.props.answerValue before pushSelectedAnswer: ', this.props.answerValue);

		if (currentQuestion.inputRule === 'input_rule_event' && this.props.answerValue === 1) {
			this.props.pushSecondaryIndicatorResponses();
		}

		if (currentQuestion && currentQuestionAnswers.length > 0 && currentQuestion.inputRule !== 'input_rule_event') {
			const currentFieldValuePair = currentQuestionAnswers.find(obj => obj.value === this.props.answerValue);
			this.props.pushSelectedAnswer({
				indicatorData: {
					name: currentIndicator.indicator,
					contentId: currentIndicator.contentId
				},
				answerData: {
					questionText: currentQuestion.questionText,
					questionSmId: currentQuestion.questionSmId,
					responseOptionField: currentFieldValuePair.field,
					responseScore: currentFieldValuePair.value,
					questionResponseOptionsType: currentQuestion.questionResponseOptionsType
				}
			});
		}

		console.log('this.props.answerValue: before push Secondary ', this.props.answerValue);
		console.log('this.props.indicatorResponseIndex ', this.props.indicatorResponseIndex);
		console.log('this.props.indicatorResponseIndex', this.props.indicatorResponseIndex);
		console.log(
			'currentIndicator.questions[this.props.indicatorResponseIndex].length',
			currentIndicator.questions.length
		);
		if (this.props.indicatorResponseIndex + 1 === currentIndicator.questions.length) {
			this.props.incrementSurveyIndicatorIndex();
			this.props.incrementQuestionIndex();
			console.log(
				'At the end of the handleIncrement Function... incrementSurveyIndicatorIndex',
				this.props.answerValue
			);
		} else {
			this.props.incrementQuestionIndex();
			this.props.incrementSurveyIndicatorResponseIndex();
		}
	};

	render() {
		const currentIndicator = this.props.receivedSurveyData.indicators[this.props.currentIndicatorIndex];

		return (
			<>
				{this.props.receivedSurveyData.indicators.length === this.props.currentIndicatorIndex ? (
					/* or like a complete survey component or something */
					// this.completeSurveyAndRedirect() && <Redirect to="/survey/complete" />
					this.completeSurveyAndRedirect()
				) : (
					<div className="container-all-questions">
						<Progress
              className="progressBar"
							percent={(this.props.questionIndex / this.props.totalQuestions) * 100}
              status="success"
							theme={{
								success: {
									symbol: `${Math.round((this.props.questionIndex / this.props.totalQuestions) * 100)}% Complete`,
									color: '#56af56',
									trailColor: '#a6c086'
								}
							}}
						/>

						<SurveyQuestion
							// should just be able to pass down props on the individual response level, not indictaor level
							onIncrementIndex={this.handleIncrementIndex}
							currentQuestion={currentIndicator.questions[this.props.indicatorResponseIndex]}
							questionNum={currentIndicator.questions.length}
							indicatorData={currentIndicator}
						/>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		survey: state.survey,
		surveyDataToSend: state.survey.surveyDataToSend,
		currentIndicatorIndex: state.survey.indicatorIndex,
		indicatorResponseIndex: state.survey.indicatorResponseIndex,
		answerValue: state.survey.answerValue,
		receivedSurveyData: state.survey.receivedSurveyData,
		isAnswerSelected: state.survey.isAnswerSelected,
		questionIndex: state.survey.questionIndex,
		totalQuestions: state.survey.totalQuestions
	};
};

const mapDispatchToProps = dispatch => {
	return {
		incrementQuestionIndex: () => dispatch(actions.incrementQuestionIndex()),
		saveSurveyParticipantResponseData: surveyDataToSend =>
			dispatch(actions.saveSurveyParticipantResponseData(surveyDataToSend)),
		incrementSurveyIndicatorIndex: () => dispatch(actions.incrementSurveyIndicatorIndex()),
		incrementSurveyIndicatorResponseIndex: () => dispatch(actions.incrementSurveyIndicatorResponseIndex()),
		pushSelectedAnswer: args => dispatch(actions.pushSelectedAnswer(args)),
		pushSecondaryIndicatorResponses: () => dispatch(actions.pushSecondaryIndicatorResponses())
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(SurveyQuestions)
);
