import React from 'react'
import CheckedImage from '../../assets/imgs/checked.png'
import UncheckedImage from '../../assets/imgs/unchecked.png'

const CheckBox = (props) => {
  let labelClasses = [];
  if (props.labelClasses) {
    labelClasses = [...props.labelClasses];
  }

  return (
    <div>
      <label
        className={labelClasses.join(' ')}
        onClick={props.onClick}
        htmlFor={props.name}
      >
        <img className='checkbox' alt='Checkbox' src={props.isChecked ? CheckedImage : UncheckedImage} />
        <input
          type='checkbox'
          value={props.value}
          name={props.name}
          ischecked={props.isChecked}
          disabled={props.disabled}
        />
        {/* <input onChange={this.props.toggleCheckedIndicatorInForm} type='checkbox' value={this.props.label} name={this.props.label}/> */}
        {props.label}
      </label>
    </div>
  )
};

export default CheckBox;
