import React, { Component } from 'react'
import { connect } from 'react-redux';
import CreateSurveyStep from '../CreateSurveyStep/CreateSurveyStep'
import IndicatorSelectForm from '../IndicatorSelectForm/IndicatorSelectForm'
import SurveyDateSelect from '../SurveyDateSelect/SurveyDateSelect';
import StudentRosterUpload from '../StudentRosterUpload/StudentRosterUpload'
import RemoteSubmitButton from '../RemoteSubmitButton/RemoteSubmitButton';
import DesignatedEmailRecipient from '../DesignatedEmailRecipient/DesignatedEmailRecipient';

class CreateSurveyStepGroup extends Component {
  state = {
    isWaiting: false,
  }

  setIsWaiting = (value) => {
    this.setState({ isWaiting: value });
  }

  render() {
    let indicatorsChosen = (() => {
      const surveyObj = this.props.surveyCrud.createSurveyObj;
      let result = false;
      if (surveyObj.appliedTo === 'adult') {
        result = surveyObj.chosenIndicators.length > 0 && surveyObj.profession ? true : false;
      }
      else {
        result = surveyObj.chosenIndicators.length > 0 ? true : false;
      }
      return result;
    })();
    let manualRosterHasLength = this.props.surveyCrud.manualRoster.length > 0;
    let manualRosterPopulated = manualRosterHasLength && this.props.formErrors && !this.props.formErrors.syncErrors;
    let fileUploaded = this.props.surveyCrud.createSurveyObj.s3Tags.fileKey;
    let rosterPopulated = !!fileUploaded;

    // If there's a manual roster entry, verify there are no form errors
    if (manualRosterHasLength) {
      rosterPopulated = manualRosterPopulated;
    }

    return (
      <>
        <CreateSurveyStep
          number='1'
          header='Select survey question indicators'
          subhead='The survey question indicators will define the blocks of questions that will make up your survey. You can select any combination of indicators to make up your survey. (Note: by choosing to select only certain indicators you will not be able to receive a total function score).'
        >
          <IndicatorSelectForm />
        </CreateSurveyStep>
        <CreateSurveyStep
          number='2'
          header='Set the survey date'
          subhead='Set the date for the survey. This, along with the categories above, will define the survey name.'
          active={indicatorsChosen}
        >
          <SurveyDateSelect />
        </CreateSurveyStep>
        <CreateSurveyStep
          number='3'
          header='Upload your participant roster'
          subhead='The participant roster must follow the <a href="ROSTERNAME">example participant roster template</a> format. Save roster as .csv file.<p>For more information on how to create a survey using a roster, watch this <a target="_blank" href="https://www.terracemetrics.org/wp-content/uploads/2020/02/TMRosterTrainingVideo1080p.mp4">video</a></p>'
          active={indicatorsChosen}
        >
          <StudentRosterUpload setIsWaiting={this.setIsWaiting} />
        </CreateSurveyStep>
        <CreateSurveyStep
          number='4'
          header='Enter a designated email recipient for priority reports'
          subhead='The email recipient will be notified whenever there is a survey participant that is considered high priority or at risk.'
          active={indicatorsChosen && rosterPopulated}
        >
          <DesignatedEmailRecipient/>
        </CreateSurveyStep>
        <CreateSurveyStep
          number='5'
          header='Save your survey'
          subhead='Once you are satisfied with your survey, save your results. You will be taken to a page which will allow you to review and edit your survey values as needed.'
          active={indicatorsChosen && rosterPopulated}
        >
          <RemoteSubmitButton isWaiting={this.state.isWaiting} />
        </CreateSurveyStep>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    surveyCrud: state.surveyCrud,
    formErrors: state.form['participant-entry-form'],
  };
};

export default connect(mapStateToProps)(CreateSurveyStepGroup);
