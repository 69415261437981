import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLogo } from './../../../assets/helper';

// import classnames from 'classnames';
import { Link } from 'react-router-dom';
import LanguagePicker from '../../../components/LanguagePicker/LanguagePicker';
import { ReactComponent as IconAvatarBG } from '../../../assets/imgs/icon-avatar-bg.svg';

import * as actions from '../../../store/actions';

class Header extends Component {
  redirectToLogin = () => {
    this.props.history.push('/login');
  };

  resetRoleAndOrg = () => {
    this.props.resetOrganization();
    this.props.resetRole();
    this.props.history.push('/admin');
  }

  openUpdateUserModal = () => {
    this.props.openModal({
      modalType: 'ADD_USER_MODAL',
      show: true,
      addUser: this.props.updateUser,
      user: this.props.userProfile,
      isUserProfile: true,
      closeModal: (e) => this.closeModal(e)
    });
  }

  closeModal = (e) => {
    // Prevent the form from submitting
    if (e) {
      e.preventDefault();
    }

    this.props.closeModal();
  }

  render() {
    const { showLogo, showUser, showLang } = this.props;
    let name = '';
    console.log('this.props.userProfile: ', this.props.userProfile);
    if (this.props.isAuthenticated && this.props.userProfile) {
      name = `${this.props.userProfile.firstName} ${this.props.userProfile.lastName}`;
    }

    return (
      <header className="header">
        <div className="container">
          {showLogo ? (
            <>
              <div className="branding">
                <Link to={this.props.role ? "/admin" : "/survey"}>
                  <img className={'logo'} src={getLogo()} alt={'logo'} />
                </Link>
                {
                  this.props.role !== 'tm_admin' && this.props.realRole && this.props.realOrganization ?
                    <button className="button-text" style={{ position: 'relative', top: '30px', left: '-210px' }} onClick={this.resetRoleAndOrg}>Return to {this.props.realRole === 'tm_admin' ? 'Terrace Admin' : 'District Champion'} Home</button> : null
                }
              </div>
              {showLang ? (
                <LanguagePicker/>
              ) : null }
            </>
          ) : null}
          {showUser && this.props.isAuthenticated ? (
            <div className="user">
              <div className="userWrapper">
                <div className="username">{name}</div>
                <div className="role">{this.props.role}</div>
                <div className="logout">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={() => this.props.logout(this.redirectToLogin)}>Log out</a>
                </div>
              </div>
              <IconAvatarBG onClick={this.openUpdateUserModal} className="avatar clickable" />
            </div>
          ) : null}
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    role: state.auth.role,
    realRole: state.auth.realRole,
    realOrganization: state.organizations.realOrganization,
    userProfile: state.users.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: cb => dispatch(actions.logout(cb)),
    resetRole: () => dispatch(actions.resetRole()),
    resetOrganization: () => dispatch(actions.resetOrganization()),
    openModal: (modalProps) => dispatch(actions.openModal(modalProps)),
    closeModal: () => dispatch(actions.closeModal()),
    updateUser: (userId, values, isUserProfile) => dispatch(actions.updateUser(userId, values, isUserProfile)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Header),
);
