import React from 'react';
import DropdownList from 'react-widgets/lib/DropdownList';
import 'react-widgets/dist/css/react-widgets.css';

const Dropdown = ({ input, className, ...rest }) => {
  const classes = className ? className : 'tm-dropdown';

  return <DropdownList {...input} {...rest} className={classes} />;
};

export default Dropdown;
