import { sad, smile, neutral, grin, happy, wondering } from 'react-icons-kit/icomoon';
import { sad as entypoSad } from 'react-icons-kit/entypo';
export const color = {
	0: ['#fff'],
	2: ['#fff'],
	4: ['#b93333', '#e16c69', '#a6c086', '#56af56'],
	5: ['#b93333', '#e16c69', '#d9c363', '#a6c086', '#56af56'],
	6: ['#b93333', '#CD504E', '#e16c69', '#a6c086', '#56af56', '#3e893e'],
	7: ['#b93333', '#CD504E', '#e16c69', '#d9c363', '#a6c086', '#56af56', '#3e893e'],
	8: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff']
};

export const emoticon = {
	0: [undefined],
	2: [undefined, undefined],
	4: [entypoSad, sad, smile, grin],
	5: [entypoSad, sad, neutral, happy, grin],
	6: [entypoSad, sad, wondering, smile, happy, grin],
	7: [entypoSad, sad, wondering, neutral, smile, happy, grin],
	8: [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined]
};
