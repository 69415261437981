import React, { Component } from 'react';
import GroupHeader from './Header';
import { getContent } from '../../../../utilities/getContent/getContent';
// import MOCK_SUMMARY from '../mock-resilience-categories';
// import parse from 'html-react-parser';

class MeanResilienceyScoresPage extends Component {
  render() {
    const payload = this.props.payload;
    const isAdult = payload.surveyName && payload.surveyName.includes('Adult');

    return (
      <div className="page group-report" style={{marginTop:'100px'}}>
        <GroupHeader payload={payload} />
        <div className="container">
          <main className="px-5">
            <div className="row">
              <h2 className="mb-3 text-uppercase">Mean Resiliency Scores by Indicator</h2>
              <table className="table table-sm table-tm-dark-striped">
                <thead>
                  <tr>
                    <th>Indicator</th>
                    <th>TM Historical Average</th>
                    <th>{payload.semester} {payload.year} Mean</th>
                    {(payload.previousSurveyName) && <th>{payload.previousSemester} {payload.previousYear} Mean</th>}
                    <th>Range</th>
                    <th>Interpretation</th>
                  </tr>
                </thead>
                <tbody>
                  {payload.reports.averageResiliencyScores.map((indicator, index) => {
                    return (
                      <tr key={index}>
                        <td className="font-weight-bold">{indicator.indicator}</td>
                        <td>{indicator.national}</td>
                        <td>{indicator.school}</td>
                        {(payload.previousSurveyName) && <td>{parseInt(indicator.previous, 10).toFixed(2)}</td>}
                        <td>{indicator.range}</td>
                        <td>{indicator.interpretation}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="row">
              <div className="box-comment">
                <h3>What is this table telling me?</h3>
                <p>
                  {isAdult ? getContent("report_overall_page04_historical_table_1_adult") : getContent("report_overall_page04_historical_table_1_student")}
                </p>
                <h3>{isAdult ? getContent("report_overall_page03_risk-heading_adult") : getContent("report_overall_page03_risk-heading")}</h3>
                <p>
                  {isAdult ? getContent("report_overall_page04_historical_table_2_adult") : getContent("report_overall_page04_historical_table_2_student")}
                </p>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }
}

export default MeanResilienceyScoresPage;
