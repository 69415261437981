import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import BootStrapButton from 'react-bootstrap/Button';

import ParticipantEntryFields from '../../CreateSurvey/StudentRosterUpload/ParticipantEntryFields';

class AddParticipantForm extends Component {
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        {this.props.error && <span className="error">{this.props.error}</span>}
        <ParticipantEntryFields index={0} />
        <div className="btnContainer-edit-participant">
          <BootStrapButton type="submit" className="modalBtn" size="lg" variant="success">
            Add participant
          </BootStrapButton>
          <BootStrapButton onClick={this.props.closeModal} className="modalBtn" size="lg" variant="outline-primary">
            Cancel
          </BootStrapButton>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'add-participant-form',
})(AddParticipantForm);
