import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import BootStrapButton from 'react-bootstrap/Button';
import SchoolChampionFields from './SchoolChampionFields';
import RenderField from '../../../../../components/RenderField/RenderField';
import { ReactComponent as IconPlusCircle } from '../../../../../assets/imgs/icon-plus-circle.svg';
import RenderPhoneField from '../../../../../components/RenderPhoneField/RenderPhoneField';

class CreateSchoolForm extends Component {
  render() {
    const { invalid, pristine, submitting } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="d-flex align-items-end mb-2">
          <div className="label-field-container flex-grow-1">
            <label className="label" htmlFor="name">
              School Name
            </label>
            <Field
              name="name"
              placeholder=""
              type="text"
              component="input"
              className="participant-entry-field"
              list="autocompleteOff"
            />
          </div>
          <div className="label-field-container flex-grow-1">
            <label className="label" htmlFor="address">
              School Address
            </label>
            <Field
              name="address"
              placeholder=""
              type="text"
              component="input"
              className="participant-entry-field"
              list="autocompleteOff"
            />
          </div>
          <div className="label-field-container flex-grow-1">
            <label className="label" htmlFor="city">
              School City
            </label>
            <Field
              name="city"
              placeholder=""
              type="text"
              component="input"
              className="participant-entry-field"
              list="autocompleteOff"
            />
          </div>
        </div>
        <div className="d-flex align-items-end mb-2">
          <div className="label-field-container col-4 pl-0 pr-2">
            <label className="label" htmlFor="phoneNumber">
              School Phone
            </label>
            <Field
              name="phoneNumber"
              type="tel"
              component={ RenderPhoneField }
              className='participant-entry-field'
            />
          </div>
        </div>
        <div>
          {this.props.champions.map((item, index) => {
            return <SchoolChampionFields key={index} index={index} removeChampion={this.props.removeChampion} />;
          })}
          {this.props.addChampion ?
            <button onClick={this.props.addChampion} className="btn px-0">
              <IconPlusCircle style={{ marginRight: '0.5rem' }} />
              Add School Champion
            </button>
          : null}
        </div>
        <div className="btnContainer justify-content-end">
          <BootStrapButton
            type="submit"
            className="small-button green"
            size="lg"
            variant="success"
            disabled={ invalid || pristine || submitting }
          >
            {this.props.saveButtonText}
          </BootStrapButton>
          <BootStrapButton className="small-blue-button-outlined" size="lg" variant="outline-primary" onClick={e => this.props.closeModal(e)}>
            Cancel
          </BootStrapButton>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'create-school-form',
})(CreateSchoolForm);
