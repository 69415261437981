import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'react-bootstrap';

import * as actions from '../../store/actions';

class Toaster extends Component {
  handleClose = () => {
    this.props.closeToaster();
  };

  render() {
    return (
      <Toast
        onClose={this.handleClose}
        show={this.props.show}
        className="tm"
        autohide={true}
        delay={5000}
        style={{
          position: 'fixed',
        }}
      >
        <button
          type="button"
          className="close"
          onClick={this.handleClose}
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <Toast.Body>{this.props.content}</Toast.Body>
      </Toast>
    );
  }
}

const mapStateToProps = state => {
  return {
    show: state.toaster.show,
    content: state.toaster.content,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeToaster: () => dispatch(actions.closeToaster()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Toaster);
