import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class FAQ extends Component {
  

  render() {
    return (
      <div className="content">
        <div className="row">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a aria-current="page" className="active" href="/admin/">Home</a>
            </li>
            <li className="breadcrumb-item active">
              <span>Frequently Asked Questions</span>
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-11">
            <h1>Frequently Asked Questions</h1>
            <p>
              School districts often have questions as they begin their partnership with Terrace Metrics. The most common questions and answers are provided below. Please do not hesitate to contact Terrace Metric either by e-mail (<a href="mailto:info@terracemetrics.org">info@terracemetrics.org</a>) or phone (800-470-4401) should you need additional information or have other questions.
            </p>
            <ol>
              <a href="#faq1"><li>Are All Indicators Valid?</li></a>
              <a href="#faq2"><li>Is The Survey Compatible With Multi-Tiered Intervention Models?</li></a>
              <a href="#faq3"><li>Do Districts/Schools Have Access To The Survey Questions?</li></a>
              <a href="#faq4"><li>Can the Survey Data Be Matched with Attendance and Behavioral Data?</li></a>
              <a href="#faq5"><li>Can the Data be used to Formulate Action Plans?</li></a>
              <a href="#faq6"><li>Are the Videos Part of the Costs?</li></a>
              <a href="#faq7"><li>How Can You Be Sure That The Responses Are Valid?</li></a>
              <a href="#faq8"><li>Are There Translations of the Survey in Other Languages? What Can be Done For Slower/Poor Readers?</li></a>
              <a href="#faq9"><li>Who Decides to Send Out the Parent Reports?</li></a>
              <a href="#faq10"><li>How is Terrace Metrics Involved in the Onboarding and Training Process?</li></a>
              <a href="#faq11"><li>What is The Process for Sharing Information Once the Results are Returned to Schools? </li></a>
              <a href="#faq12"><li>What is the Usual Course of Action for Schools when They Receive the Participant Reports? </li></a>
              <a href="#faq13"><li>Won’t This Priority System Overburden Available Resources?</li></a>
              <a href="#faq14"><li>What Parameters Must be Followed?</li></a>
            </ol>
          </div>
        </div>

        <div className="row">
          <ol className="col-11">
            <li className="mb-6" id="faq1">
              <h2>Are All Indicators Valid?</h2>
              <p>
                To be selected for the survey, all indicators must pass stringent validity criteria. Specifically, each indicator must demonstrate strong psychometric evidence (including high reliability, temporal stability, and construct validity) for their targeted ages. Second, support for the indicator must be shown in one or more independent, peer-reviewed studies.  Finally, all items and scoring options must demonstrate content validity and be understood by participants with 3rd-grade reading abilities.   
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq2">
              <h2>Is The Survey Compatible With Multi-Tiered Intervention Models?</h2>
              <p>
                Yes. The survey (indeed, the entire system) was designed to help educators apply the findings to multi-tiered systems of supports. Terrace Metrics provides templates, examples, and consultation services to help schools understand how the findings can and have been used to generate Tier 1 (school wide), Tier 2 (group-specific) and Tier 3 (individual-focused), evidence-based, strategies.   
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq3">
              <h2>Do Districts/Schools Have Access To The Survey Questions?</h2>
              <p>
                Yes. Upon request, schools and districts have access to all survey questions and options.  Parents may also request this information as well but they must come to the school to review them. 
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq4">
              <h2>Can the Survey Data Be Matched with Attendance and Behavioral Data?</h2>
              <p>
                Yes. Schools can easily take the de-identified data and merge them with other school indicators.
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq5">
              <h2>Can the Data be used to Formulate Action Plans?</h2>
              <p>
                Yes. Terrace Metrics has templates that can be used by schools, and (for a minimal fee) can customize action plans as well.
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq6">
              <h2>Are the Videos Part of the Costs?</h2>
              <p>
                Yes. Each indicator has an accompanying video that is specific to educators and parents (respectively). These 3-minute videos provide readily accessible, empirically-based suggestions on steps that educators/parents can take to increase a specific resiliency score, and decrease or address a specific risk score. 
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq7">
              <h2>How Can You Be Sure That The Responses Are Valid?</h2>
              <p>
                Prior to releasing the reports back to the schools, we run the data through several validity checks (e.g., response patterns, speed of survey completion) to make sure that the participant was taking the survey seriously.  Any report that is suspect is flagged for the response team. It should be noted that invalid protocols happen less than 1% of the time. Likewise, anecdotal statements from administrators/members of each school response team, as well as historical analyses of the data against objective academic indicators (GPA, state testing) and behavioral indicators (e.g., suspensions, absences) reveals substantially low false positive/negatives (less than 3% for each).
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq8">
              <h2>Are There Translations of the Survey in Other Languages? What Can be Done For Slower/Poor Readers?</h2>
              <p>
                The survey is currently translated in Spanish. The survey will be available in additional languages in the fall of 2019. Most schools separate participants who struggle with reading to another location, where the items are read by the proctor. 
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq9">
              <h2>Who Decides to Send Out the Parent Reports?</h2>
              <p>
                School districts make this decision. Virtually all of our district partners send out the parent report on the same day that they received the participant report.
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq10">
              <h2>How is Terrace Metrics Involved in the Onboarding and Training Process?</h2>
              <p>
                Onboarding (i.e., preloading the survey) is easy and takes approximately 5 minutes.
A Terrace Metrics representative will work directly with a designated district Champion to ensure that the onboarding process is successful. 
              </p>
              <p>
Approximately 1-2 weeks prior to the survey administration, a member of Terrace Metrics will be on hand to train each school’s response team (which typically consists of the school psychologist, counselor/nurse/social worker and designated administrator). During this meeting, which lasts 45-60 minutes, response team members will understand and interpret the participant report, and the steps that should be taken once the report is posted. Terrace Metrics will be on hand during and even after survey administration to ensure that the user experience is positive and the process runs smoothly.  
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq11">
              <h2>What is The Process for Sharing Information Once the Results are Returned to Schools? </h2>
              <p>
                Only members of a school’s response team have access to individual participant data and reports; teachers will not have access to individual data. Both the participant and parent reports are delivered back to the team through our secure and encrypted system.  All members of the response team are trained on how to respond and disseminate the information once received.
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq12">
              <h2>What is the Usual Course of Action for Schools when They Receive the Participant Reports? </h2>
              <p>
                Participants in the At Risk and Moderate Concern ranges are given a priority score, which informs the response team of how quickly the school’s response team should respond. The priority scores rank, in order, the recommended time to meet with participants who endorsed either single indicators in the At Risk range (e.g., depressive symptoms), or had a number of indicators in the At Risk range.  The time-to-respond ranges from 24 hours to within 2 weeks, depending on priority score.
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq13">
              <h2>Won’t This Priority System Overburden Available Resources?  </h2>
              <p>
                Districts should not have all schools take the survey at once.  Most districts conduct a “sweep”; usually having one grade level or school take the survey one day, moving to another grade/school the next day and so on. Our largest districts are able to have all schools complete the survey in 3 weeks; smaller districts usually reserve a week to complete it. As the system is automated and is independent from other assessments (e.g., state academic testing), the survey can stay active as long as the district wishes. 
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            <li className="mb-6" id="faq14">
              <h2>What Parameters Must be Followed? </h2>
              <p>
                Parents must consent to having their participant participate in the survey; they can certainly opt out if they wish. As our consent rate currently averages 98% and has stayed at that level for many years, most parents respond to the information positively (even and especially pertaining to participants in the at-risk category).
              </p>
              <p>
Schools must follow their existing procedures when they become aware of a participant who is under duress or may be a threat of harm to self or others. In these cases, counselors must reach out to the participant and contact the parents. 
              </p>
              <a href="#top" className="up-arrow">Back to top</a>
            </li>

            
          </ol>
        </div>
        
      </div>
    );
  }
}

export default connect()(withRouter(FAQ));
