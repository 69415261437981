import axios from 'axios';
import * as actionTypes from '../actionTypes';
import { createInitialSurveyResponse } from '../index';
// eslint-disable-next-line
const { REACT_APP_API_URL, STAGE } = process.env;

export const loginStudentStart = () => {
  return {
    type: actionTypes.LOGIN_STUDENT_START,
  };
};

export const loginStudentSuccess = payload => {
  return {
    type: actionTypes.LOGIN_STUDENT_SUCCESS,
    ...payload,
  };
};

export const loginStudentFail = err => {
  return {
    type: actionTypes.LOGIN_STUDENT_FAIL,
    error: err,
  };
};

export const loginStudent = keyCode => {
  return dispatch => {
    dispatch(loginStudentStart());
    // https://6du3j6ziaa.execute-api.us-east-1.amazonaws.com/test
    return axios({
      method: 'post',
      url: `${REACT_APP_API_URL}/survey/participant/verify`,
      data: {
        inviteCode: keyCode,
      },
    })
      .then(res => {
        console.log('Login student response: ', res);
        dispatch(createInitialSurveyResponse(res.data));
				const {
					firstName,
					lastName,
					middleName,
					grade,
					schoolName,
					surveyParticipantId,
				} = res.data;
        const userName = [firstName, middleName, lastName].filter(Boolean).join(' ');
        dispatch(
          loginStudentSuccess({
						userId: surveyParticipantId,
            grade,
            userName,
            schoolName,
          }),
        );
      })
      .catch(err => {
        let msg =  err && err.message ? err.message : 'Invalid login attempt: internal server error.'
        // TODO: Here you may want to pull a pretty error message from the server or throw a redirect callback or something
        dispatch(
          loginStudentFail({ ...err, message: msg })
        );
      });
  };
};
