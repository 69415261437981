import React, { Component } from 'react';
import scoreMap from '../scoreMap';
import { getContent } from '../../../../utilities/getContent/getContent';
class ScoreBlock extends Component {
  render() {
    let scoreDetail = scoreMap[`${this.props.score}`] || scoreMap.no_concern;
    let showLink = this.props.showLink;
    // REMOVED as of https://jira.fusionalliance.com/browse/TMOAS-415
    // let indicator = this.props.indicator;
    if (scoreDetail) {
      let label = this.props.score ? getContent(`risk_${this.props.score}`) : getContent('risk_no_concern');
      let url = '';
      if (scoreDetail.url) {
        url = this.props.isAdult ? scoreDetail.url.adult : scoreDetail.url.default;
      }
      let className = scoreDetail && this.props.solid ? `block-${scoreDetail.color}` : `block-outline-${scoreDetail.color}`;
      // REMOVED as of https://jira.fusionalliance.com/browse/TMOAS-415
      // if (indicator) {
      //   let indicatorStr = indicator.toLowerCase().replace(" ", "");
      //   if (scoreDetail[indicatorStr]) { url = scoreDetail[indicatorStr]; }
      // }
      if (showLink && url) {
        return (
          <div className={`score block ${className} ${this.props.className}`}>
            <a href={`${url}`} target="_blank" className='show-link' rel="noopener noreferrer">{label}</a>
          </div>
        );
      } else {
        return (
          <div className={`score block ${className} ${this.props.className}`}>
            {label}
          </div>
        );
      }
    } else {
      return '';
    }
  }
}

export default ScoreBlock;
